<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="12">
                <v-btn color="primary" tile @click="showadd">
                    <v-icon small>mdi-plus</v-icon> 新增货主
                </v-btn>
            </v-col>
        </v-row>
        <v-card tile class="mt-2">
            <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
                :scroll="{ x: width, y: height }" row-key="_id">
                <template slot="title">
                    <div class="d-flex align-center pl-2">
                        <span>共 {{ length }} 页 / {{ count }} 条数据</span>
                        <v-menu bottom offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                                    {{ limit }}/页
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                                    <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template slot="index" slot-scope="text, record, index">
                    {{ index + 1 }}
                </template>
                <template slot="create_time" slot-scope="text, record">
                    {{ record.create_time | dateFormat('YYYY-MM-DD HH:mm:ss') }}
                </template>
                <template slot="action" slot-scope="text, record">
                    <a-space>
                        <v-btn text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
                        <a-popconfirm title="删除货主" ok-text="确定" cancel-text="取消" @confirm="remove(record._id)">
                            <v-btn text tile color="error"> 删除 </v-btn>
                        </a-popconfirm>
                    </a-space>
                </template>
            </a-table>
            <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
        </v-card>
        <v-dialog v-model="model" persistent max-width="588px">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
                        {{ modeltitle }}
                        <v-icon @click="cancelsave">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field v-model="ownerinfo.name" dense outlined :rules="[vrules.required]">
                                        <template v-slot:label>
                                            姓名 <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-file-input  variant="filled"
                                        prepend-icon="mdi-camera">
                                        <template v-slot:label>
                                            头像 <span class="red--text">*</span>
                                        </template>
                                    </v-file-input>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="ownerinfo.phone" type="number" dense outlined
                                        :rules="[vrules.required]">
                                        <template v-slot:label>
                                            手机号 <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="ownerinfo.project" type="text" dense outlined>
                                        <template v-slot:label>
                                            项目 <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="ownerinfo.mark" label="备注" dense outlined>

                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" tile :disabled="!valid" @click="save">确定</v-btn>
                        <v-btn tile @click="cancelsave">关闭</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
import UploadTask from "@/components/uploadtask.vue";
export default {
    name: "SourceGoodsOwner",
    data() {
        return {
            pages: [
                { text: '20/页', value: 20 },
                { text: '50/页', value: 50 }
            ],
            valid: true,
            headers: [
                { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 60, align: "center" },
                { title: "姓名", dataIndex: "name", scopedSlots: { customRender: "name" }, width: 150, align: "center" },
                { title: "头像", dataIndex: "avatar", scopedSlots: { customRender: "avatar" }, width: 100, align: "center" },
                { title: "手机号", dataIndex: "phone", scopedSlots: { customRender: "phone" }, width: 100, align: "center" },
                { title: "项目", dataIndex: "project", scopedSlots: { customRender: "project" }, width: 100, align: "center" },
                { title: "备注", dataIndex: "mark", scopedSlots: { customRender: "mark" }, width: 100, align: "center" },
                { title: "创建时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
                { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: 'right' },
            ],
            page: 1,
            limit: 20,
            list: [],
            length: 0,
            count: 0,
            height: 600,
            model: false,
            modeltitle: "",
            vrules: {
                required: (value) => !!value || "不能为空",
            },
            ownerinfo_template: {
                _id: "", name: '', avatar: '', phone: '', project: '', mark: ""
            },
            ownerinfo: {},
            date_menu: false,
        };
    },
    computed: {
        ...mapState(["role", "username"]),
        width() {
            return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
        },
    },
    components: { UploadTask },
    watch: {
        page() {
            this.getdata();
        },
        limit() {
            if (this.page !== 1) this.page = 1;
            else this.getdata();
        },
    },
    activated() {
        this.page = 1;
        this.list = [];
        this.length = 0;
        this.count = 0;
        this.getdata();
    },
    methods: {
        showupload() {
        },
        getdata() {

        },
        showadd() {
            this.ownerinfo = _.extend({}, { ...this.ownerinfo_template });
            this.modeltitle = "新增货主";
            this.model = true;
        },
        showedit(item) {
            this.ownerinfo = _.pick(item, _.keys(this.ownerinfo_template));
            this.modeltitle = "编辑货主";
            this.model = true;
        },
        save() {
            if (!this.$refs.form.validate()) return;

        },
        cancelsave() {
            this.$refs.form.resetValidation();
            this.model = false;
        },
        remove(_id) {
            this.$toast.loading({ message: "" });

        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .row {
    margin-bottom: 0 !important;
}

/deep/ .col-12 {
    padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    padding: 0 4px;
}

/deep/ p {
    margin: 0;
    padding: 0;
}
</style>
