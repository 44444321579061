<template>
  <v-container fluid>
    <div>
      <v-row>
        <!-- <v-col cols="12" lg="2" md="3" sm="4">
          <v-menu ref="start_day_menu" v-model="start_day_menu" :close-on-content-click="false"
            :return-value.sync="queryForm.start_day" transition="scale-transition" min-width="auto" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="queryForm.start_day" label="开始日期" append-icon="mdi-calendar" readonly v-bind="attrs"
                v-on="on" dense outlined>
                <template v-slot:label>开始日期</template>
              </v-text-field>
            </template>
            <v-date-picker v-model="queryForm.start_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
              @input="() => { $refs.start_day_menu.save(queryForm.start_day); start_day_menu = false; }"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" lg="2" md="3" sm="4">
          <v-menu ref="end_day_menu" v-model="end_day_menu" :close-on-content-click="false"
            :return-value.sync="queryForm.end_day" transition="scale-transition" min-width="auto" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="queryForm.end_day" label="结束日期" append-icon="mdi-calendar" readonly v-bind="attrs"
                v-on="on" dense outlined>
                <template v-slot:label>结束日期</template>
              </v-text-field>
            </template>
            <v-date-picker v-model="queryForm.end_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
              @input="() => { $refs.end_day_menu.save(queryForm.end_day); end_day_menu = false; }"></v-date-picker>
          </v-menu>
        </v-col> -->
        <v-col cols="12" lg="2" md="3" sm="4">
          <v-menu ref="month_menu" v-model="month_menu" :close-on-content-click="false"
            :return-value.sync="queryForm.month" transition="scale-transition" min-width="auto" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="queryForm.month" label="月份" append-icon="mdi-calendar" readonly v-bind="attrs"
                v-on="on" dense outlined>
                <template v-slot:label>月份</template>
              </v-text-field>
            </template>
            <v-date-picker v-model="queryForm.month" type="month" no-title value="YYYY-mm" scrollable locale="zh-cn"
              @input="() => { $refs.month_menu.save(queryForm.month); month_menu = false; }"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <a-space>
            <v-btn @click="getdata" color="cyan" dark tile>
              <v-icon small>mdi-magnify</v-icon>查询
            </v-btn>
            <v-btn @click="download" color="indigo" dark tile>
              <v-icon small>mdi-download</v-icon>下载营收日报
            </v-btn>
          </a-space>
        </v-col>
      </v-row>
    </div>
    <v-tabs v-model="tab" @change="changetab">
      <v-tab v-for="item in tablist" :key="item.value"> {{ item.text }} </v-tab>
    </v-tabs>
    <v-card>
      <a-table
        :columns="tab === tablist[0].value ? headers1 : (tab === tablist[tablist.length - 1].value ? headers3 : headers2)"
        :data-source="list" :pagination="false" bordered :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: tab === tablist[0].value ? width1 : (tab === tablist[tablist.length - 1].value ? width3 : width2), y: height }">
        <template slot="province" slot-scope="text, record">
          <span :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.province }}</span>
        </template>
        <template slot="count" slot-scope="text, record">
          <span :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.count }}</span>
        </template>
        <template slot="up_freight" slot-scope="text, record">
          <span :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.up_freight }}</span>
        </template>
        <template slot="down_freight" slot-scope="text, record">
          <span :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.down_freight }}</span>
        </template>
        <template slot="self_freight" slot-scope="text, record">
          <span :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.self_freight }}</span>
        </template>
        <template slot="cost" slot-scope="text, record">
          <span :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.cost }}</span>
        </template>
        <template slot="profit" slot-scope="text, record">
          <span :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.profit }}</span>
        </template>
        <template slot="rate1" slot-scope="text, record">
          <span :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.rate1 }}</span>
        </template>
        <template slot="rate2" slot-scope="text, record">
          <span :class="{ 'text-h6 error--text': record.province === '合计' }">{{ record.rate2 }}</span>
        </template>
      </a-table>
    </v-card>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";

export default {
  name: "FinanceManageDaily",
  data() {
    return {
      queryForm: {
        month: this.$moment().format("YYYY-MM"),
        start_day: this.$moment().format("YYYY-MM-01"),
        end_day: this.$moment().format("YYYY-MM-DD"),
      },
      tab: 0,
      tablist: [
        { text: '汇总', value: 0 },
        { text: '河南', value: 1 },
        { text: '广东', value: 2 },
        { text: '江西', value: 3 },
        { text: '浙江', value: 4 },
        { text: '广西', value: 5 },
        { text: '按线路', value: 6 },
      ],
      headers1: [
        { title: "项目", dataIndex: "province", scopedSlots: { customRender: "province" }, width: 100, align: "center" },
        { title: "发车数", dataIndex: "count", scopedSlots: { customRender: "count" }, width: 100, align: "center" },
        { title: "上游运费", dataIndex: "up_freight", scopedSlots: { customRender: "up_freight" }, width: 200, align: "center" },
        { title: "下游运费", dataIndex: "down_freight", scopedSlots: { customRender: "down_freight" }, width: 200, align: "center" },
        { title: "自营运费", dataIndex: "self_freight", scopedSlots: { customRender: "self_freight" }, width: 200, align: "center" },
        { title: "总成本", dataIndex: "cost", scopedSlots: { customRender: "cost" }, width: 200, align: "center" },
        { title: "利润", dataIndex: "profit", scopedSlots: { customRender: "profit" }, width: 200, align: "center" },
        { title: "成本利润率", dataIndex: "rate1", scopedSlots: { customRender: "rate1" }, width: 150, align: "center" },
        { title: "销售利润率", dataIndex: "rate2", scopedSlots: { customRender: "rate2" }, width: 150, align: "center" },
        // { title: "状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 120, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: 'right' },
      ],
      headers2: [
        { title: "封车日期", dataIndex: "block_day", scopedSlots: { customRender: "block_day" }, width: 150, align: "center" },
        { title: "发车数", dataIndex: "count", scopedSlots: { customRender: "count" }, width: 150, align: "center" },
        { title: "上游运费", dataIndex: "up_freight", scopedSlots: { customRender: "up_freight" }, width: 150, align: "center" },
        { title: "下游运费", dataIndex: "down_freight", scopedSlots: { customRender: "down_freight" }, width: 150, align: "center" },
        { title: "自营运费", dataIndex: "self_freight", scopedSlots: { customRender: "self_freight" }, width: 150, align: "center" },
        { title: "总成本", dataIndex: "cost", scopedSlots: { customRender: "cost" }, width: 150, align: "center" },
        { title: "利润", dataIndex: "profit", scopedSlots: { customRender: "profit" }, width: 150, align: "center" },
        { title: "成本利润率", dataIndex: "rate1", scopedSlots: { customRender: "rate1" }, width: 150, align: "center" },
        { title: "销售利润率", dataIndex: "rate2", scopedSlots: { customRender: "rate2" }, width: 150, align: "center" },
        // { title: "状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 120, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: 'right' },
      ],
      headers3: [
        { title: "项目", dataIndex: "province", scopedSlots: { customRender: "province" }, width: 100, align: "center" },
        { title: "线路", dataIndex: "postline", scopedSlots: { customRender: "postline" }, width: 150, align: "center" },
        // { title: "单程/往返", dataIndex: "transport_kind", scopedSlots: { customRender: "transport_kind" }, width: 150, align: "center" },
        { title: "发车数", dataIndex: "count", scopedSlots: { customRender: "count" }, width: 150, align: "center" },
        { title: "上游运费", dataIndex: "up_freight", scopedSlots: { customRender: "up_freight" }, width: 150, align: "center" },
        { title: "下游运费", dataIndex: "down_freight", scopedSlots: { customRender: "down_freight" }, width: 150, align: "center" },
        { title: "自营运费", dataIndex: "self_freight", scopedSlots: { customRender: "self_freight" }, width: 150, align: "center" },
        { title: "总成本", dataIndex: "cost", scopedSlots: { customRender: "cost" }, width: 150, align: "center" },
        { title: "利润", dataIndex: "profit", scopedSlots: { customRender: "profit" }, width: 150, align: "center" },
        { title: "成本利润率", dataIndex: "rate1", scopedSlots: { customRender: "rate1" }, width: 150, align: "center" },
        { title: "销售利润率", dataIndex: "rate2", scopedSlots: { customRender: "rate2" }, width: 150, align: "center" },
        // { title: "状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 120, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: 'right' },
      ],
      list: [],
      height: 600,
      start_day_menu: false,
      end_day_menu: false,
      month_menu: false,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width1() {
      return _.reduce(this.headers1, (a, b) => a + (b.width || 300), 0);
    },
    width2() {
      return _.reduce(this.headers2, (a, b) => a + (b.width || 300), 0);
    },
    width3() {
      return _.reduce(this.headers3, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  mounted() {
    this.height = window.innerHeight - 300;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 300;
    };
    this.tab = 0;
    this.list = []
    this.queryForm = {
      month: this.$moment().format("YYYY-MM"),
      start_day: this.$moment().format("YYYY-MM-01"),
      end_day: this.$moment().format("YYYY-MM-DD"),
    };
    this.getdata();
  },
  activated() {
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/dailyreport/list", { ...this.queryForm, tab: this.tab, tablist: this.tablist }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          if (this.tab > this.tablist[0].value && this.tab < this.tablist[this.tablist.length - 1].value) {
            res.data.list.sort((a, b) => new Date(b.block_day) - new Date(a.block_day));
          }
          if (this.tab === this.tablist[this.tablist.length - 1].value) {
            res.data.list = _.map(res.data.list, n => {
              let index = _.findWhere(this.tablist, { text: n.province }).value;
              return {
                ...n,
                index,
              }
            });
            res.data.list.sort((a, b) => {
              if (a.index === b.index) return a.postline.localeCompare(b.postline);
              return a.index - b.index;
            });
          }
          this.list = _.map(res.data.list, n => {
            return {
              ...n,
              up_freight: this.$utils.formatMoney(n.up_freight, 2, ''),
              down_freight: this.$utils.formatMoney(n.down_freight, 2, ''),
              self_freight: this.$utils.formatMoney(n.self_freight, 2, ''),
              cost: this.$utils.formatMoney(n.cost, 2, ''),
              profit: this.$utils.formatMoney(n.profit, 2, ''),
            }
          });
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    download() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/dailyreport/list", { ...this.queryForm, tab: this.tab, action: 'download' }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    changetab(tab) {
      console.log('changetab', tab);
      this.list = [];
      this.getdata();
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .row+.row {
  margin-top: 0;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
