import { v4 as uuidv4 } from 'uuid';
import { Base64 } from 'js-base64';
import moment from 'moment';
moment.locale('zh_cn');
import _ from "underscore";
import axios from 'axios';
import { Dialog, Toast } from "vant";
import Decimal from 'decimal.js';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { cn as nzhcn } from "nzh";
import html2pdf from 'html2pdf.js';
import store from './store';

function sliceFile(size) {
  let chunksize = 512 * 1024;//切片大小 512k
  let res = [];
  if (size <= chunksize) {
    res.push({ chunk: 0, chunks: 1 });
  } else {
    let chunks = size % chunksize == 0 ? Math.floor(size / chunksize) : Math.floor(size / chunksize) + 1;
    for (let i = 0; i < chunks; i++) {
      res.push({ chunk: i, chunks, from: chunksize * i, to: (i == chunks - 1) ? size : chunksize * (i + 1) });
    }
  }
  return res;
}
function makeUUID() {
  return uuidv4().replace(/-/g, '');
}
function dateTime(date, pattern = "YYYY-MM-DD") {
  date = moment(date);
  if (date.isValid()) return date.format(pattern);
  return "";
}
function fromNow(date) {
  return moment(date).fromNow();
}
function callPhone(phone) {
  let a = document.createElement("a"), //创建a标签
    e = document.createEvent("MouseEvents"); //创建鼠标事件对象
  e.initEvent("click", false, false); //初始化事件对象
  a.href = 'tel:' + phone; //设置下载地址
  a.target = "_blank";
  a.dispatchEvent(e); //给指定的元素，执行事件click事件
}
function sleep(miliseconds = 1500) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, miliseconds);
  });
}
function toBase64String(str) {
  return Base64.encodeURI(str);
}
function shareVersion() {
  return moment().format('YYYYMMDDHHmmss');
}
function downloadBrowser({ name, href }) {
  var a = document.createElement("a"),
    e = document.createEvent("MouseEvents");
  e.initEvent("click", false, false);
  a.href = href;
  a.target = "_blank";
  if (name) a.download = encodeURIComponent(name);
  a.dispatchEvent(e);
}
function randomPassword() {
  return (Math.random() + "").substring(2, 8);
}
function alert(text) {
  return Dialog.alert({ title: "提示", message: text });
}
function isDecimal(n) {
  try {
    new Decimal(n);
    return true;
  } catch (e) { console.error(e) }
  return false;
}
function d(n = 0) {
  return new Decimal(n);
}
function ds(n, l = 0) {
  if (!n) return "";
  return d(n).toFixed(l);
}
function dsp(n, l = 0) {
  if (!n) return "";
  let v = d(n).times(100).toFixed(l);
  if (v === 'NaN') return '-';
  return v + '%';
}
function textToColor(str) {
  if (!str || str.length === 0)
    return false;
  for (var i = 0, hash = 0; i < str.length; hash = str.charCodeAt(i++) + ((hash << 5) - hash));
  for (var j = 0, color = '#'; j < 3; color += ('00' + ((hash >> j++ * 2) & 0xFF).toString(16)).slice(-2));
  return color;
}
function setStoreAdmin({ userid, username, name, role }) {
  localStorage.setItem("lidi.userid", userid);
  localStorage.setItem("lidi.username", username);
  localStorage.setItem("lidi.name", name);
  localStorage.setItem("lidi.role", role);
  store.dispatch("SetUserid", userid);
  store.dispatch("SetUsername", username);
  store.dispatch("SetName", name);
  store.dispatch("SetRole", role);
}
function formatMoney(number, places, symbol, thousand, decimal) {
  number = Number(number) || 0;
  places = !isNaN(places = Math.abs(places)) ? places : 0;
  symbol = symbol !== undefined ? symbol : "￥";
  thousand = thousand || ",";
  decimal = decimal || ".";
  var negative = number < 0 ? "-" : "";
  var i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
  return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
}
function getBuffer(url) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer'
    }).then(data => {
      resolve(data.data);
    }).catch(error => {
      console.error(error);
      resolve(null);
    })
  })
}
function getBlob(url) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'blob'
    }).then(data => {
      resolve(data.data);
    }).catch(error => {
      console.error(error);
      resolve(null);
    })
  })
}
function getHtml(url) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'text'
    }).then(data => {
      resolve(data.data);
    }).catch(error => {
      console.error(error);
      resolve(null);
    })
  })
}
function saveBlob(blob, name) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, name);
    Toast.success("下载完成");
  } else {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob); // 创建对象url
    link.download = name;
    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);
    link.click();
    body.removeChild(link);
    window.URL.revokeObjectURL(link.href); // 通过调用 URL.createObjectURL() 创建的 URL 对象
    Toast.success("下载完成");
  }
}
function download(url, name = '') {
  Toast.loading({ message: "正在下载" });
  getBlob(url).then((blob) => {
    saveBlob(blob, name);
  }).catch(() => {
    Toast.fail("下载失败");
  });
}
/**
 * 批量打包zip包下载
 * @param files Array [{url: 下载文件的路径, name: 下载文件名称}]
 * @param zipname zip文件名
 */
function downloadZip(files, zipname) {
  zipname = zipname || "打包下载";
  if (files.length === 0) return;
  if (files.length === 1) return download(files[0].url, files[0].name);
  Toast.loading({ message: "正在下载" });
  const zip = new JSZip();
  const cache = {};
  const promises = [];
  files.forEach((item) => {
    const promise = getBlob(item.url).then((data) => { // 下载文件, 并存成ArrayBuffer对象
      zip.file(item.name, data, { binary: true }) // 逐个添加文件
      cache[item.name] = data
    })
    promises.push(promise);
  });

  Promise.all(promises).then(() => {
    zip.generateAsync({ type: 'blob' }).then((content) => { // 生成二进制流
      FileSaver.saveAs(content, `${zipname}.zip`); // 利用file-saver保存文件
      Toast.success("下载完成");
    });
  });
}
async function makePDF({ url, options }) {
  Toast.loading({ message: "正在保存" });
  let html = await getHtml(url);
  await html2pdf()
    .set(options)
    .from(html, 'string')
    .toPdf()
    .save();
  Toast.success("已保存");
}
async function makePDFs(list) {
  for (let i = 0; i < list.length; i++) {
    await makePDF(list[i]);
  }
}
function numtocn(num) {
  return nzhcn.toMoney(num).replace('人民币', '');
}
function readBuffer(file, start = 0, end = 2) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file.slice(start, end));
  });
}
function check(headers) {
  return (buffers, options = { offset: 0 }) =>
    headers.every(
      (header, index) => header === buffers[options.offset + index]
    );
}
async function isPNG(file) {
  const buffers = await readBuffer(file, 0, 8);
  const uint8Array = new Uint8Array(buffers);
  return check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a])(uint8Array);
}
async function isJPEG(file) {
  const buffers = await readBuffer(file, 0, 3);
  const uint8Array = new Uint8Array(buffers);
  return check([0xff, 0xd8, 0xff])(uint8Array);
}
async function isXLS(file) {
  const buffers = await readBuffer(file, 0, 8);
  const uint8Array = new Uint8Array(buffers);
  return check([0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1])(uint8Array);
}
async function isXLSX(file) {
  const buffers = await readBuffer(file, 0, 4);
  const uint8Array = new Uint8Array(buffers);
  return check([0x50, 0x4b, 0x03, 0x04])(uint8Array);
}
//将base64转换为文件
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
//调用
// var file = dataURLtoFile(base64Data, imgName);

//将base64转换为blob
function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
//将blob转换为file
function blobToFile(theBlob, fileName) {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}
//调用
// var blob = dataURLtoBlob(base64Data);
// var file = blobToFile(blob, imgName);

export default {
  sliceFile,
  makeUUID,
  uuid: makeUUID,
  dateFormat: dateTime,
  dateTime,
  fromNow,
  callPhone,
  toBase64String,
  downloadBrowser,
  sleep,
  randomPassword,
  shareVersion,
  alert,
  isDecimal,
  d,
  ds,
  dsp,
  moment,
  textToColor,
  setStoreAdmin,
  formatMoney,
  getBlob,
  saveBlob,
  download,
  downloadZip,
  makePDF,
  makePDFs,
  numtocn,
  isPNG,
  isJPG: isJPEG,
  isJPEG,
  isXLS,
  isXLSX,
  dataURLtoFile,
  dataURLtoBlob,
  blobToFile,
};