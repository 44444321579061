<template>
  <v-container fluid>
    <div class="mm">
      <div class="header">车辆承运合同</div>
      <div class="number">协议编号：{{ contract.contract_no || "/" }}</div>
      <div class="section">甲方：河南利滴物流有限公司</div>
      <div class="section">
        乙方：
        <span id="name">{{ contract.name || "/" }}</span> 身份证号码：
        <span id="idno">{{ contract.certificate || "/" }}</span>
      </div>
      <div class="section">
        为确保甲方货物安全、准确、准时到达目的地，在平等互利的基础上，甲、乙双方经过友好协商，就甲方委托乙方承运甲方省际干线货物运输事宜签订本合同，以资共同信守：
      </div>
      <div class="section">
        一、合同有效期从
        <span class="t">{{ contract.start_date | dateTime("YYYY") }}</span> 年
        <span class="t">{{ contract.start_date | dateTime("M") }}</span> 月
        <span class="t">{{ contract.start_date | dateTime("D") }}</span> 日起至
        <span>{{ contract.end_date | dateTime("YYYY") }}</span> 年
        <span class="t">{{ contract.end_date | dateTime("M") }}</span> 月
        <span class="t">{{ contract.end_date | dateTime("D") }}</span> 日。
      </div>
      <div class="section">
        二、合同期内，甲方委托乙方（运输车辆的法定使用人）承运
        <span>{{ contract.start_place || "/" }}</span> 至
        <span>{{ contract.end_place || "/" }}</span> 的
        <span>{{ contract.goods_name || "/" }}</span>
        ，运输方式为汽车公路运输，乙方名下的车辆车牌号为：
        <span>{{ contract.carnumber || "/" }}</span>
        ；乙方为甲方提供的运输车辆为厢式货车，GPS登记号码为：
        <span>{{ contract.gpsnumber || "/" }}</span>
        ，从本合同签订到交车需要在1个小时内，乙方及车辆具体信息详见合同附件（乙方身份证、驾驶证、车辆行驶证、营运证），复印件作为本合同的附件与本合同具有同等法律效力。
      </div>
      <div class="section">三、运输费用及结算方式</div>
      <div class="section">
        1、结算金额：
        <span>{{ contract.money || "/" }}</span> 元，人民币大写：
        <span>{{ contract.money_cn || "/" }}</span> 结算周期为：
        <span>{{ contract.billing_cycle || "/" }}</span> 油卡卡号：
        <span>{{ contract.fuelcard_no || "/" }}</span>
        运费结算方式：
        <span> {{ contract.settlement_method || "/" }} </span>。
      </div>
      <div class="section">
        2、乙方收款账号：
        <span>{{ contract.bankcard_no || "/" }}</span> 户名：
        <span>{{ contract.bankcard_host || "/" }} </span> 开户：
        <span>{{ contract.bank_name || "/" }} </span>
      </div>
      <div class="section">四、双方权利义务及责任条款</div>
      <div class="section">
        1、甲乙双方权利义务见本协议第2页附件《双方权利义务及责任条款》；
      </div>
      <div class="section">
        2、甲乙双方承诺已详细阅读并理解本协议第2页背面附件内《双方权利义务及责任条款》约定的权利义务的相关内容，并遵守执行。
      </div>
      <div class="section sign">
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <div class="left">
              <p>甲方：河南利滴物流有限公司（盖章）</p>
              <p>
                代表人：<span></span>
              </p>
              <p>
                电话： <span></span>
              </p>
              <p>
                日期：
                <span class="t">{{ contract.sign_date | dateTime("YYYY") }}</span>年
                <span class="t">{{ contract.sign_date | dateTime("M") }}</span>月
                <span class="t">{{ contract.sign_date | dateTime("D") }}</span>日
              </p>
              <img src="/image/zhang.png" style="width: 128px;height: 128px;position: absolute;top: -20px;left: 100px;" />
            </div>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <div class="right">
              <p style="display: flex">
                <span style="border: none; display: inline-block; width: 70px">
                  乙方：
                </span>
                <img :src="contract.signature ? contract.signature : 'data:image/png;base64'
                  " alt=""
                  style="display: inline-block;height: 40px;position: relative;top: -15px;border-bottom: 1px solid;" />
              </p>
              <p>
                身份证号码：
                <span>{{ contract.certificate || "/" }}</span>
              </p>
              <p>
                电话：
                <span>{{ contract.phone || "/" }}</span>
              </p>
              <p>
                日期：
                <span class="t">{{ contract.sign_date | dateTime("YYYY") }}</span> 年
                <span class="t">{{ contract.sign_date | dateTime("M") }}</span> 月
                <span class="t">{{ contract.sign_date | dateTime("D") }}</span> 日
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="section">附件：</div>
      <div class="section">
        <div class="header">《双方权利义务及责任条款》</div>
      </div>
      <div class="section">一、甲方的权利义务</div>
      <div class="section">
        1、乙方车辆在甲方起运地与到达地的货物装卸均由甲方工作人员负责，核定装载重量以内。
      </div>
      <div class="section">
        2、甲方有权根据自身运营需求安排乙方的发车时间，或是临时调整乙方车辆的运输线路。
      </div>
      <div class="section">二、乙方的权利义务</div>
      <div class="section">
        1、乙方名下的运输车辆及驾驶人员，必须证件及保险齐全符合国家规定的标准，如因车辆不符合国家标准及相关规定所出现的一切后果由乙方自行承担。
      </div>
      <div class="section">
        2、按照甲方运输要求，在规定的时间内到达目的地，并将货物安全运送到甲方指定的地点，交给甲方指定收货人。
      </div>
      <div class="section">
        3、乙方有保障货物安全到达目的地的义务，一旦发生雨淋、短缺、丢失、损坏及车辆货损造成货物损失的，应照价赔偿。
      </div>
      <div class="section">
        4、乙方应当依照规定投保机动车交通事故责任强制保险、车上人员责任险（保险人数至少为2人，每人最低保险额为10万元）、第三者商业责任险（最低保额100万元）并将货物保险保单原件交甲方验视，复印件交甲方备案。乙方故意隐瞒或不参加法律规定参加保险和约定保险而产生纠纷的，甲方不承担任何法律责任，乙方在承运中发生各类事故导致人员伤亡的，由乙方负责处理及赔偿，甲方不承担任何责任。
      </div>
      <div class="section">三、考核标准</div>
      <div class="section">
        1、甲方交付乙方承运的货物均系贵重物品，乙方应予以高度重视，确保货物准时、安全运达。非因不可抗力（如雨雪天气、交通管制、堵车等）造成货物逾期运达的，如客户追究甲方责任，乙方应全额赔偿甲方的经济损失。因不可抗力（如雨雪天气、交通管制、堵车等）造成货物无法按期运达目的地时，乙方须及时向甲方报备（根据甲方异常报备要求），乙方应第一时间通知甲方并向甲方提供相关证明，以便甲方与客户报备在途异常与进行协调。乙方应保证其所提供信息的真实性，如乙方有谎报或隐瞒，给甲方所造成的经济损失由乙方全部承担。
      </div>
      <div class="section">
        2、乙方车辆在甲方场地装卸货时，乙方司机及随车人员应服从甲方公司规定，未经允许不得擅自在甲方（客户）库房内随意走动，严禁在甲方场地内使用明火或吸烟，如有违反参照甲方公司规章制度进行处罚。
      </div>
      <div class="section">四、乙方违规处罚</div>
      <div class="section">
        1、GPS无出入围栏：①GPS未通电或者中途断电；②设备信号未第一时间报备与工作人员且未作任何补救措施；违反上述任意一条款运费
        5000以下罚款 200元，运费 5000以上罚款 500元；③GPS 设备损坏或丢失罚款 350元；
      </div>
      <div class="section">
        2、运输晚点∶乙方未按甲方要求全程走高速公路运输，中途异常停留，违章，事故，车辆故障等导致晚点，按照晚点时长计算，运费
        5000 以下罚款 200元/小时，运费 5000 以上罚款 500元/小时；
      </div>
      <div class="section">
        3、异常报备∶①客观原因晚点未及时报备按照晚点时长罚款100/小时；最少按 0.5
        小时计算；②客观原因晚点虚假报备，报备材料不合格，且乙方不按甲方要求处理，视为运输晚点按照上述第二点处罚；
      </div>
      <div class="section">
        4、乙方在运输途中无权以任何理由对运输的货物进行任何形式的留置，处理，变卖，抵押或故意拖延甲方委托乙方所承运的货物、乙方对运输货物无任何占有，使用及处分的权利；如乙方留置，扣押行为达半小时以上或向甲方主张任何形式的款项时（含剩余应付运输款），则乙方向甲方支付本趟次运费10倍金额的违约金，并保留追诉的权利。
      </div>
      <div class="section">
        5、乙方有义务按甲方约定时效完成运输，发生运输超时效及时报备甲方。若乙方出现运输超时效问题.甲方有权根据实际情况和上游客户考核金额对乙方进行处罚。
      </div>
      <div class="section">
        6、任何因本合同引发的纷争，败诉方应该承担胜诉方为此支付的律师费用、差旅费用、调查费用、公证和认证费用、翻译费用等额外开支。
      </div>
      <div class="section">五、合同的终止</div>
      <div class="section">
        1、在合同履行期间，任何一方不得使用不正当手段解决问题甚至出现打架斗殴等暴力行为，如发生此类情况任何一方有权利单方面解除此合同。
      </div>
      <div class="section">
        2、在合同履行期间，乙方应配合甲方公司运营操作，如不配合甲方有权按公司规章制度予以处罚直至解除合同，如造成乙方过失或不配合造成的损失，由乙方全部承担。
      </div>
      <div class="section">六、争议的解决方式</div>
      <div class="section">
        本合同履行过程中如甲乙双方发生纠纷，双方应采取和平协商的手段进行解决，协商不成的向甲方所在地人民法院起诉。
      </div>
      <div class="section">
        七、本合同未尽事宜，双方协商签订书面补充协议。补充协议作为本合同的组成部分，与本合同具有同等法律效力。
      </div>
      <div class="section">
        八、本合同一式两份，双方各持一份，具同等法律效力，双方签字盖章后生效。
      </div>
      <div class="footer" v-if="!contract.signature">
        <div class="my-2">
          <v-btn color="primary" dark style="width: 100%" @click="showsign">
            我要签名
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" fullscreen persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          请在下面输入框中完成签名
        </v-card-title>
        <v-card-text>
          <sign-canvas v-if="dialog" class="sign-canvas" ref="SignCanvas" :options="signoptions" v-model="signvalue" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="canvasClear()">撤销</v-btn>
          <v-btn color="primary" button @click="saveAsImg()">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import SignCanvas from "sign-canvas";
Vue.use(SignCanvas);
export default {
  name: "ContractInfo",
  data() {
    return {
      dialog: false,
      signoptions: {
        lastWriteSpeed: 1, //书写速度 [Number] 可选
        lastWriteWidth: 2, //下笔的宽度 [Number] 可选
        lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]    正方形线帽
        lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
        // canvasWidth: 700, //canvas宽高 [Number] 可选
        canvasHeight: 150, //高度  [Number] 可选
        isShowBorder: false, //是否显示边框 [可选]
        bgColor: "#fff", //背景色 [String] 可选
        borderWidth: 1, // 网格线宽度  [Number] 可选
        borderColor: "#ff787f", //网格颜色  [String] 可选
        writeWidth: 5, //基础轨迹宽度  [Number] 可选
        maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
        minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
        writeColor: "#333", // 轨迹颜色  [String] 可选
        isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        imgType: "png", //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
      },
      signvalue: null,
      contract: {},
    };
  },
  activated() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.post("/api/lidi/contract/getinfo", { uuid: this.$route.params.id }).then((res) => {
        console.log(res);
        this.$toast.clear();
        this.contract = res;
      }).catch((err) => {
        this.$toast.clear();
      });
    },
    showsign() {
      this.signoptions.canvasWidth = window.innerWidth - 48;
      // this.signoptions.canvasHeight = window.innerHeight - 160;
      this.dialog = true;
    },
    /*** 清除画板*/
    canvasClear() {
      this.$refs.SignCanvas.canvasClear();
    },
    /*** 保存图片*/
    saveAsImg() {
      const img = this.$refs.SignCanvas.saveAsImg();
      // console.log(img);
      this.$dialog.confirm({ title: "提示", message: "提交签名" }).then(() => {
        // this.contract.signature = img;
        // this.$emit("update:visible", false);
        // this.dialog = false;
        console.log("确定");
        this.$toast.loading({ message: "" });
        this.$api.post("/api/lidi/contract/setsign", { uuid: this.$route.params.id, version: this.contract.version, signature: img, }).then((res) => {
          console.log(res);
          this.$toast.clear();
          if (res.code === 0) {
            this.contract.signature = img;
            this.$emit("update:visible", false);
            this.dialog = false;
          } else {
            this.$alert(res.msg);
          }
        }).catch((err) => {
          this.$toast.clear();
        });
      }).catch(() => {
        console.log("取消");
      });
    },
  },
};
</script>
<style lang="less" scope>
.mm {
  padding: 20px;
  margin-bottom: 40px;

  .header {
    font-size: 1.5rem;
    text-align: center;
  }

  .number {
    text-align: right;
  }

  .section {
    text-indent: 24px;
    padding: 10px 0;
  }

  .section span {
    min-width: 100px;
    max-width: 320px;
    display: inline-block;
    border-bottom: 1px solid #333;
  }

  .section span.t {
    width: 50px;
  }

  .section.sign {}

  .section.sign .left {
    position: relative;
  }

  .section.sign .right {}
}
</style>
