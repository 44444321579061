<template>
  <div ref="canvasMain">
    <a-button v-if="!hidebtn" @click="capture" type="primary">
      截图
    </a-button>
    <a-table size="small" :customHeaderRow="customHeaderRow" :rowClassName="rowClassName" :columns="columns" :data-source="data"
      bordered :pagination="false" row-key="_id" :scroll="{ x: true }">
      <div slot="carrier" slot-scope="text, record" class="text-h6 font-weight-bold padding-cell">
        {{ record.carrier }}
      </div>
      <div slot="A" slot-scope="text, record" class="text-h6 font-weight-bold padding-cell bg-day">
        {{ record.A }}
      </div>
      <div slot="B" slot-scope="text, record" class="text-h6 font-weight-bold padding-cell bg-day">
        {{ record.B }}
      </div>
      <div slot="C" slot-scope="text, record" class="text-h6 font-weight-bold padding-cell bg-day">
        {{ record.C }}
      </div>
      <div slot="D" slot-scope="text, record" class="text-h6 font-weight-bold padding-cell bg-day">
        {{ record.D ? record.D + '%' : '-' }}
      </div>
      <div slot="E" slot-scope="text, record" class="text-h6 font-weight-bold padding-cell bg-day">
        {{ record.E }}
      </div>
      <div slot="F" slot-scope="text, record" class="text-h6 font-weight-bold padding-cell bg-day">
        {{ record.F ? record.F + '%' : '-' }}
      </div>
      <div slot="H" slot-scope="text, record, index"
        :class="{ 'text-h6 font-weight-bold padding-cell bg-month': true, 'bg-img': index < data.length - 1 }"
        :style="index < data.length - 1 ? { 'background-size': `${record.H_rate}% 100%` } : {}">
        {{ record.H }}
      </div>
      <div slot="I" slot-scope="text, record" class="text-h6 font-weight-bold padding-cell bg-month">
        {{ record.I }}
      </div>
      <div slot="J" slot-scope="text, record, index"
        :class="{ 'text-h6 font-weight-bold padding-cell bg-month': true, 'bg-img': index < data.length - 1 }"
        :style="index < data.length - 1 ? { 'background-size': `${record.J_rate}% 100%`, } : {}">
        {{ record.J }}
      </div>
      <div slot="K" slot-scope="text, record" class="text-h6 font-weight-bold padding-cell bg-month">
        {{ record.K ? record.K + '%' : '-' }}
      </div>
      <div slot="L" slot-scope="text, record, index"
        :class="{ 'text-h6 font-weight-bold padding-cell bg-month': true, 'bg-img': index < data.length - 1 }"
        :style="index < data.length - 1 ? { 'background-size': `${record.L_rate}% 100%`, } : {}">
        {{ record.L }}
      </div>
      <div slot="M" slot-scope="text, record" class="text-h6 font-weight-bold padding-cell bg-month">
        {{ record.M ? record.M + '%' : '-' }}
      </div>
    </a-table>
  </div>
</template>
<script>
import _ from "underscore";
import html2canvas from 'html2canvas';
export default {
  data: function () {
    return {
      columns: [],
      data: [],
      hidebtn: false,
    };
  },
  activated() {
    if (!this.$route.query.date) return this.$router.replace({ path: "/" });
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/statistics/carrierqualitydata", { date: this.$route.query.date }).then((res) => {
        this.data = this.makedata(res.data);
        this.$toast.clear();
      });
    },
    makedata({ date, list }) {
      let data = [];
      let total = { A: 0, B: 0, C: 0, A3: 0, E: 0, H: 0, I: 0, J: 0, A7: 0, L: 0, };
      for (let i = 0; i < list.length; i++) {
        let { carrier, A1: A, A2: B, A3, A4: E, A5: H, A6: I, A7, A8: L, B1: C, B2: D, B3: F, B4: J, B5: K, B6: M } = list[i];
        data.push({ _id: this.$utils.uuid(), carrier, A, B, C, D, E, F, H, I, J, K, L, M });
        total.A += Number(A);
        total.B += Number(B);
        total.C += Number(C);
        total.A3 += Number(A3);
        total.E += Number(E);
        total.H += Number(H);
        total.I += Number(I);
        total.J += Number(J);
        total.A7 += Number(A7);
        total.L += Number(L);
      }
      total.D = total.B > 0 ? this.$utils.d(total.A3).dividedBy(total.B).times(100).toFixed(2) : 0;
      total.F = total.B > 0 ? this.$utils.d(total.B).minus(total.E).dividedBy(total.B).times(100).toFixed(2) : 0;
      total.K = total.I > 0 ? this.$utils.d(total.A7).dividedBy(total.I).times(100).toFixed(2) : 0;
      total.M = total.I > 0 ? this.$utils.d(total.I).minus(total.L).dividedBy(total.I).times(100).toFixed(2) : 0;
      let maxH = _.max([data[0].H, data[1].H, data[2].H, data[3].H]);
      let maxJ = _.max([data[0].J, data[1].J, data[2].J, data[3].J]);
      let maxL = _.max([data[0].L, data[1].L, data[2].L, data[3].L]);
      for (let i = 0; i < data.length; i++) {
        data[i].H_rate = maxH > 0 ? this.$utils.d(data[i].H).dividedBy(maxH).times(100).toFixed(0) : 0;
        data[i].J_rate = maxJ > 0 ? this.$utils.d(data[i].J).dividedBy(maxJ).times(100).toFixed(0) : 0;
        data[i].L_rate = maxL > 0 ? this.$utils.d(data[i].L).dividedBy(maxL).times(100).toFixed(0) : 0;
      }
      data.sort((a, b) => b.A - a.A);
      data.push({ _id: this.$utils.uuid(), carrier: "合计", ...total });
      this.columns = [
        {
          title: "承运商质量数据通报",
          children: [
            {
              title: `统计周期 ${date}`, align: 'left',
              children: [
                { title: "承运商", dataIndex: "carrier", width: 100, scopedSlots: { customRender: "carrier" } },
                {
                  title: "当日完成情况", dataIndex: "day", customHeaderCell: this.customHeaderCell,
                  children: [
                    { dataIndex: "A", width: 100, title: "发班趟次", scopedSlots: { customRender: "A" }, customHeaderCell: this.customHeaderCell, },
                    { dataIndex: "B", width: 100, title: "到达趟次", scopedSlots: { customRender: "B" }, customHeaderCell: this.customHeaderCell, },
                    { dataIndex: "C", width: 120, title: "无GPS趟次", scopedSlots: { customRender: "C" }, customHeaderCell: this.customHeaderCell, },
                    { dataIndex: "D", width: 120, title: "GPS采集率", scopedSlots: { customRender: "D" }, customHeaderCell: this.customHeaderCell, },
                    { dataIndex: "E", width: 100, title: "晚点趟次", scopedSlots: { customRender: "E" }, customHeaderCell: this.customHeaderCell, },
                    { dataIndex: "F", width: 100, title: "准点率", scopedSlots: { customRender: "F" }, customHeaderCell: this.customHeaderCell, },
                  ]
                },
                {
                  title: "当月完成情况", dataIndex: "month", customHeaderCell: this.customHeaderCell,
                  children: [
                    { dataIndex: "H", width: 100, title: "发班趟次", scopedSlots: { customRender: "H" }, customHeaderCell: this.customHeaderCell, },
                    { dataIndex: "I", width: 100, title: "到达趟次", scopedSlots: { customRender: "I" }, customHeaderCell: this.customHeaderCell, },
                    { dataIndex: "J", width: 120, title: "无GPS趟次", scopedSlots: { customRender: "J" }, customHeaderCell: this.customHeaderCell, },
                    { dataIndex: "K", width: 120, title: "GPS采集率", scopedSlots: { customRender: "K" }, customHeaderCell: this.customHeaderCell, },
                    { dataIndex: "L", width: 100, title: "晚点趟次", scopedSlots: { customRender: "L" }, customHeaderCell: this.customHeaderCell, },
                    { dataIndex: "M", width: 100, title: "准点率", scopedSlots: { customRender: "M" }, customHeaderCell: this.customHeaderCell, },
                  ]
                },
              ]
            },
          ]
        }
      ];
      return data;
    },
    customHeaderRow(column, index) {
      // console.log('customHeaderRow', column, index);
      column.map(n => {
        let className = '';
        if (index === 0) className = ' text-h6 font-weight-bold';
        else if (index === 1) className = ' text-h6 font-weight-bold';
        else className = ' text-h6 font-weight-bold text-center';
        n.className = className;
      });
    },
    customHeaderCell(column) {
      // console.log('customHeaderCell', column);
      let day_columns = ["day", "A", "B", "C", "D", "E", "F"];
      let month_columns = ["month", "H", "I", "J", "K", "L", "M",];
      if (day_columns.indexOf(column.dataIndex) > -1) column.className += ' text-h6 font-weight-bold bg-day';
      if (month_columns.indexOf(column.dataIndex) > -1) column.className += ' text-h6 font-weight-bold bg-month';
      return column;
    },
    rowClassName(record, index) {
      // console.log('rowClassName', record, index);
      return 'text-h6 font-weight-bold text-center';
    },
    capture() {
      this.hidebtn = true;
      setTimeout(() => {
        // let BaseScreenshot = this.$refs.canvasMain
        let BaseScreenshot = document.body
        // 保存滚动位置
        const [x, y] = [window.scrollX, window.scrollY]

        // 滚动的初始位置
        window.scrollTo(0, 0)

        // 生成截图
        html2canvas(BaseScreenshot, {
          scrollY: 0,
          scrollX: 0,
          // 开启跨域配置
          useCORS: true,
          // canvas高
          height: BaseScreenshot.scrollHeight,
          // canvas宽
          width: BaseScreenshot.scrollWidth
        }).then(canvas => {
          let base64Data = canvas.toDataURL('image/jpeg', 1.0);
          let blob = this.$utils.dataURLtoBlob(base64Data);
          this.$utils.saveBlob(blob, "承运商质量数据通报.jpg");
          // 恢复滚动位置
          window.scrollTo(x, y)
          this.hidebtn = false;
        });
      }, 100);
    },
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-table-thead>tr>th {
  padding: 8px;
}
/deep/ .ant-table-tbody>tr>td {
  padding: 0;
}

/deep/ .ant-table th {
  white-space: nowrap;
}

/deep/ .ant-table td {
  white-space: nowrap;
}

/deep/ .ant-table-bordered .ant-table-thead>tr:not(:last-child)>th {
  border-bottom: 1px solid #000;
}

/deep/ .ant-table-bordered .ant-table-thead>tr>th,
.ant-table-bordered .ant-table-tbody>tr>td {
  border-right: 1px solid #000;
}

/deep/ .ant-table-bordered .ant-table-thead>tr>th {
  border-right: 1px solid #000;
}

/deep/ .ant-table-bordered .ant-table-tbody>tr>td {
  border-right: 1px solid #000;
}

/deep/.ant-table-thead>tr>th {
  border-bottom: 1px solid #000;
}

/deep/ .ant-table-tbody>tr>td {
  border-bottom: 1px solid #000;
}

.padding-cell {
  padding: 8px;
}

/deep/ .bg-day {
  background-color: #FCE4D3;
}

/deep/ .bg-month {
  background-color: #FEE796;
}

.bg-img {
  background-image: linear-gradient(to right, #F00, #fff)
}
</style>