<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="2" lg="3" md="4" sm="6">
        <v-autocomplete v-model="queryForm.postlineid" :items="postlines" label="线路"
          :menu-props="{ bottom: true, offsetY: true }" dense outlined no-data-text="无" @change="getdata">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="4" sm="6">
        <v-autocomplete v-model="queryForm.day_type"
          :items="[{ text: '流水号日期', value: 'serial_t' }, { text: '封车日期', value: 'block_t' }]" label="查询方式"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined @change="getdata">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="4" sm="6">
        <v-menu ref="start_menu" v-model="start_menu" :close-on-content-click="false"
          :return-value.sync="queryForm.start_day" transition="scale-transition" min-width="auto" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="queryForm.start_day" label="开始日期" append-icon="mdi-calendar" readonly v-bind="attrs"
              v-on="on" dense outlined>
            </v-text-field>
          </template>
          <v-date-picker v-model="queryForm.start_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
            @input="() => { $refs.start_menu.save(queryForm.start_day); start_menu = false; getdata(); }"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="4" sm="6">
        <v-menu ref="end_menu" v-model="end_menu" :close-on-content-click="false" :return-value.sync="queryForm.end_day"
          transition="scale-transition" min-width="auto" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="queryForm.end_day" label="结束日期" append-icon="mdi-calendar" readonly v-bind="attrs"
              v-on="on" dense outlined>
            </v-text-field>
          </template>
          <v-date-picker v-model="queryForm.end_day" no-title value="YYYY-mm-dd" scrollable locale="zh-cn"
            @input="() => { $refs.end_menu.save(queryForm.end_day); end_menu = false; getdata(); }"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="4" sm="6">
        <v-btn @click="getdata" color="cyan" dark tile>
          <v-icon small>mdi-magnify</v-icon>查询
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="2" lg="3" md="4" sm="4">
        <v-card tile>
          <a-table size="small" :columns="headers" :data-source="list" row-key="_id" :pagination="false"
            :locale="{ emptyText: '暂无数据' }" :scroll="{ x: width, y: height }">
            <template slot="freight" slot-scope="text, record">
              <p class="error--text font-weight-bold">{{ record.freight }}</p>
            </template>
          </a-table>
        </v-card>
      </v-col>
      <v-col cols="12" xl="10" lg="9" md="8" sm="8">
        <apexchart width="100%" :options="options" :series="series"></apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _ from "underscore";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
  name: "FinanceSelfBillChart",
  data() {
    return {
      queryForm: {
        postlineid: "",
        day_type: "serial_t",
        start_day: this.$moment().subtract(1, 'month').format("YYYY-MM-DD"),
        end_day: this.$moment().format("YYYY-MM-DD"),
      },
      start_menu: false,
      end_menu: false,
      postlines: [],
      list: [],
      height: 600
    };
  },
  computed: {
    headers() {
      let headers = [
        { title: this.queryForm.day_type === 'serial_t' ? "流水号日期" : "封车日期", dataIndex: "start_day", scopedSlots: { customRender: "start_day" }, width: 120, align: "center", },
        { title: "调车价格", dataIndex: "freight", scopedSlots: { customRender: "freight" }, width: 120, align: "center", },
      ];
      return headers;
    },
    options() {
      return {
        chart: {
          type: "line",
          zoom: { enabled: false }
        },
        xaxis: { categories: _.pluck(this.list, "start_day").reverse() }
      };
    },
    series() {
      return [
        {
          name: '调车费',
          data: _.pluck(this.list, "freight").reverse()
        }
      ];
    },
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {

  },
  mounted() {
    this.height = window.innerHeight - 380;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 380;
    };
  },
  activated() {
    this.list = [];
    this.queryForm = {
      postlineid: "",
      day_type: "serial_t",
      start_day: this.$moment().subtract(1, 'month').format("YYYY-MM-DD"),
      end_day: this.$moment().format("YYYY-MM-DD"),
    };
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/selfbillchart/list", { ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = this.makelist(res.data.list);
          this.postlines = res.data.postlines;
          if (!this.queryForm.postlineid) {
            this.queryForm.postlineid = res.data.postlines[0].value;
          }
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    makelist(list) {
      let result = {}, key = this.queryForm.day_type === 'serial_t' ? 'serial_day' : 'block_day';
      for (let i = 0; i < list.length; i++) {
        let { real_freight2_947 } = list[i];
        let k = list[i][key];
        if (!result[k]) result[k] = { [key]: k, t: list[i][this.queryForm.day_type], freight: this.$utils.d(), count: 0 };
        result[k].count++;
        result[k].freight = result[k].freight.plus(real_freight2_947 || 0);
      }
      result = _.filter(_.map(_.values(result).sort((a, b) => b.t - a.t), n => {
        return {
          start_day: n[key],
          freight: Number(n.freight.dividedBy(n.count || 1).toFixed(2)),
        };
      }), n => Number(n.freight) > 0);
      console.log(result);
      return result;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .row+.row {
  margin-top: 0;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}

p {
  margin: 0;
}
</style>
