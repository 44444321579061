<template>
  <v-container fluid>

  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "VehicleMonit",
  data() {
    return {

    };
  },
  computed: {
    ...mapState(["role", "username"]),
  },
  components: {  },
  watch: {

  },
  activated() {

  },
  methods: {

  },
};
</script>
<style lang="less" scoped>

</style>
