<template>
  <v-container fluid>
    <a-space>
      <v-btn color="primary" tile class="mb-2" @click="showadd">
        <v-icon small>mdi-plus</v-icon>新增账号
      </v-btn>
      <v-btn color="primary" tile outlined class="ml-2 mb-2" @click="getdata">
        <v-icon small>mdi-reload</v-icon>刷新
      </v-btn>
      <v-checkbox v-if="username === 'root'" v-model="showdisabled" label="显示已禁用账号" hide-details="auto" dense
        @change="getdata">
      </v-checkbox>
    </a-space>
    <v-card>
      <v-data-table :headers="headers" :items="list" :items-per-page="limit" no-data-text="无数据" hide-default-footer
        disable-sort>
        <template v-slot:item.username="{ item }">
          {{ item.username }}
          <v-chip label small color="green" dark v-if="item.current">当前</v-chip>
        </template>
        <template v-slot:item.role="{ item }">
          <v-chip label small :color="rolecolors[item.role]" dark>
            {{ rolenames[item.role] }}
          </v-chip>
        </template>
        <template v-slot:item.enabled="{ item }">
          <span v-if="item.enabled === 'YES'" class="success--text">启用</span>
          <span v-if="item.enabled === 'NO'" class="error--text">禁用</span>
        </template>
        <template v-slot:item.time="{ item }">
          <p class="success--text">{{ item.last_login_time }}</p>
          <p class="error--text">{{ item.last_active_time }}</p>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn text color="success" @click="showedit(item)"
            v-if="(role === 'root' && item.role !== 'root') || (role === 'admin' && item.role !== 'admin')">
            编辑
          </v-btn>
        </template>
      </v-data-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="model" persistent max-width="400px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            {{ modeltitle }}
            <v-icon @click="cancelsave">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="accountinfo.username" :rules="[vrules.required]" placeholder="输入登录账号"
                    :disabled="accountinfo._id !== ''" persistent-placeholder dense outlined>
                    <template v-slot:label>
                      账号 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <v-text-field v-model="accountinfo.password" label="密码" placeholder="输入登录密码" persistent-placeholder
                    :rules="accountinfo._id === '' ? [vrules.required] : []" dense outlined>
                    <template v-slot:label>
                      密码 <span v-if="accountinfo._id === ''" class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <v-text-field v-model="accountinfo.name" :rules="[vrules.required]" placeholder="输入姓名"
                    persistent-placeholder dense outlined>
                    <template v-slot:label>
                      姓名 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <v-text-field v-model="accountinfo.mobile" :rules="[vrules.required]" placeholder="输入手机号"
                    persistent-placeholder dense outlined>
                    <template v-slot:label>
                      手机号 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <!-- <v-text-field v-model="accountinfo.email" label="邮箱" placeholder="输入邮箱地址" persistent-placeholder dense
                    outlined>
                  </v-text-field> -->
                  <v-autocomplete :items="role === 'root' ? roles2 : roles" v-model="accountinfo.role"
                    :rules="[vrules.required]" :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense
                    outlined>
                    <template v-slot:label>
                      角色 <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                  <v-checkbox v-model="accountinfo.enabled" :true-value="'YES'" :false-value="'NO'" dense>
                    <template v-slot:label>
                      <span :class="`${accountinfo.enabled === 'YES' ? 'success--text' : 'error--text'}`">
                        {{ accountinfo.enabled === 'YES' ? '启用' : '禁用' }}
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" tile :disabled="!valid" @click="saveaccount">确定</v-btn>
            <v-btn tile @click="cancelsave">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "SystemAccount",
  data() {
    return {
      valid: true,
      showdisabled: false,
      headers: [
        { text: "账号", value: "username", align: 'center' },
        { text: "姓名", value: "name", align: "center" },
        { text: "手机号", value: "mobile", align: "center" },
        // { text: "邮箱", value: "email", align: "center" },
        { text: "角色", value: "role", align: "center" },
        { text: "状态", value: "enabled", align: "center" },
        { text: "登录/活跃", value: "time", align: "center" },
        { text: "操作", value: "action", width: 180 },
      ],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      model: false,
      modeltitle: "",
      vrules: {
        required: (value) => !!value || "不能为空",
        username: (value) => /[a-zA-Z0-9]{6,12}/.test(value) || "账号只能包含字母数字，长度6-12位",
        password: (value) => /^(?![0-9]+$)(?![a-zA-Z]+$)(?![_]+$)[0-9A-Za-z_]{8,16}$/.test(value) || "密码为8~16位数字、字母或下划线其中两项组合",
      },
      accountinfo_template: {
        _id: "",
        username: "",
        password: "",
        name: "",
        mobile: "",
        email: "",
        role: "",
        enabled: "YES",
      },
      accountinfo: {
        _id: "",
        username: "",
        password: "",
        name: "",
        mobile: "",
        email: "",
        role: "",
        enabled: "YES",
      },
      model2: false,
      verifycode: "",
    };
  },
  computed: {
    ...mapState(["username", "role", "userid", "roles", "roles2", "rolecolors", "rolenames"]),
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  activated() {
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/accounts", { page: this.page, limit: this.limit, showdisabled: this.showdisabled, }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = res.data.list;
          this.length = res.data.length;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showadd() {
      this.accountinfo = _.extend({}, { ...this.accountinfo_template });
      this.modeltitle = "新增账号";
      this.model = true;
    },
    showedit(item) {
      this.accountinfo = _.pick(item, _.keys(this.accountinfo_template));
      this.modeltitle = "编辑账号";
      this.model = true;
    },
    saveaccount() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/saveaccount", { accountinfo: this.accountinfo }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.model = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelsave() {
      this.$refs.form.resetValidation();
      this.model = false;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}

p {
  margin: 0;
}
</style>
