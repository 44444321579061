<template>
  <v-container fluid>
    <a-form-model layout="vertical" :hideRequiredMark="true">
      <a-row :gutter="16">
        <a-col :lg="4" :md="8">
          <a-textarea v-model="queryForm.nos" placeholder="派车单号或流水号" :auto-size="{ minRows: 6, maxRows: 6 }" />
        </a-col>
        <a-col :lg="20" :md="16">
          <a-row :gutter="16">
            <a-col :xl="3" :lg="6" :md="12">
              <a-form-model-item label="查询方式">
                <a-select v-model="queryForm.timetype" placeholder="查询方式" :not-found-content="null">
                  <a-select-option key="block_t"> 封车日期 </a-select-option>
                  <a-select-option key="serial_t"> 流水号日期 </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xl="6" :lg="6" :md="12">
              <a-form-model-item label="时间">
                <a-range-picker v-model="queryForm.times" :locale="locale" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                  :allow-clear="false" />
              </a-form-model-item>
            </a-col>
            <a-col :xl="3" :lg="6" :md="12">
              <a-form-model-item label="省份">
                <a-select v-model="queryForm.province" :options="provinces" placeholder="省份" :not-found-content="null"
                  allow-clear>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xl="4" :lg="6" :md="12">
              <a-form-model-item label="邮路">
                <a-input v-model="queryForm.postroute" placeholder="邮路" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xl="4" :lg="6" :md="12">
              <a-form-model-item label="邮路级别">
                <a-select v-model="queryForm.postroutelevel" placeholder="一干/二干" :not-found-content="null" allow-clear>
                  <a-select-option key="一级"> 一干 </a-select-option>
                  <a-select-option key="二级"> 二干 </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xl="4" :lg="6" :md="12">
              <a-form-model-item label="性质">
                <a-select v-model="queryForm.transport_kind" placeholder="单程/往返" :not-found-content="null" allow-clear>
                  <a-select-option key="单程"> 单程 </a-select-option>
                  <a-select-option key="往返"> 往返 </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xl="3" :lg="6" :md="12">
              <a-form-model-item label="车牌号">
                <a-input v-model="queryForm.vehicle_number" placeholder="车牌号" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xl="3" :lg="6" :md="12">
              <a-form-model-item label="派押局">
                <a-input v-model="queryForm.dispatch_post_office" placeholder="派押局" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xl="4" :lg="6" :md="12">
              <a-form-model-item label="派车响应单位">
                <a-input v-model="queryForm.dispatch_response_corp" placeholder="派车响应单位" allow-clear>
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xl="3" :lg="3" :md="6">
              <a-form-model-item label="车型">
                <a-select v-model="queryForm.vehicle_type" placeholder="车型" :not-found-content="null" allow-clear>
                  <a-select-option v-for="item in vehicle_types" :key="item.value"> {{ item.label }} </a-select-option>
                  <a-select-option key="未匹配"> 未匹配 </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xl="3" :lg="3" :md="6">
              <a-form-model-item label="所属公司">
                <a-select v-model="queryForm.corp" :options="corps" placeholder="所属公司" :not-found-content="null"
                  allow-clear>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xl="6" :lg="3" :md="24">
              <div class="d-flex align-end" style="margin-top:15px;height:42px;">
                <a-space>
                  <v-btn tile small class="primary white--text" @click="getdata">
                    <v-icon small>mdi-magnify</v-icon>查询
                  </v-btn>
                  <v-btn tile small class="purple white--text" @click="downloadwaybill">
                    <v-icon small>mdi-arrow-down-circle-outline</v-icon> 下载路单
                  </v-btn>
                  <!-- <v-btn tile small class="cyan white--text" @click="downloadwaybill2">
                    <v-icon small>mdi-arrow-down-circle-outline</v-icon> 下载路单(带ID)
                  </v-btn> -->
                </a-space>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-form-model>
    <v-card tile class="mt-2">
      <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: width, y: height }" row-key="_id">
        <template slot="title">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center pl-2">
              <span>共 {{ length }} 页 / {{ count }} 条数据</span>
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                    {{ limit }}/页
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                    <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
          </div>
        </template>
        <template slot="customA">
          <p>进场日期</p>
          <p>流水号日期</p>
        </template>
        <template slot="customA1">
          <p>班次</p>
          <p>组开局</p>
          <p>承运商</p>
        </template>
        <template slot="customB">
          <p>线路</p>
          <p>邮路名称</p>
          <p>派车响应单位</p>
          <p>派押局</p>
        </template>
        <template slot="customC">
          <p>车牌号</p>
          <p>挂厢号</p>
        </template>
        <template slot="customD">
          <p>所属公司</p>
          <p>车型</p>
          <p>容积</p>
        </template>
        <template slot="customE">
          <p>司机姓名</p>
          <p>司机手机号</p>
        </template>
        <template slot="customF">
          <p>封车时间</p>
          <p>解车时间</p>
        </template>
        <template slot="customG">
          <p>出围栏时间</p>
          <p>入围栏时间</p>
        </template>
        <template slot="customH">
          <p>运行时长</p>
          <p>考核时效</p>
        </template>
        <template slot="customI">
          <p>派车单号</p>
          <p>流水号</p>
        </template>
        <template slot="customJ">
          <p>往返属性</p>
          <p>邮路级别</p>
        </template>
        <template slot="customK">
          <p>要求吨位(t)</p>
          <p>实际吨位(t)</p>
          <p>结算吨位(t)</p>
        </template>
        <template slot="customL">
          <p>运输量</p>
          <p>装载率(%)</p>
        </template>
        <template slot="index" slot-scope="text, record,index">
          {{ index + 1 }}
        </template>
        <template slot="A" slot-scope="text, record">
          <p>{{ record.enter_day || '-' }}</p>
          <p>{{ record.serial_day || '-' }}</p>
        </template>
        <template slot="A1" slot-scope="text, record">
          <p>{{ record.shift_order }}</p>
          <p class="success--text">{{ record.postoffice }}</p>
          <p class="error--text">{{ record.carrier }}</p>
        </template>
        <template slot="B" slot-scope="text, record">
          <p>{{ record.postline }}</p>
          <p>{{ record.postroute }}</p>
          <p>{{ record.dispatch_response_corp }}</p>
          <p>{{ record.dispatch_post_office }}</p>
        </template>
        <template slot="C" slot-scope="text, record">
          <p :class="{ 'error--text': record.vehicleblock }">
            {{ record.vehicle_number || '-' }}
          </p>
          <p>{{ record.vehicle_gnumber || '-' }}</p>
        </template>
        <template slot="D" slot-scope="text, record">
          <p>{{ record.corp || '-' }}</p>
          <p>{{ record.vehicle_type || '-' }}</p>
          <p>{{ record.vehicle_volume || '-' }}</p>
        </template>
        <template slot="E" slot-scope="text, record">
          <p>{{ record.driver_name || '-' }}</p>
          <p>{{ record.driver_mobile || '-' }}</p>
        </template>
        <template slot="F" slot-scope="text, record">
          <p>{{ record.block_time || '-' }}</p>
          <p>{{ record.unblock_time || '-' }}</p>
        </template>
        <template slot="G" slot-scope="text, record">
          <p>{{ record.out_time || '-' }}</p>
          <p>{{ record.in_time || '-' }}</p>
        </template>
        <template slot="H" slot-scope="text, record">
          <p>{{ record.duration || '-' }}</p>
          <p>{{ record.check_aging || '-' }}</p>
        </template>
        <template slot="I" slot-scope="text, record">
          <p>{{ record.dispatch_no || '-' }}</p>
          <p>{{ record.serial_no || '-' }}</p>
        </template>
        <template slot="J" slot-scope="text, record">
          <!-- <p>{{ record.postline || '-' }}</p> -->
          <p v-if="record.transport_kind" class="success--text">{{ record.transport_kind }}</p>
          <p v-else class="error--text">未匹配</p>
          <p>{{ record.postroutelevel || '-' }}</p>
        </template>
        <template slot="K" slot-scope="text, record">
          <p>{{ record.request_weight || '-' }}</p>
          <p>{{ record.real_weight || '-' }}</p>
          <p v-if="record.fee_weight" class="success--text">{{ record.fee_weight }}</p>
          <p v-else class="error--text underline">{{ record.request_weight }}</p>
        </template>
        <template slot="L" slot-scope="text, record">
          <p>{{ record.transport_amount || '-' }}</p>
          <p>{{ record.load_factor ? record.load_factor + '%' : '-' }}</p>
        </template>
        <template slot="action" slot-scope="text, record">
          <div class="d-flex flex-column">
            <v-btn v-if="username === 'root' || role === 'admin' || role === 'jiesuan'" text tile color="purple"
              @click="showpostline(record)">
              变更线路和上游合同
            </v-btn>
            <v-btn v-if="username === 'root' || role === 'admin' || role === 'jiesuan'" text tile color="error"
              @click="showcarrier(record)">
              变更承运商合同
            </v-btn>
            <v-btn text tile color="error" @click="remove(record._id)">
              删除
            </v-btn>
            <v-btn v-if="username === 'root'" text tile color="error" @click="ignore(record._id)">
              移除
            </v-btn>
          </div>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="changepostlinemodel" persistent width="750px">
      <v-form ref="changepostlineform" v-model="changepostlinevalid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            变更线路和上游合同
            <v-icon @click="cancelchangepostline">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="changePostLineForm.dispatch_no" label="派车单号" outlined dense
                    disabled></v-text-field>
                  <v-autocomplete v-model="changePostLineForm.postlineid" :items="postlines" :rules="[vrules.required]"
                    label="线路" :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
                    <template v-slot:label>
                      线路 <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete v-model="changePostLineForm.postline_contractid" :items="filter_postline_contracts"
                    :rules="[vrules.required]" label="上游合同" :menu-props="{ bottom: true, offsetY: true }"
                    no-data-text="无" dense outlined>
                    <template v-slot:label>
                      上游合同 <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" :disabled="!changepostlinevalid" @click="changepostline">保存</v-btn>
            <v-btn tile @click="cancelchangepostline">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="changecarriermodel" persistent width="750px">
      <v-form ref="changecarrierform" v-model="changecarriervalid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            变更承运商合同
            <v-icon @click="cancelchangecarrier">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="changeCarrierForm.dispatch_no" label="派车单号" outlined dense
                    disabled></v-text-field>
                  <v-autocomplete v-model="changeCarrierForm.carrier_contractid" :items="carrier_contracts"
                    :rules="[vrules.required]" label="承运商合同" :menu-props="{ bottom: true, offsetY: true }"
                    no-data-text="无" dense outlined>
                    <template v-slot:label>
                      承运商合同 <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" :disabled="!changecarriervalid" @click="changecarrier">保存</v-btn>
            <v-btn tile @click="cancelchangecarrier">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { mapState } from "vuex";
export default {
  name: "OrderList",
  data() {
    return {
      locale,
      pages: [
        { text: '20/页', value: 20 },
        { text: '50/页', value: 50 },
        { text: '100/页', value: 100 },
        { text: '200/页', value: 200 },
        // { text: '500/页', value: 500 },
        // { text: '1000/页', value: 1000 },
        // { text: '1500/页', value: 1500 },
      ],
      provinces: [],
      corps: [],
      vehicle_types: [],
      queryForm: {
        nos: "",
        timetype: 'block_t',
        times: [null, null],
        postline: undefined,
        province: undefined,
        transport_kind: undefined,
        postroute: undefined,
        postroutelevel: undefined,
        vehicle_number: null,
        dispatch_post_office: null,
        dispatch_response_corp: null,
        vehicle_type: undefined,
        corp: undefined,
      },
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      height: 600,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      changeCarrierForm: {},
      carrier_contracts: [],
      changecarriermodel: false,
      changecarriervalid: true,
      changePostLineForm: {},
      postlines: [],
      postline_contracts: [],
      changepostlinemodel: false,
      changepostlinevalid: true,
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
    headers() {
      let headers = [
        { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 60, align: "center" },
        { dataIndex: "A", slots: { title: "customA" }, scopedSlots: { customRender: "A" }, width: 120, align: "center" },
        { dataIndex: "A1", slots: { title: "customA1" }, scopedSlots: { customRender: "A1" }, width: 150, align: "center" },
        { dataIndex: "B", slots: { title: "customB" }, scopedSlots: { customRender: "B" }, width: 180, align: "center" },
        { dataIndex: "C", slots: { title: "customC" }, scopedSlots: { customRender: "C" }, width: 120, align: "center" },
        { dataIndex: "D", slots: { title: "customD" }, scopedSlots: { customRender: "D" }, width: 100, align: "center" },
        { dataIndex: "E", slots: { title: "customE" }, scopedSlots: { customRender: "E" }, width: 150, align: "center" },
        { dataIndex: "F", slots: { title: "customF" }, scopedSlots: { customRender: "F" }, width: 180, align: "center" },
        { dataIndex: "G", slots: { title: "customG" }, scopedSlots: { customRender: "G" }, width: 180, align: "center" },
        { title: "入局扫描时间", dataIndex: "scan_time", scopedSlots: { customRender: "scan_time" }, width: 180, align: "center" },
        { dataIndex: "H", slots: { title: "customH" }, scopedSlots: { customRender: "H" }, width: 100, align: "center" },
        { dataIndex: "I", slots: { title: "customI" }, scopedSlots: { customRender: "I" }, width: 130, align: "center" },
        { dataIndex: "J", slots: { title: "customJ" }, scopedSlots: { customRender: "J" }, width: 120, align: "center" },
        { dataIndex: "K", slots: { title: "customK" }, scopedSlots: { customRender: "K" }, width: 120, align: "center" },
        { dataIndex: "L", slots: { title: "customL" }, scopedSlots: { customRender: "L" }, width: 100, align: "center" },
        { title: "状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 100, align: "center" },
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 100, align: "center" },
      ];
      if (this.username === 'root' || this.role === 'admin' || this.role === 'jiesuan') headers.push(
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 180, align: "center", fixed: 'right' }
      );
      return headers;
    },
    filter_postline_contracts() {
      let postline_contracts = _.where(this.postline_contracts, { postlineid: this.changePostLineForm.postlineid });
      return postline_contracts;
    },
  },
  components: {},
  watch: {
    '$route.query.nos'(nos) {
      if (nos) {
        this.queryForm.nos = (nos || "").split(',').join('\n');
        this.getdata();
      }
    },
    page() {
      this.getdata();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
  },
  mounted() {
    this.height = window.innerHeight - 400;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 400;
    };
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.count = 0;
    this.queryForm = {
      nos: (this.$route.query.nos || "").split(',').join('\n'),
      timetype: 'block_t',
      times: [this.$dateFormat(new Date(), 'YYYY-MM-01'), this.$dateFormat()],
      postline: undefined,
      province: undefined,
      transport_kind: undefined,
      postroute: undefined,
      postroutelevel: undefined,
      vehicle_number: null,
      dispatch_post_office: null,
      dispatch_response_corp: null,
    }
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/waybill/list", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.list = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: this.$dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
              update_time: n.update_time ? this.$dateFormat(n.update_time, 'YYYY-MM-DD HH:mm:ss') : "",
            }
          });
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
          this.provinces = res.data.provinces;
          this.corps = res.data.corps;
          this.vehicle_types = res.data.vehicle_types;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    downloadwaybill() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/waybill/list", { ...this.queryForm, action: 'download' }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    downloadwaybill2() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/waybill/list", { ...this.queryForm, action: 'download2', }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showpostline(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/waybill/showpostline", { _id: item._id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.changePostLineForm = res.data.changePostLineForm;
          this.postlines = res.data.postlines;
          this.postline_contracts = res.data.postline_contracts;
          this.changepostlinemodel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    changepostline() {
      if (!this.$refs.changepostlineform.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/waybill/changepostline", { changePostLineForm: this.changePostLineForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.changepostlinemodel = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelchangepostline() {
      this.$refs.changepostlineform.resetValidation();
      this.changepostlinemodel = false;
    },
    showcarrier(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/waybill/showcarrier", { _id: item._id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.changeCarrierForm = res.data.changeCarrierForm;
          this.carrier_contracts = res.data.carrier_contracts;
          this.changecarriermodel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    changecarrier() {
      if (!this.$refs.changecarrierform.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/waybill/changecarrier", { changeCarrierForm: this.changeCarrierForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.changecarriermodel = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelchangecarrier() {
      this.$refs.changecarrierform.resetValidation();
      this.changecarriermodel = false;
    },
    remove(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/waybill/remove", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    ignore(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/waybill/ignore", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    filterOption(input, option) {
      // console.log('filter-option', input, option);
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: unset;
}

/deep/ .row+.row {
  margin-top: 0;
}

p {
  margin: 0;
}
</style>
