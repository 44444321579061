<template>
  <v-container fluid>
    <a-space>
      <v-btn color="primary" tile @click="showadd">
        <v-icon small>mdi-plus</v-icon>新增承运商
      </v-btn>
      <v-btn color="primary" tile outlined @click="getdata">
        <v-icon small>mdi-reload</v-icon>刷新
      </v-btn>
    </a-space>
    <v-card tile class="mt-2">
      <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: width, y: height }" row-key="_id">
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
        <template slot="code" slot-scope="text, record">
          <a href="javascript:;" @click="showmap(record)"> {{ record.code }} </a>
        </template>
        <template slot="action" slot-scope="text, record">
          <v-btn text color="success" @click="showedit(record)"> 编辑 </v-btn>
          <a-popconfirm title="删除" ok-text="确定" cancel-text="取消" @confirm="remove(record._id)">
            <v-btn text color="error"> 删除 </v-btn>
          </a-popconfirm>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="model" persistent max-width="580px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            {{ modeltitle }}
            <v-icon @click="cancelsave">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="carrierinfo.name" :rules="[vrules.required]" placeholder="输入名称"
                    persistent-placeholder dense outlined :disabled="carrierinfo._id !== ''">
                    <template v-slot:label>
                      名称 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <v-text-field v-model="carrierinfo.shortname" :rules="[vrules.required]" placeholder="输入简称"
                    persistent-placeholder dense outlined :disabled="carrierinfo._id !== ''">
                    <template v-slot:label>
                      简称 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <v-text-field v-model="carrierinfo.code" :rules="[vrules.required]" placeholder="输入车辆监控代码"
                    persistent-placeholder dense outlined>
                    <template v-slot:label>
                      车辆监控代码 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <v-text-field v-model="carrierinfo.account_period_days" type="number" label="账期(天)" dense outlined>
                  </v-text-field>
                  <v-text-field v-model="carrierinfo.bankaccountname" label="开户名称" dense outlined>
                  </v-text-field>
                  <v-text-field v-model="carrierinfo.bankaccount" label="账号" dense outlined>
                  </v-text-field>
                  <v-text-field v-model="carrierinfo.bankname" label="开户银行" dense outlined>
                  </v-text-field>
                  <v-text-field v-model="carrierinfo.linkman" label="负责人" dense outlined>
                  </v-text-field>
                  <v-text-field v-model="carrierinfo.linkphone" label="负责人电话" dense outlined>
                  </v-text-field>
                  <v-text-field v-model="carrierinfo.remark" label="备注" dense outlined>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" tile :disabled="!valid" @click="save">确定</v-btn>
            <v-btn tile @click="cancelsave">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "CarrierList",
  data() {
    return {
      valid: true,
      headers: [
        { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 100 },
        { title: "名称", dataIndex: "name", scopedSlots: { customRender: "name" }, width: 300 },
        { title: "简称", dataIndex: "shortname", scopedSlots: { customRender: "shortname" }, width: 150 },
        { title: "车辆监控代码", dataIndex: "code", scopedSlots: { customRender: "code" }, width: 150 },
        { title: "负责人", dataIndex: "linkman", scopedSlots: { customRender: "linkman" }, width: 150 },
        { title: "电话", dataIndex: "linkphone", scopedSlots: { customRender: "linkphone" }, width: 150 },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 220, fixed: 'right' },
      ],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      model: false,
      modeltitle: "",
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      carrierinfo_template: {
        _id: "",
        code: "",
        name: "",
        shortname: "",
        bankaccountname: "",
        bankaccount: "",
        bankname: "",
        linkman: "",
        linkphone: "",
        account_period_days: "",
        remark: ""
      },
      carrierinfo: {},
      height: 600,
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  mounted() {
    this.height = window.innerHeight - 200;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 200;
    };
  },
  activated() {
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.count = 0;
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/carrier/list", { page: this.page, limit: this.limit, }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showmap(item) {
      this.$downloadBrowser({ href: `https://map.hnlidi.cn/xiayou/${item.code}` });
    },
    showadd() {
      this.carrierinfo = _.extend({}, { ...this.carrierinfo_template });
      this.modeltitle = "新增承运商";
      this.model = true;
    },
    showedit(item) {
      this.carrierinfo = _.pick(item, _.keys(this.carrierinfo_template));
      this.modeltitle = "编辑承运商";
      this.model = true;
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/carrier/save", { carrierinfo: this.carrierinfo }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.model = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelsave() {
      this.$refs.form.resetValidation();
      this.model = false;
    },
    remove(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/carrier/remove", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
