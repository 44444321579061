import * as types from './types'
import _ from 'underscore'

const mutations = {
  [types.SET_USERID]: (state, data) => {
    state.userid = data
  },
  [types.SET_USERNAME]: (state, data) => {
    state.username = data
  },
  [types.SET_NAME]: (state, data) => {
    state.name = data
  },
  [types.SET_ROLE]: (state, data) => {
    state.role = data
  },
  [types.SET_NAV_TITLE]: (state, data) => {
    state.navTitle = data
  },
  [types.SET_NAV_LOGO]: (state, data) => {
    state.navLogo = data
  },
  [types.OPEN_TAB]: (state, data) => {
    let navTabs = [...state.navTabs];
    if (
      !_.find(
        state.navTabs, n =>
        checkpaths(data.path, n.path, ['/order/detail',])
        || (data.path === n.path)
      )
    ) {
      let pushed = false;
      for (let i = 0; i < state.initmenus.length; i++) {
        let { name, path, children = [] } = state.initmenus[i];
        if (path === data.path) {
          navTabs.push({ name, path });
          pushed = true;
          break;
        }
        let stop = false;
        for (let j = 0; j < children.length; j++) {
          if (children[j].path === data.path) {
            navTabs.push(_.pick(children[j], ["name", "path"]));
            pushed = true;
            stop = true;
            break;
          }
        }
        if (stop) break;
      }
      if (!pushed) navTabs.push(data);
    } else {
      for (let i = 0; i < navTabs.length; i++) {
        if (navTabs[i].path === data.path && checkpath(data.path, ['/order/detail',])) {
          navTabs[i] = data;
          break;
        }
      }
    }
    for (let i = 0; i < navTabs.length; i++) {
      if (
        checkpaths(data.path, navTabs[i].path, ['/order/detail',])
        || data.path === navTabs[i].path
      ) {
        navTabs[i].path = data.path;
        state.navTabIndex = i;
        break;
      }
    }
    state.navTabs = navTabs;
  },
  [types.CLOSE_TAB]: (state, data) => {
    let navTabs = [...state.navTabs];
    let removed = false, removeindex = -1;
    for (let i = 0; i < navTabs.length; i++) {
      if (
        checkpaths(data, navTabs[i].path, ['/order/detail',])
        || data === navTabs[i].path
      ) {
        navTabs.splice(i, 1);
        removeindex = i;
        removed = true;
        break;
      }
    }
    if (removed) {
      state.navTabs = navTabs;
      if (removeindex <= state.navTabIndex) {
        if (removeindex === state.navTabIndex) {
          if (removeindex === 0) {
            if (navTabs.length === 0) {
              window._doRouterReplace("/workbench");
            } else {
              window._doRouterReplace(navTabs[removeindex].path);
            }
          } else if (removeindex >= 1) {
            window._doRouterReplace(navTabs[removeindex - 1].path)
            state.navTabIndex -= 1;
          }
        } else {
          window._doRouterReplace(navTabs[state.navTabIndex - 1].path)
          state.navTabIndex -= 1;
        }
      }
    }
  },
  [types.CLEAR_TABS]: (state, data) => {
    state.navTabs = [];
    state.navTabIndex = -1;
  },
  [types.SET_UPLOADTASK_MODEL]: (state, data) => {
    state.uploadtask_model = data;
  },
  [types.SET_UPLOADTASK_TITLE]: (state, data) => {
    state.uploadtask_title = data;
  },
  [types.SET_UPLOADTASK_TYPE]: (state, data) => {
    state.uploadtask_type = data;
  },
}
export default mutations

function checkpath(path1, paths) {
  for (let i = 0; i < paths.length; i++) {
    if (path1.indexOf(paths[i]) === 0) return true;
  }
  return false
}
function checkpaths(path1, path2, paths) {
  for (let i = 0; i < paths.length; i++) {
    if (path1.indexOf(paths[i]) === 0 && path2.indexOf(paths[i]) === 0) return true;
  }
  return false
}