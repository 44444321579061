import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import BasicLayout from './layouts/BasicLayout'
import EmptyLayout from './layouts/EmptyLayout'
Vue.component('basic-layout', BasicLayout);
Vue.component('empty-layout', EmptyLayout);
import api from './request'
Vue.prototype.$api = api;
import utils from './utils';
Vue.prototype.$utils = utils;
Vue.prototype.$d = utils.d;
Vue.prototype.$dsp = utils.dsp;
Vue.prototype.$isDecimal = utils.isDecimal;
Vue.prototype.$alert = utils.alert;
Vue.prototype.$formatMoney = utils.formatMoney;
Vue.prototype.$dateTime = utils.dateTime;
Vue.prototype.$dateFormat = utils.dateTime;
Vue.prototype.$download = utils.download;
Vue.prototype.$downloadBrowser = utils.downloadBrowser;
Vue.prototype.$downloadZip = utils.downloadZip;
Vue.prototype.$uuid = utils.makeUUID;
Vue.prototype.$moment = utils.moment;
import async from 'async';
Vue.prototype.$async = async;
import axios from 'axios'
Vue.prototype.$axios = axios;

Vue.filter('dateFormat', function (date, pattern = "YYYY-MM-DD") {
  return utils.dateTime(date, pattern);
});
Vue.filter('dateTime', function (date, pattern = "YYYY-MM-DD") {
  return utils.dateTime(date, pattern);
});
Vue.filter('fromNow', function (date) {
  return utils.fromNow(date);
});

import { Dialog, Empty, Toast, Lazyload, } from "vant";
Vue.use(Empty);
Vue.use(Dialog);
Toast.setDefaultOptions('loading', { duration: 0, forbidClick: true, loadingType: "spinner" });
Vue.use(Toast);
Vue.use(Lazyload, { lazyComponent: true });

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
import InputTag from 'vue-input-tag';
Vue.component('input-tag', InputTag);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
