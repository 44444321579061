<template>
  <v-container fluid>
    <a-table size="small" :columns="headers" bordered :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
      :scroll="{ y: 600 }">
      <template slot="data" slot-scope="text, record">
        {{ record.data }}
        <v-btn v-if="record.type === 0" text tile color="success" @click="updatesetting(record)">升级</v-btn>
        <template v-if="record.type === 1">
          <v-btn v-if="record.data === 1" text tile color="error" @click="updatesetting(record)">关闭</v-btn>
          <v-btn v-if="record.data === 0" text tile color="success" @click="updatesetting(record)">开启</v-btn>
        </template>
        <template v-if="record.type === 2 || record.type === 3">
          <v-btn text tile color="error" @click="editarray(record)">编辑</v-btn>
        </template>
      </template>
    </a-table>
    <v-dialog v-model="arrayModel" persistent max-width="400px">
      <v-card>
        <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
          {{ arrayForm.name }}
          <v-icon @click="arrayModel = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="arrayForm.data" :label="arrayForm.name" placeholder="每行一个" persistent-placeholder
                  outlined rows="10" dense>
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" tile @click="savearray">确定</v-btn>
          <v-btn tile @click="arrayModel = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "SystemSetting",
  data: () => ({
    headers: [
      { title: "KEY", dataIndex: "_id", scopedSlots: { customRender: "_id" }, width: 100, },
      { title: "NAME", dataIndex: "name", scopedSlots: { customRender: "name" }, width: 100, },
      { title: "VALUE", dataIndex: "data", scopedSlots: { customRender: "data" }, width: 100, },
    ],
    list: [],
    arrayModel: false,
    arrayForm: {},
  }),
  components: {},
  computed: {
    ...mapState(["allroles", "role"]),
  },
  watch: {},
  activated() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/system/settinglist").then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = res.data.list;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    updatesetting(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/system/updatesetting", item).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    editarray(item) {
      let data = "";
      if (item.type === 2) data = item.data.join('\n');
      if (item.type === 3) data = _.map(item.data, n => JSON.stringify(n)).join('\n');
      this.arrayForm = {
        _id: item._id,
        name: item.name,
        data
      };
      this.arrayModel = true;
    },
    savearray() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/system/updatesetting", this.arrayForm).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.arrayModel = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
