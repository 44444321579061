<template>
  <div>
    <v-btn v-if="btn_size === 'small'" small color="success" tile @click="showupload">
      <v-icon small>mdi-upload</v-icon> {{ btn_text }}
    </v-btn>
    <v-btn v-else color="success" tile @click="showupload">
      <v-icon small>mdi-upload</v-icon> {{ btn_text }}
    </v-btn>
    <v-dialog v-model="model" persistent max-width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 text-subtitle-1 font-weight-bold d-flex justify-space-between">
          {{ title }}
          <v-icon @click="onClose">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <a-alert v-if="alert_text" class="mt-2" type="warning" show-icon>
            <template #message>
              <div v-html="alert_text"></div>
            </template>
          </a-alert>
          <v-row class="mb-2 mt-2">
            <v-col cols="12" class="d-flex">
              <v-file-input v-model="file" label="文件" accept=".xls,.xlsx" prepend-icon="mdi-microsoft-excel" show-size
                outlined hide-details dense>
                <template v-slot:selection="{ text }">
                  <v-chip label small color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
              <v-btn class="ml-2" color="primary" text tile :disabled="!file" @click="doupload"> 上传
              </v-btn>
            </v-col>
          </v-row>
          <a-table size="small" :columns="headers" bordered :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
            :scroll="{ y: 400 }">
            <template slot="index" slot-scope="text, record, index">
              {{ index + 1 }}
            </template>
            <template slot="originalname" slot-scope="text, record">
              <a href="javascript:;" @click="downloadfile(record)">{{ record.originalname }}</a>
            </template>
            <template slot="create_time" slot-scope="text, record">
              {{ record.create_time | dateTime("YYYY-MM-DD HH:mm") }}
            </template>
            <template slot="state" slot-scope="text, record">
              <span v-if="record.state === 0" class="primary--text">
                正在处理
              </span>
              <span v-else-if="record.state === -1" class="error--text underline click">
                处理失败：{{ record.reason }}
              </span>
              <span v-else-if="record.success === 0" class="error--text underline click">
                处理失败
              </span>
              <span v-else-if="record.state === 1" class="success--text">
                处理成功
              </span>
            </template>
            <template slot="count" slot-scope="text, record">
              <div>
                <span class="primary--text">{{ record.count }}</span>/
                <span class="success--text underline click" @click="showsuccess(record._id)">
                  {{ record.success }}
                </span>/
                <span class="error--text underline click" @click="showfail(record._id)">
                  {{ record.fail }}
                </span>
              </div>
            </template>
          </a-table>
          <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
        </v-card-text>
        <v-card-actions>
          <a v-if="template_file" href="javascript:;" @click="downloadtemplate"> {{ template_file.name }} </a>
          <v-spacer></v-spacer>
          <v-btn color="primary" tile text @click="getdata">刷新</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog></v-dialog>
  </div>
</template>
<script>
export default {
  name: "uploadtask",
  props: ["btnSize", "btnText", "modelTitle", "alertText", "uploadType", "templateFile"],
  data() {
    return {
      btn_size: "",
      btn_text: "",
      model: false,
      title: "",
      alert_text: "",
      type: "",
      template_file: null,
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      headers: [
        { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 80, align: "center" },
        { title: "文件", dataIndex: "originalname", scopedSlots: { customRender: "originalname" }, width: 120, align: "center" },
        { title: "上传时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 160, align: "center" },
        { title: "上传人", dataIndex: "create_by", scopedSlots: { customRender: "create_by" }, width: 100, align: "center" },
        { title: "状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 100, align: "center" },
        { title: "总数/成功/失败", dataIndex: "count", scopedSlots: { customRender: "count" }, width: 150, align: "center" },
      ],
      file: null,
    };
  },
  computed: {
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  watch: {
    btnText(btn_text) {
      this.btn_text = btn_text;
    },
    btnSize(btn_size) {
      this.btn_size = btn_size;
    },
    modelTitle(title) {
      this.title = title;
    },
    alertText(alert_text) {
      this.alert_text = alert_text;
    },
    uploadType(type) {
      this.type = type;
    },
    templateFile(file) {
      this.template_file = file;
    },
    page() {
      this.getdata();
    },
  },
  activated() {
    this.btn_size = this.btnSize;
    this.btn_text = this.btnText;
    this.title = this.modelTitle;
    this.alert_text = this.alertText;
    this.type = this.uploadType;
    this.template_file = this.templateFile;
  },
  methods: {
    reset() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/uploadtask/list", { page: this.page, limit: this.limit, type: this.type }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    checkfile(file) {
      if (!file) {
        this.$alert("文件不能为空");
        return { code: 1 };
      }
      const isLt10M = file.size / 1024 / 1024 < 2
      if (!isLt10M) {
        this.$alert("文件不能超过2MB");
        return { code: 1 };
      }
      return { code: 0 };
    },
    async doupload() {
      let checkresult = this.checkfile(this.file);
      if (checkresult.code !== 0) return;
      this.$toast.loading({ message: "" });
      let file = this.file;
      const slicefiles = this.$utils.sliceFile(file.size);
      const faid = this.$utils.uuid();
      for (let j = 0; j < slicefiles.length; j++) {
        let slicefile = slicefiles[j];
        const formData = new FormData();
        if (slicefile.chunks > 1) {
          let _file = file.slice(slicefile.from, slicefile.to);
          formData.set("file", _file);
        } else {
          formData.set("file", file);
        }
        formData.set("faid", faid);
        formData.set("chunk", slicefile.chunk);
        formData.set("chunks", slicefile.chunks);
        formData.set("originalname", file.name);
        formData.set("type", this.type);
        try {
          let res = await this.$api.doUpload("/uploadtask/plupload", formData);
          if (res.code === 0) {
            if (slicefile.chunk + 1 === slicefile.chunks) {
              this.$toast.clear();
              this.getdata();
            }
          } else {
            this.$toast.clear();
            this.$alert(res.msg);
            break;
          }
        } catch (e) {
          console.error(e);
          this.$toast.clear();
          this.$alert(e.message);
          break;
        }
      }
      this.file = null;
    },
    showsuccess(_id) {
      this.showresult(_id, 'success');
    },
    showfail(_id) {
      this.showresult(_id, 'fail');
    },
    showresult(_id, type) {

    },
    downloadfile(item) {
      this.$download(item.remoteurl, item.originalname);
    },
    downloadtemplate() {
      this.$download(this.template_file.url, this.template_file.name);
    },
    showupload() {
      this.model = true;
      this.reset();
    },
    onClose() {
      this.model = false
    },
  },
};
</script>
<style lang="less" scoped></style>
