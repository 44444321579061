<template>
  <v-app>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark style>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-avatar size="36px" v-if="navLogo">
        <img style="background: #fff" :src="navLogo" />
      </v-avatar>
      <v-toolbar-title style="width: 200px">
        <span class="hidden-sm-and-down"> {{ navTitle }} </span>
      </v-toolbar-title>
      <v-spacer />
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-avatar v-if="avatar" size="36px" item>
              <v-img :src="avatar" alt="Vuetify" />
            </v-avatar>
            <v-avatar v-else-if="name" size="36" color="primary">
              <span class="white--text headline"> {{ name.substr(0, 1) }} </span>
            </v-avatar>
            <v-avatar v-else-if="username" size="36" color="primary">
              <span class="white--text headline"> {{ username.substr(0, 1) }} </span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img v-if="avatar" :src="avatar" />
                <v-avatar v-else-if="name" size="36" color="primary">
                  <span class="white--text headline"> {{ name.substr(0, 1) }} </span>
                </v-avatar>
                <v-avatar v-else-if="username" size="36" color="primary">
                  <span class="white--text headline"> {{ username.substr(0, 1) }} </span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ name || username }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="logout">
                  <v-icon> mdi-logout-variant </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item v-for="(item, index) in lists" :key="index" @click="goto(path, item.path)">
              <v-list-item-action>
                <v-icon> {{ item.icon }} </v-icon>
              </v-list-item-action>
              <v-list-item-title> {{ item.text }} </v-list-item-title>
            </v-list-item>
          </v-list>
          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" tile text @click="modifypwd">修改密码</v-btn>
          </v-card-actions> -->
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense nav expand>
        <template v-for="item in getmenus(path)">
          <v-list-group v-if="item.children" :key="item.key" :color="item.active ? 'primary' : ''" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> {{ item.name }} </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:prependIcon>
              <v-list-item-content>
                <template v-if="item.icon1">
                  <v-img v-if="item.active" :src="item.icon1" width="24" height="24" />
                  <v-img v-else :src="item.icon0" width="24" height="24" />
                </template>
                <v-icon v-else> {{ item.icon }} </v-icon>
              </v-list-item-content>
            </template>
            <v-list-item class="m-item" v-for="child in item.children" :key="child.key" :link="!!child.path"
              color="primary" :input-value="child.active" @click="goto(path, child.path)" dense
              @contextmenu="(e) => showmenu(e, path, child.path)">
              <v-list-item-content>
                <v-list-item-title> {{ child.name }} </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <template v-if="child.icon1">
                  <v-img v-if="child.active" :src="child.icon1" width="24" height="24" />
                  <v-img v-else :src="child.icon0" width="24" height="24" />
                </template>
                <v-icon v-else> {{ child.icon }} </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
          <template v-else>
            <v-list-item v-if="item.type === 'spacer'" :key="item.key" dense>
              <v-list-item-action>
                <v-btn outlined tile depressed color="primary">
                  <span class="white--text title"> {{ item.name }} </span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-else-if="item.path" :key="item.key" :link="!!item.path" :input-value="item.active"
              color="primary" @click="goto(path, item.path)" dense @contextmenu="(e) => showmenu(e, path, item.path)">
              <v-list-item-action>
                <template v-if="item.icon1">
                  <v-img v-if="item.active" :src="item.icon1" width="24" height="24" />
                  <v-img v-else :src="item.icon0" width="24" height="24" />
                </template>
                <v-icon v-else> {{ item.icon }} </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> {{ item.name }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
      <v-list>
        <v-list-item @click="goto2(p, p2)">
          <v-list-item-title>新标签页打开</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-main id="overflow_box" style="padding-bottom: 15px">
      <keep-alive>
        <router-view> </router-view>
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import _ from "underscore";
import { mapState, mapGetters } from "vuex";
export default {
  name: "BasicLayout",
  data() {
    return {
      drawer: null,
      path: this.$route.path,
      lists: [
        {
          icon: "mdi-account",
          text: "账户设置",
          path: "/user/setting",
        },
      ],
      showMenu: false,
      x: 0,
      y: 0,
      p: '',
      p2: '',
    };
  },
  computed: {
    ...mapState(["avatar", "userid", "username", "name", "role", "navTitle", "navLogo", "navTabs", "navTabIndex",]),
    ...mapGetters(["getmenus"]),
  },
  created() {
    // console.log("BasicLayout-created");
    this.init();
  },
  activated() {
    // console.log("BasicLayout-activated");
  },
  mounted() {
    // console.log("BasicLayout-mounted");
  },
  watch: {
    $route(newVal) {
      this.path = newVal.path;
    },
  },
  methods: {
    init() {
      const userid = localStorage.getItem("lidi.userid");
      const username = localStorage.getItem("lidi.username");
      const name = localStorage.getItem("lidi.name");
      const role = localStorage.getItem("lidi.role");
      this.$store.dispatch("SetUserid", userid);
      this.$store.dispatch("SetUsername", username);
      this.$store.dispatch("SetName", name);
      this.$store.dispatch("SetRole", role);
    },
    goto(path, cpath) {
      if (typeof cpath === "string" && path !== cpath) {
        this.$router.push({
          path: cpath,
          query: this.$route.meta.qs ? _.pick(this.$route.query, this.$route.meta.qskeys || ["id"]) : {},
        }).catch(console.error);
      }
    },
    goto2(path, cpath) {
      const routeData = this.$router.resolve({
        path: cpath,
        query: this.$route.meta.qs ? _.pick(this.$route.query, this.$route.meta.qskeys || ["id"]) : {},
      });
      window.open(routeData.href, '_blank');
    },
    removetab(path) {
      this.$store.dispatch("CloseTab", path);
    },
    logout() {
      localStorage.clear();
      this.$router.replace({ path: "/login" }).catch(console.error);
    },
    showmenu(e, p, p2) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.p = p;
      this.p2 = p2;
      this.$nextTick(() => {
        this.showMenu = true
      });
    },
  },
};
</script>
<style lang="less" scoped>
.m-item {
  padding-left: 70px;
}

#overflow_box {
  height: 100vh;
  overflow: scroll;
}

#overflow_box::-webkit-scrollbar {
  display: none;
}
/deep/ .v-list-group__header.v-list-item--active{color: #fff; background: #1990FF;}
</style>