<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2" sm="3">
        <v-menu ref="month_menu" v-model="month_menu" :close-on-content-click="false" :return-value.sync="queryForm.month"
          transition="scale-transition" min-width="auto" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="queryForm.month" label="选择月份" append-icon="mdi-calendar" readonly v-bind="attrs"
              v-on="on" dense outlined hide-details>
            </v-text-field>
          </template>
          <v-date-picker v-model="queryForm.month" type="month" no-title value="YYYY-mm" scrollable locale="zh-cn"
            @input="() => { $refs.month_menu.save(queryForm.month); month_menu = false; }"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2" sm="3">
        <v-autocomplete v-model="queryForm.province" :items="provinces" label="省份"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="2" sm="3">
        <v-autocomplete v-model="queryForm.postofficeid" :items="postoffices" label="地市邮局"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="2" sm="3">
        <v-autocomplete v-model="queryForm.corp" :items="corps" label="所属公司" :menu-props="{ bottom: true, offsetY: true }"
          no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-text-field v-model="queryForm.dispatch_response_corp" label="派车响应单位" dense outlined clearable hide-details>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <a-space>
          <v-btn @click="getdata" color="cyan" dark tile>
            <v-icon small>mdi-magnify</v-icon>查询
          </v-btn>
          <v-btn @click="download" color="indigo" dark tile>
            <v-icon small>mdi-download</v-icon>下载财务报表
          </v-btn>
          <v-btn tile color="primary" @click="updatebill"> 更新 </v-btn>
        </a-space>
      </v-col>
    </v-row>
    <v-card>
      <a-table size="small" :columns="headers" :data-source="list" row-key="_id" :pagination="false" bordered
        :locale="{ emptyText: '暂无数据' }" :scroll="{ x: width, y: height }">
        <!-- <template slot="title">
          <a-space>
            <template v-if="username === 'root' || role === 'caiwu'">
              <v-btn tile small color="primary" @click="updatebill"> 更新 </v-btn>
              <v-btn v-if="locked" tile color="success" @click="unlockbill"> 解锁 </v-btn>
              <v-btn v-else tile color="error" @click="lockbill"> 锁定 </v-btn>
            </template>
          </a-space>
        </template> -->
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";

export default {
  name: "FinanceReport",
  data() {
    return {
      queryForm: {
        month: this.$moment().format('YYYY-MM'), province: "", postofficeid: "", corp: "", dispatch_response_corp: "",
      },
      month_menu: false,
      provinces: [],
      postoffices: [],
      corps: [],
      headers: [
        { title: "项目", dataIndex: "province", scopedSlots: { customRender: "province" }, width: 100, align: "center" },
        { title: "月份", dataIndex: "month", scopedSlots: { customRender: "month" }, width: 100, align: "center" },
        { title: "地市局", dataIndex: "spostoffice", scopedSlots: { customRender: "spostoffice" }, width: 120, align: "center" },
        { title: "客户名称", dataIndex: "postoffice", scopedSlots: { customRender: "postoffice" }, width: 200, align: "center" },
        { title: "派车响应单位", dataIndex: "dispatch_response_corp", scopedSlots: { customRender: "dispatch_response_corp" }, width: 200, align: "center" },
        { title: "所属公司", dataIndex: "corp", scopedSlots: { customRender: "corp" }, width: 100, align: "center" },
        {
          title: "收入",
          children: [
            // { title: '趟次', dataIndex: 'count1', scopedSlots: { customRender: "count1" }, width: 100, align: "center" },
            { title: '确认收入', dataIndex: 'income', scopedSlots: { customRender: "income" }, width: 150, align: "center" },
            { title: '已开票收入', dataIndex: 'invoice_income', scopedSlots: { customRender: "invoice_income" }, width: 150, align: "center" },
            { title: '未开票金额', dataIndex: 'uninvoice_income', scopedSlots: { customRender: "uninvoice_income" }, width: 150, align: "center" },
            { title: '已回款', dataIndex: 'paid_income', scopedSlots: { customRender: "paid_income" }, width: 120, align: "center" },
          ]
        },
        {
          title: "成本",
          children: [
            // { title: '下游', dataIndex: 'carrier', scopedSlots: { customRender: "carrier" }, width: 200, align: "center" },
            // { title: '趟次', dataIndex: 'count2', scopedSlots: { customRender: "count2" }, width: 100, align: "center" },
            { title: '确认成本', dataIndex: 'cost', scopedSlots: { customRender: "cost" }, width: 150, align: "center" },
            { title: '利滴成本', dataIndex: 'ld_cost', scopedSlots: { customRender: "ld_cost" }, width: 150, align: "center" },
            { title: '已开票成本', dataIndex: 'invoice_cost', scopedSlots: { customRender: "invoice_cost" }, width: 150, align: "center" },
            { title: '未开票金额', dataIndex: 'uninvoice_cost', scopedSlots: { customRender: "uninvoice_cost" }, width: 150, align: "center" },
            { title: '已付款', dataIndex: 'paid_cost', scopedSlots: { customRender: "paid_cost" }, width: 120, align: "center" },
          ]
        },
        // {
        //   title: "油卡",
        //   children: [
        //     { title: '中石油', dataIndex: 'oilcard1', scopedSlots: { customRender: "oilcard1" }, width: 100, align: "center" },
        //     { title: '团油', dataIndex: 'oilcard2', scopedSlots: { customRender: "oilcard2" }, width: 100, align: "center" },
        //   ]
        // },
        { title: "趟次", dataIndex: "count", scopedSlots: { customRender: "count" }, width: 120, align: "center" },
        // { title: "总成本", dataIndex: "cost", scopedSlots: { customRender: "cost" }, width: 120, align: "center" },
        { title: "利润", dataIndex: "profit", scopedSlots: { customRender: "profit" }, width: 120, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: 'right' },
      ],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      locked: false,
      height: 600,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  mounted() {
    this.height = window.innerHeight - 250;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 250;
    };
    this.page = 1;
    this.list = []
    this.length = 0;
    this.queryForm = {
      month: this.$moment().format('YYYY-MM'), province: "", postofficeid: "", corp: "", dispatch_response_corp: "",
    };
  },
  activated() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/billreport/list", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
          this.provinces = res.data.provinces;
          this.postoffices = res.data.postoffices;
          this.corps = res.data.corps;
          this.locked = res.data.locked;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    download() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/billreport/list", { ...this.queryForm, action: 'download' }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    updatebill() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/billreport/update", { ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    lockbill() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/billreport/lock", { ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    unlockbill() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/billreport/unlock", { ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .row+.row {
  margin-top: 0;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
