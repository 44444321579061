<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <div class="header">司机信息</div>
            </v-card-title>
            <div class="form basic">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field v-model="contract.name" label="姓名" outlined dense :disabled="saveing">
                    <template v-slot:label> 姓名 </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="contract.certificate" label="身份证号码" outlined dense :disabled="saveing">
                    <template v-slot:label> 身份证号 </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="contract.phone" label="手机号" type="number" outlined dense :disabled="saveing">
                    <template v-slot:label> 手机号 </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field v-model="contract.bankcard_no" label="收款账号" outlined dense :disabled="saveing">
                    <template v-slot:label> 收款账号 </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="contract.bankcard_host" label="户名" outlined dense :disabled="saveing">
                    <template v-slot:label> 户名 </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="contract.bank_name" label="开户行" outlined dense :disabled="saveing">
                    <template v-slot:label> 开户行 </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <div class="header">合同信息</div>
            </v-card-title>
            <div class="form other">
              <v-row>
                <v-col cols="12" md="4">
                  <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false"
                    :return-value.sync="contract.start_day" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="contract.start_day" label="合同开始日期" append-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" dense outlined clearable :disabled="saveing">
                        <template v-slot:label> 合同开始日期 </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="contract.start_day" no-title scrollable locale="zh-cn"
                      @input="() => { $refs.menu1.save(contract.start_day); menu1 = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                    :return-value.sync="contract.end_day" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="contract.end_day" label="合同截止日期" append-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" dense outlined clearable :disabled="saveing">
                        <template v-slot:label> 合同截止日期 </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="contract.end_day" no-title scrollable locale="zh-cn"
                      @input="() => { $refs.menu2.save(contract.end_day); menu2 = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false"
                    :return-value.sync="contract.sign_day" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="contract.sign_day" label="合同签订日期" append-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" dense outlined clearable :disabled="saveing">
                        <template v-slot:label> 合同签订日期 </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="contract.sign_day" no-title scrollable locale="zh-cn"
                      @input="() => { $refs.menu3.save(contract.sign_day); menu3 = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field v-model="contract.start_place" label="出发地" outlined dense :disabled="saveing">
                    <template v-slot:label> 出发地 </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="contract.end_place" label="目的地" outlined dense :disabled="saveing">
                    <template v-slot:label> 目的地 </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="contract.goods_name" label="货物" outlined dense :disabled="saveing">
                    <template v-slot:label> 货物 </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-card>
            <v-card-title>
              <div class="header">车辆信息</div>
            </v-card-title>
            <div class="form other">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="contract.carnumber" label="车牌号" outlined dense :disabled="saveing">
                    <template v-slot:label> 车牌号 </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="contract.gpsnumber" label="GPS登记号码" outlined dense :disabled="saveing">
                    <template v-slot:label> GPS登记号码 </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="10">
          <v-card>
            <v-card-title>
              <div class="header">运费信息</div>
            </v-card-title>
            <div class="form other">
              <v-row>
                <v-col cols="12" md="2">
                  <v-text-field v-model="contract.money" label="结算金额" type="number" outlined dense :disabled="saveing">
                    <template v-slot:label> 结算金额 </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="contract.money_cn" label="人民币大写" outlined dense :disabled="saveing">
                    <template v-slot:label> 人民币大写 </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field v-if="customer_input_cycle" v-model="contract.billing_cycle" label="结算周期" outlined dense
                    :disabled="saveing">
                    <template v-slot:label> 结算周期 </template>
                  </v-text-field>
                  <v-autocomplete v-else v-model="contract.billing_cycle" :items="billing_cycles" label="结算周期" outlined
                    dense :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" :disabled="saveing">
                    <template v-slot:label> 结算周期 </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-checkbox v-model="contract.customer_input_cycle" label="手动录入" hide-details="auto" dense
                    :disabled="saveing">
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="contract.fuelcard_no" label="油卡卡号" outlined dense :disabled="saveing">
                    <template v-slot:label> 油卡卡号 </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-if="customer_input_method" v-model="contract.settlement_method" label="结算方式" outlined
                    dense :disabled="saveing">
                    <template v-slot:label> 结算方式 </template>
                  </v-text-field>
                  <v-autocomplete v-else v-model="contract.settlement_method" :items="settlement_methods" label="结算方式"
                    outlined dense :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" :disabled="saveing">
                    <template v-slot:label> 结算方式 </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-checkbox v-model="contract.customer_input_method" label="手动录入" hide-details="auto" dense
                    :disabled="saveing">
                  </v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!valid" :loading="saveing" color="warning" tile large width="200px" @click="savecontract">
                提交生成合同
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import _ from "underscore";
export default {
  name: "ContractAdd",
  data() {
    return {
      valid: true,
      saveing: false,
      contract: {
        _id: "",
        name: "",
        certificate: "",
        phone: "",
        bankcard_no: "",
        bankcard_host: "",
        bank_name: "",
        start_day: "",
        end_day: "",
        sign_day: "",
        start_place: "",
        end_place: "",
        goods_name: "",
        carnumber: "",
        gpsnumber: "",
        money: "",
        money_cn: "",
        billing_cycle: "",
        fuelcard_no: "",
        settlement_method: "",
        customer_input_cycle: true,
        customer_input_method: true,
      },
      customer_input_cycle: true,
      customer_input_method: true,
      billing_cycles: ["一个月"],
      settlement_methods: ["油卡+现金"],
      vrules: {
        required: (value) => !!value || "不能为空",
      },
    };
  },
  watch: {
    "$route.params.id"() {
      this.getdata();
    },
    "contract.customer_input_cycle"(customer_input_cycle) {
      setTimeout(() => {
        this.customer_input_cycle = customer_input_cycle;
      }, 200);
    },
    "contract.customer_input_method"(customer_input_method) {
      setTimeout(() => {
        this.customer_input_method = customer_input_method;
      }, 200);
    },
    "contract.money"(money) {
      this.contract.money_cn = this.$utils.numtocn(money);
    },
  },
  activated() {
    this.getdata();
  },
  methods: {
    init(contract) {
      this.contract = contract || {
        _id: "",
        name: "",
        certificate: "",
        phone: "",
        bankcard_no: "",
        bankcard_host: "",
        bank_name: "",
        start_day: "",
        end_day: "",
        sign_day: "",
        start_place: "",
        end_place: "",
        goods_name: "",
        carnumber: "",
        gpsnumber: "",
        money: "",
        money_cn: "",
        billing_cycle: "",
        fuelcard_no: "",
        settlement_method: "",
        customer_input_cycle: true,
        customer_input_method: true,
      };
    },
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/contract/info", { id: this.$route.params.id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.init(res.data.contract);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    savecontract() {
      if (!this.$refs.form.validate()) return;
      this.saveing = true;
      this.$api.doAPI("/contract/save", { contract: this.contract }).then((res) => {
        this.$toast.clear();
        this.saveing = false;
        if (res.code === 0) {
          //TODO
          this.$toast.success("提交成功");
          this.$downloadBrowser({ href: res.data.url });
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.saveing = false;
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scope>
.v-card__title {
  background: #f2f2f2;
  font-size: 14px;
  padding: 10px 16px;
}

.form {
  padding: 15px;
}

p.ph {
  margin: 0;
}

.cell {
  display: inline-block;
}

.cell p:first-child:before {
  content: "*";
  color: red;
  display: inline;
}

.txt {
  top: 13px;
}
</style>
