<template>
  <component :is="getlayout"></component>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "App",
  data: () => ({
    current_layout: "empty-layout",
  }),
  computed: {
    getlayout() {
      return this.$route.meta.layout || this.current_layout;
    },
  },
});
</script>
<style lang="less">
.inaline {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.intwoline {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

#nprogress .bar {
  background: red !important; //�Զ�����ɫ
}

.click {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}
.line-through {
  text-decoration: line-through;
}
</style>