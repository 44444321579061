export const SET_USERID = 'SET_USERID';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_NAME = 'SET_NAME';
export const SET_ROLE = 'SET_ROLE';
export const SET_NAV_TITLE = 'SET_NAV_TITLE';
export const SET_NAV_LOGO = 'SET_NAV_LOGO';
export const OPEN_TAB = 'OPEN_TAB';
export const CLOSE_TAB = 'CLOSE_TAB';
export const CLEAR_TABS = 'CLEAR_TABS';
export const SET_UPLOADTASK_MODEL = 'SET_UPLOADTASK_MODEL';
export const SET_UPLOADTASK_TITLE = 'SET_UPLOADTASK_TITLE';
export const SET_UPLOADTASK_TYPE = 'SET_UPLOADTASK_TYPE';