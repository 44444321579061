<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="4">
                <a-space>
                    <v-text-field v-model="queryForm.loadingplace" label="装货地" dense outlined clearable hide-details /> →
                    <v-text-field v-model="queryForm.unloadingplace" label="卸货地" dense outlined clearable hide-details />
                </a-space>
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete v-model="queryForm.cartype" :items="vehicle_types" label="车辆类型"
                    :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details />
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete v-model="queryForm.carlength" :items="carlengths" label="车长"
                    :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details />
            </v-col>
            <v-col cols="12" md="2">
                <v-menu ref="date_menu" v-model="date_menu" :close-on-content-click="false" :return-value.sync="date"
                    transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" label="日期" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                            dense outlined>
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable locale="zh-cn"
                        @input="() => { $refs.date_menu.save(date); date_menu = false; getdata() }"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
                <a-space>
                    <v-btn color="cyan" tile style="color:#fff;" @click="getdata">
                        <v-icon small>mdi-magnify</v-icon> 查询
                    </v-btn>
                    <v-btn color="primary" tile @click="showadd">
                        <v-icon small>mdi-plus</v-icon> 新增货源
                    </v-btn>
                </a-space>
            </v-col>
        </v-row>
        <v-tabs v-model="tab" color="deep-purple-accent-4" align-tabs="end">
            <v-tab :value="1">全部</v-tab>
            <v-tab :value="2">未预订</v-tab>
            <v-tab :value="3">已预订</v-tab>
        </v-tabs>
        <v-card tile class="mt-2">
            <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
                :scroll="{ x: width, y: height }" row-key="_id">
                <template slot="title">
                    <div class="d-flex align-center pl-2">
                        <span>共 {{ length }} 页 / {{ count }} 条数据</span>
                        <v-menu bottom offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                                    {{ limit }}/页
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                                    <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template slot="index" slot-scope="text, record, index">
                    {{ index + 1 }}
                </template>
                <template slot="create_time" slot-scope="text, record">
                    {{ record.create_time | dateFormat('YYYY-MM-DD HH:mm:ss') }}
                </template>
                <template slot="loadingsite" slot-scope="text, record">
                    <p>装：{{ record.loadingplace }}</p>
                    <p>卸：{{ record.unloadingplace }}</p>
                </template>
                <template slot="typelength" slot-scope="text, record">
                    <p>车型：{{ record.cartype }}</p>
                    <p>车长：{{ record.carlength }}</p>
                    <p>数量：{{ record.carnum }}</p>
                </template>
                <template slot="action" slot-scope="text, record">
                    <a-space>
                        <v-btn text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
                        <a-popconfirm title="删除货源" ok-text="确定" cancel-text="取消" @confirm="remove(record._id)">
                            <v-btn text tile color="error"> 删除 </v-btn>
                        </a-popconfirm>
                    </a-space>
                </template>
            </a-table>
            <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
        </v-card>
        <v-dialog v-model="model" persistent max-width="788px">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
                        {{ modeltitle }}
                        <v-icon @click="cancelsave">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                    <a-cascader v-model="sourcegoodsinfo.loadingplace1" :options="options"
                                        placeholder="选择装货地" />
                                </v-col>
                                <v-col cols="8">
                                    <v-text-field v-model="sourcegoodsinfo.loadingplace2" dense outlined
                                        :rules="[vrules.required]">
                                        <template v-slot:label>
                                            详细地址 <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <a-cascader v-model="sourcegoodsinfo.unloadingplace1" :options="options"
                                        placeholder="选择卸货地" />
                                </v-col>
                                <v-col cols="8">
                                    <v-text-field v-model="sourcegoodsinfo.unloadingplace2" dense outlined
                                        :rules="[vrules.required]">
                                        <template v-slot:label>
                                            详细地址 <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-autocomplete v-model="sourcegoodsinfo.cartype" :items="vehicle_types"
                                        :rules="[vrules.required]" :menu-props="{ bottom: true, offsetY: true }"
                                        no-data-text="无" dense outlined>
                                        <template v-slot:label>
                                            车辆类型 <span class="red--text">*</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                    <v-autocomplete v-model="sourcegoodsinfo.carlengths" :items="carlengths"
                                        :rules="[vrules.required]" :menu-props="{ bottom: true, offsetY: true }"
                                        no-data-text="无" dense outlined>
                                        <template v-slot:label>
                                            车长 <span class="red--text">*</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field v-model="sourcegoodsinfo.carnum" type="number" suffix="辆" dense outlined
                                        :rules="[vrules.required]">
                                        <template v-slot:label>
                                            车辆数量 <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-autocomplete v-model="sourcegoodsinfo.owner" :items="owners"
                                        :rules="[vrules.required]" :menu-props="{ bottom: true, offsetY: true }"
                                        no-data-text="无" dense outlined>
                                        <template v-slot:label>
                                            项目/货主 <span class="red--text">*</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                    <v-autocomplete v-model="sourcegoodsinfo.date" :items="datelist"
                                        :rules="[vrules.required]" :menu-props="{ bottom: true, offsetY: true }"
                                        no-data-text="无" dense outlined>
                                        <template v-slot:label>
                                            装货时间 <span class="red--text">*</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                    <v-autocomplete v-model="sourcegoodsinfo.weight" :items="weightlist"
                                        :rules="[vrules.required]" :menu-props="{ bottom: true, offsetY: true }"
                                        no-data-text="无" dense outlined>
                                        <template v-slot:label>
                                            重量（吨） <span class="red--text">*</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="sourcegoodsinfo.freight" type="number" suffix="元/趟" dense
                                        outlined>
                                        <template v-slot:label>
                                            运费 <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="sourcegoodsinfo.payment" type="number" suffix="元" dense
                                        outlined>
                                        <template v-slot:label>
                                            订金 <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-textarea v-model="sourcegoodsinfo.goods" dense outlined>
                                        <template v-slot:label>
                                            货物信息 <span class="red--text">*</span>
                                        </template>
                                    </v-textarea>
                                </v-col>
                                <v-col cols="6">
                                    <v-textarea v-model="sourcegoodsinfo.mark" label="备注" dense outlined>

                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" tile :disabled="!valid" @click="save">确定</v-btn>
                        <v-btn tile @click="cancelsave">关闭</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
import UploadTask from "@/components/uploadtask.vue";
export default {
    name: "SourceGoodsList",
    data() {
        return {
            tab: "1",
            pages: [
                { text: '20/页', value: 20 },
                { text: '50/页', value: 50 },
                { text: '100/页', value: 100 },
                { text: '200/页', value: 200 },
            ],
            queryForm: { loadingplace: "", unloadingplace: "", cartype: "", carlength: "", },
            vehicle_types: ["平板","高栏","厢式","集装箱","自卸","冷藏","保温","高低板","面包车"],
            owners: ["河南项目","浙江项目","山西项目","广东项目"],
            carlengths:["4.2米", "5米", '5.2米', "6.2米", "7.6米", "8.2米", "9.6米", "11.7米", "13米", "15米", "17.5米"],
            datelist: ['今天', '明天', '后天'],
            weightlist: ["0-5", "5-10", '10-20', "20-30", "30-40", "40-50"],
            valid: true,
            headers: [
                { title: "货源ID", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 80, align: "center" },
                { title: "装卸地", dataIndex: "loadingsite", scopedSlots: { customRender: "loadingsite" }, width: 150, align: "center" },
                { title: "车型车长", dataIndex: "typelength", scopedSlots: { customRender: "typelength" }, width: 100, align: "center" },
                { title: "重量(吨)", dataIndex: "weight", scopedSlots: { customRender: "weight" }, width: 100, align: "center" },
                { title: "运费", dataIndex: "freight", scopedSlots: { customRender: "freight" }, width: 100, align: "center" },
                { title: "货主", dataIndex: "owner", scopedSlots: { customRender: "owner" }, width: 100, align: "center" },
                { title: "货物信息", dataIndex: "goods", scopedSlots: { customRender: "goods" }, width: 100, align: "center" },
                { title: "时间要求", dataIndex: "date", scopedSlots: { customRender: "date" }, width: 100, align: "center" },
                { title: "订金", dataIndex: "payment", scopedSlots: { customRender: "payment" }, width: 100, align: "center" },
                { title: "状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 100, align: "center" },
                { title: "备注", dataIndex: "mark", scopedSlots: { customRender: "mark" }, width: 100, align: "center" },
                { title: "创建时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
                { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: 'right' },
            ],
            page: 1,
            limit: 20,
            list: [],
            length: 0,
            count: 0,
            height: 600,
            model: false,
            modeltitle: "",
            vrules: {
                required: (value) => !!value || "不能为空",
            },
            sourcegoodsinfo_template: {
                _id: "", loadingplace: '', unloadingplace: '', cartype: '', carlength: '', carnum: '1', freight: "", owner: "", goods: "", date: "", weight: '', payment: "200", mark: ""
            },
            sourcegoodsinfo: {},
            date_menu: false,
            date: "",
            options: [
                {
                    value: '浙江',
                    label: '浙江',
                    children: [
                        {
                            value: '杭州',
                            label: '杭州',
                            children: [
                                {
                                    value: '余杭区',
                                    label: '余杭区',
                                },
                            ],
                        },
                    ],
                },
                {
                    value: '河南',
                    label: '河南',
                    children: [
                        {
                            value: '郑州',
                            label: '郑州',
                            children: [
                                {
                                    value: '金水区',
                                    label: '金水区',
                                },
                                {
                                    value: '二七区',
                                    label: '二七区',
                                },
                                {
                                    value: '管城区',
                                    label: '管城区',
                                },
                            ],
                        },
                    ],
                }],
        }
    },
    computed: {
        ...mapState(["role", "username"]),
        width() {
            return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
        },
    },
    components: { UploadTask },
    watch: {
        page() {
            this.getdata();
        },
        limit() {
            if (this.page !== 1) this.page = 1;
            else this.getdata();
        },
    },
    activated() {
        this.page = 1;
        this.list = [];
        this.length = 0;
        this.count = 0;
        this.getdata();
    },
    methods: {
        showupload() {
        },
        getdata() {

        },
        showadd() {
            this.sourcegoodsinfo = _.extend({}, { ...this.sourcegoodsinfo_template });
            this.modeltitle = "新增货源";
            this.model = true;
        },
        showedit(item) {
            this.sourcegoodsinfo = _.pick(item, _.keys(this.sourcegoodsinfo_template));
            this.modeltitle = "编辑货源";
            this.model = true;
        },
        save() {
            if (!this.$refs.form.validate()) return;

        },
        cancelsave() {
            this.$refs.form.resetValidation();
            this.model = false;
        },
        remove(_id) {
            this.$toast.loading({ message: "" });

        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .row {
    margin-bottom: 0 !important;
}

/deep/ .col-12 {
    padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    padding: 0 4px;
}

/deep/ p {
    margin: 0;
    padding: 0;
}
</style>
