<template>
  <v-container fluid>
    <a-space>
      <v-btn color="primary" tile @click="showadd">
        <v-icon small>mdi-plus</v-icon>新增车辆比例
      </v-btn>
      <v-btn color="primary" tile outlined @click="getdata">
        <v-icon small>mdi-reload</v-icon>刷新
      </v-btn>
    </a-space>
    <v-card tile class="mt-2">
      <v-data-table :headers="headers" :items="list" :items-per-page="limit" no-data-text="无数据" hide-default-footer
        disable-sort>
        <template v-slot:item.index="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.rate_3="{ item }">
          {{ item.rate_3 || '无' }}{{ item.volume_3 ? `(${item.volume_3}m³)` : '' }}
        </template>
        <template v-slot:item.rate_5="{ item }">
          {{ item.rate_5 || '无' }}{{ item.volume_5 ? `(${item.volume_5}m³)` : '' }}
        </template>
        <template v-slot:item.rate_8="{ item }">
          {{ item.rate_8 || '无' }}{{ item.volume_8 ? `(${item.volume_8}m³)` : '' }}
        </template>
        <template v-slot:item.rate_12="{ item }">
          {{ item.rate_12 || '无' }}{{ item.volume_12 ? `(${item.volume_12}m³)` : '' }}
        </template>
        <template v-slot:item.rate_20="{ item }">
          {{ item.rate_20 || '无' }}{{ item.volume_20 ? `(${item.volume_20}m³)` : '' }}
        </template>
        <template v-slot:item.rate_25="{ item }">
          {{ item.rate_25 || '无' }}{{ item.volume_25 ? `(${item.volume_25}m³)` : '' }}
        </template>
        <template v-slot:item.rate_30="{ item }">
          {{ item.rate_30 || '无' }}{{ item.volume_30 ? `(${item.volume_30}m³)` : '' }}
        </template>
        <template v-slot:item.rate_40="{ item }">
          {{ item.rate_40 || '无' }}{{ item.volume_40 ? `(${item.volume_40}m³)` : '' }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn text color="success" @click="showedit(item)"> 编辑 </v-btn>
          <a-popconfirm title="删除" ok-text="确定" cancel-text="取消" @confirm="remove(item._id)">
            <v-btn text color="error"> 删除 </v-btn>
          </a-popconfirm>
        </template>
      </v-data-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="model" persistent max-width="580px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            {{ modeltitle }}
            <v-icon @click="cancelsave">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" lg="6" md="6">
                  <v-autocomplete :items="provinces" v-model="vehiclerateinfo.province" :rules="[vrules.required]"
                    :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
                    <template v-slot:label>
                      省份 <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-autocomplete :items="postroutelevels" v-model="vehiclerateinfo.postroutelevel"
                    :rules="[vrules.required]" :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense
                    outlined>
                    <template v-slot:label>
                      邮路级别 <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.rate_3" type="number" label="3吨系数" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.volume_3" type="number" label="3吨容积" suffix="m³" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.rate_5" type="number" label="5吨系数" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.volume_5" type="number" label="5吨容积" suffix="m³" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.rate_8" type="number" label="8吨系数" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.volume_8" type="number" label="8吨容积" suffix="m³" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.rate_12" type="number" label="12吨系数" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.volume_12" type="number" label="12吨容积" suffix="m³" dense
                    outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.rate_20" type="number" label="20吨系数" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.volume_20" type="number" label="20吨容积" suffix="m³" dense
                    outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.rate_25" type="number" label="25吨系数" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.volume_25" type="number" label="25吨容积" suffix="m³" dense
                    outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.rate_30" type="number" label="30吨系数" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.volume_30" type="number" label="30吨容积" suffix="m³" dense
                    outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.rate_40" type="number" label="40吨系数" dense outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="vehiclerateinfo.volume_40" type="number" label="40吨容积" suffix="m³" dense
                    outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="vehiclerateinfo.remark" label="备注" dense outlined>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" tile :disabled="!valid" @click="save">确定</v-btn>
            <v-btn tile @click="cancelsave">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "PostOfficeCarratio",
  data() {
    return {
      valid: true,
      headers: [
        { text: "序号", value: "index", align: "center", width: 100 },
        { text: "省份", value: "province", align: "center", width: 150 },
        { text: "邮路级别", value: "postroutelevel", align: "center", width: 150 },
        { text: "3吨系数(容积)", value: "rate_3", align: "center", width: 120 },
        { text: "5吨系数(容积)", value: "rate_5", align: "center", width: 120 },
        { text: "8吨系数(容积)", value: "rate_8", align: "center", width: 120 },
        { text: "12吨系数(容积)", value: "rate_12", align: "center", width: 120 },
        { text: "20吨系数(容积)", value: "rate_20", align: "center", width: 120 },
        { text: "25吨系数(容积)", value: "rate_25", align: "center", width: 120 },
        { text: "30吨系数(容积)", value: "rate_30", align: "center", width: 120 },
        { text: "40吨系数(容积)", value: "rate_40", align: "center", width: 120 },
        { text: "备注", value: "remark", align: "center", width: 150 },
        { text: "操作", value: "action", width: 180, fixed: 'right' },
      ],
      provinces: [],
      postroutelevels: [],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      model: false,
      modeltitle: "",
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      vehiclerateinfo_template: {
        _id: "",
        province: "",
        postroutelevel: "",
        rate_3: "",
        rate_5: "",
        rate_8: "",
        rate_12: "",
        rate_20: "",
        rate_25: "",
        rate_30: "",
        rate_40: "",
        volume_3: "",
        volume_5: "",
        volume_8: "",
        volume_12: "",
        volume_20: "",
        volume_25: "",
        volume_30: "",
        volume_40: "",
        remark: ""
      },
      vehiclerateinfo: {},
    };
  },
  computed: {
    ...mapState(["role", "username"]),
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  activated() {
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.count = 0;
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/vehiclerate/list", { page: this.page, limit: this.limit, }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.provinces = res.data.provinces;
          this.postroutelevels = res.data.postroutelevels;
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showadd() {
      this.vehiclerateinfo = _.extend({}, { ...this.vehiclerateinfo_template });
      this.modeltitle = "新增车辆比例系统";
      this.model = true;
    },
    showedit(item) {
      this.vehiclerateinfo = _.pick(item, _.keys(this.vehiclerateinfo_template));
      this.modeltitle = "编辑车辆比例系统";
      this.model = true;
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/vehiclerate/save", { vehiclerateinfo: this.vehiclerateinfo }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.model = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelsave() {
      this.$refs.form.resetValidation();
      this.model = false;
    },
    remove(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/vehiclerate/remove", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
