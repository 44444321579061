<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.postofficeid" :items="postoffices" label="组开局"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.postroutelevel" :items="['一级干线', '二级干线']" label="邮路级别"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.province" :items="provinces" label="省份"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="6">
        <v-autocomplete v-model="queryForm.destprovince" :items="destprovinces" label="寄达省"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="6">
        <a-space>
          <v-text-field v-model="queryForm.departure" label="始发" dense outlined clearable hide-details /> →
          <v-text-field v-model="queryForm.destination" label="到达" dense outlined clearable hide-details />
        </a-space>
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-text-field v-model="queryForm.name" label="名称" dense outlined clearable hide-details />
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-autocomplete v-model="queryForm.carrierid" :items="carriers" label="承运商"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-autocomplete v-model="queryForm.transport_kind" :items="transport_kinds" label="单程/往返"
          :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-autocomplete v-model="queryForm.corp" :items="corps" label="所属公司" :menu-props="{ bottom: true, offsetY: true }"
          no-data-text="无" dense outlined clearable hide-details>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-btn @click="getdata" color="primary" tile style="width:80%;">查询</v-btn>
      </v-col>
    </v-row>
    <v-card tile>
      <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: width, y: height }" row-key="_id">
        <template slot="title">
          <div class="d-flex justify-space-between align-center pl-2">
            <div>
              <span>共 {{ length }} 页 / {{ count }} 条数据</span>
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                    {{ limit }}/页
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                    <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-btn v-if="username === 'root'" tile text color="success" @click="copyall">
              复制全部
            </v-btn>
          </div>
        </template>
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
          <v-btn v-if="username === 'root'" tile text color="success" @click="copyid(record)">
            复制
          </v-btn>
        </template>
        <template slot="customA">
          <p>省份</p>
          <p>线路名称</p>
          <p>寄达省</p>
        </template>
        <template slot="customB">
          <p>所属公司</p>
          <p>邮路级别</p>
          <p>单程/往返</p>
        </template>
        <template slot="customC">
          <p>邮局合同</p>
        </template>
        <template slot="customD">
          <p>组开局</p>
          <p>公里数</p>
          <p>车型</p>
          <p>单价</p>
        </template>
        <template slot="customE">
          <p>油价联动</p>
          <p>账期</p>
        </template>
        <template slot="customF">
          <p>期限</p>
        </template>
        <template slot="customG">
          <p>承运商合同</p>
        </template>
        <template slot="customH">
          <p>承运商</p>
          <p>车型</p>
          <p>单价</p>
        </template>
        <template slot="customI">
          <p>保险</p>
          <p>账期模式</p>
          <p>账期</p>
        </template>
        <template slot="customJ">
          <p>期限</p>
        </template>
        <template slot="A" slot-scope="text, record">
          <p>{{ record.province }}</p>
          <p>{{ record.name }}</p>
          <p>{{ record.destprovince }}</p>
        </template>
        <template slot="B" slot-scope="text, record">
          <p>{{ record.corp }}</p>
          <p>{{ record.postroutelevel }}</p>
          <p>{{ record.transport_kind }}</p>
        </template>
        <template slot="C" slot-scope="text, record">
          <v-btn tile text color="success" @click="showupcontracts(record)">
            邮局合同({{ record.upcontract_count }})
          </v-btn>
        </template>
        <template slot="D" slot-scope="text, record">
          <p class="success--text">{{ getpostofficename(record) }}</p>
          <p v-if="record.postlinecontract">
            {{ record.postlinecontract.km }}
          </p>
          <p v-if="record.postlinecontract">
            {{ record.postlinecontract.vehicle_type }}
          </p>
          <p v-if="record.postlinecontract">
            {{ record.postlinecontract.price }}
          </p>
        </template>
        <template slot="E" slot-scope="text, record">
          <p v-if="record.postlinecontract">
            {{ record.postlinecontract.oilpricelink }}
          </p>
          <p v-if="record.postlinecontract">
            {{ record.postlinecontract.account_period }}
          </p>
        </template>
        <template slot="F" slot-scope="text, record">
          <p v-if="record.postlinecontract">
            {{ record.postlinecontract.start_day || '-' }}
          </p>
          <p v-if="record.postlinecontract">
            {{ record.postlinecontract.end_day || '-' }}
          </p>
        </template>
        <template slot="G" slot-scope="text, record">
          <v-btn tile text color="error" @click="showdowncontracts(record)">
            承运商合同({{ record.downcontract_count }})
          </v-btn>
        </template>
        <template slot="H" slot-scope="text, record">
          <p v-if="record.carriercontract" class="error--text">
            {{ record.carriercontract.shortname }}
          </p>
          <p v-if="record.carriercontract">
            {{ record.carriercontract.vehicle_type }}
          </p>
          <p v-if="record.carriercontract">
            {{ record.carriercontract.price }}
          </p>
        </template>
        <template slot="I" slot-scope="text, record">
          <template v-if="record.carriercontract && record.carriercontract.insurances">
            <div v-html="record.carriercontract.insurances.join('<br>')"></div>
          </template>
          <p v-if="record.carriercontract">
            {{ record.carriercontract.account_period_mode }}
          </p>
          <p v-if="record.carriercontract">
            {{ record.carriercontract.account_period }}
          </p>
        </template>
        <template slot="J" slot-scope="text, record">
          <p v-if="record.carriercontract">
            {{ record.carriercontract.start_day || '-' }}
          </p>
          <p v-if="record.carriercontract">
            {{ record.carriercontract.end_day || '-' }}
          </p>
        </template>
        <template slot="create_time" slot-scope="text, record">
          <p>{{ record.create_time }}</p>
          <p>{{ record.create_by }}</p>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <v-btn text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
            <a-popconfirm title="删除线路" ok-text="确定" cancel-text="取消" @confirm="remove(record._id)">
              <v-btn text tile color="error"> 删除 </v-btn>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="contractsModel" fullscreen persistent max-width="1000px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon tile @click="contractsModel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>合同管理</v-toolbar-title>
          <v-toolbar-title class="ml-2">{{ contractsTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn tile text @click="contractsModel = false"> 关闭 </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <div class="d-flex justify-space-between">
              <a-tabs type="card" v-model="contractType" @change="showcontracts" size="large">
                <a-tab-pane key="up" tab="邮局合同">
                </a-tab-pane>
                <a-tab-pane key="down" tab="承运商合同">
                </a-tab-pane>
              </a-tabs>
              <a-space>
                <v-btn color="cyan" dark tile @click="bulkcopycontract">批量复制</v-btn>
                <v-btn v-if="contractType === 'up'" color="success" tile @click="addcontract">新增邮局合同</v-btn>
                <v-btn v-if="contractType === 'down'" color="error" tile @click="addcontract">新增(承运商)合同</v-btn>
              </a-space>
            </div>
            <a-table size="small" :columns="contractType === 'up' ? cheaders1 : cheaders2" :data-source="contracts" :pagination="false"
              :locale="{ emptyText: '暂无数据' }" :scroll="{ y: height }" row-key="_id"
              :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }">
              <template slot="postroutes" slot-scope="text, record">
                <a-tag v-for="(postroute, index) in record.postroutes" :key="index"
                  :color="postroutecolors[index % record.postroutes.length]">
                  {{ postroute }}
                </a-tag>
              </template>
              <template slot="shortname" slot-scope="text, record">
                {{ record.shortname }}
                <v-btn v-if="username === 'root'" tile text color="success" @click="copycarriercontract(record)">
                  复制
                </v-btn>
              </template>
              <template slot="price" slot-scope="text, record">
                <div>
                  {{ record.price }}<span v-if="record.price_fixed" class="error--text"> (固定)</span>
                </div>
              </template>
              <template slot="insurance" slot-scope="text, record">
                <p v-for="(item, index) in record.insurance_list" :key="index">
                  {{ item.vehicle_type }}：{{ item.insurance }}
                </p>
                <v-btn tile text color="orange" @click="editinsurance(record)">修改</v-btn>
              </template>
              <template slot="contract_duration" slot-scope="text, record">
                <p :class="{ 'success--text': record.running }">{{ record.start_day }}~{{ record.end_day }}</p>
              </template>
              <template slot="create_time" slot-scope="text, record">
                <p>{{ record.create_time }}</p>
                <p>{{ record.create_by }}</p>
              </template>
              <template slot="action" slot-scope="text, record">
                <a-space>
                  <v-btn text tile color="success" @click="editcontract(record)"> 编辑 </v-btn>
                  <v-btn text tile color="purple" @click="copycontract(record)"> 复制 </v-btn>
                  <a-popconfirm title="删除合同" ok-text="确定" cancel-text="取消" @confirm="removecontract(record._id)">
                    <v-btn text tile color="error"> 删除 </v-btn>
                  </a-popconfirm>
                </a-space>
              </template>
            </a-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="contractModel" persistent max-width="600px">
      <v-form ref="contractForm" v-model="contractValid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            {{ contractTitle }}
            <v-icon @click="cancelsavecontract">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <template v-if="contractType === 'up'">
                  <v-col cols="12" md="6">
                    <v-text-field v-model="contractForm.km" :rules="[vrules.required]" type="number" dense outlined>
                      <template v-slot:label>
                        合同公里数 <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="contractForm.vehicle_type" :items="vehicle_types" :rules="[vrules.required]"
                      :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
                      <template v-slot:label>
                        车型 <span class="red--text">*</span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="contractForm.price" :rules="[vrules.required]" type="number" dense outlined>
                      <template v-slot:label>
                        合同单价 <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox v-model="contractForm.price_fixed" label="固定价格"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="contractForm.oilpricelink" :items="['有', '无']" :rules="[vrules.required]"
                      :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
                      <template v-slot:label>
                        油价联动 <span class="red--text">*</span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="contractForm.account_period" :rules="[vrules.required]" type="number" dense
                      outlined>
                      <template v-slot:label>
                        账期 <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </template>
                <template v-if="contractType === 'down'">
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="contractForm.carrierid" :items="carriers" :rules="[vrules.required]"
                      :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined
                      @change="(e) => changeCarrier(e, contractForm.carrierid)">
                      <template v-slot:label>
                        承运商 <span class="red--text">*</span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="contractForm.vehicle_type" :items="vehicle_types" :rules="[vrules.required]"
                      :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
                      <template v-slot:label>
                        车型 <span class="red--text">*</span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="contractForm.price" :rules="[vrules.required]" type="number" dense outlined>
                      <template v-slot:label>
                        合同单价 <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox v-model="contractForm.price_fixed" label="固定价格" dense></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <!-- <v-text-field v-model="contractForm.insurance" type="number" dense outlined>
                      <template v-slot:label>
                        保险
                      </template>
                    </v-text-field> -->
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="contractForm.account_period_mode" :items="carrier_account_period_modes"
                      :rules="[vrules.required]" :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense
                      outlined>
                      <template v-slot:label>
                        账期模式 <span class="red--text">*</span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="contractForm.account_period" :rules="[vrules.required]" type="number" dense
                      outlined>
                      <template v-slot:label>
                        账期 <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </template>
                <v-col cols="12" md="6">
                  <v-menu ref="contract_start_menu" v-model="contract_start_menu" :close-on-content-click="false"
                    :return-value.sync="contractForm.start_day" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="contractForm.start_day" :rules="[vrules.required]" append-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          合同期限开始 <span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="contractForm.start_day" no-title scrollable locale="zh-cn"
                      @input="() => { $refs.contract_start_menu.save(contractForm.start_day); contract_start_menu = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu ref="contract_end_menu" v-model="contract_end_menu" :close-on-content-click="false"
                    :return-value.sync="contractForm.end_day" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="contractForm.end_day" :rules="[vrules.required]" append-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          合同期限结束 <span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="contractForm.end_day" no-title scrollable locale="zh-cn"
                      @input="() => { $refs.contract_end_menu.save(contractForm.end_day); contract_end_menu = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" v-if="contractModel">
                  <input-tag v-model="contractForm.postroutes" placeholder="输入邮路名称括号内容(不含括号)，回车添加"></input-tag>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="contractForm.remark" outlined rows="2" label="备注"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" tile :disabled="!contractValid" @click="savecontract">保存</v-btn>
            <v-btn tile @click="cancelsavecontract">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="insuranceModel" persistent max-width="600px">
      <v-card>
        <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
          编辑保险
          <v-icon @click="() => insuranceModel = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="5">
              <v-autocomplete v-model="insuranceItem.vehicle_type" :items="vehicle_types" label="车型" placeholder="车型"
                :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field v-model="insuranceItem.insurance" label="保险" type="number" dense outlined>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn text tile :color="insuranceIndex === -1 ? 'success' : 'orange'" @click="saveinsuranceitem">
                {{ insuranceIndex === -1 ? '添加' : '保存' }}
              </v-btn>
            </v-col>
          </v-row>
          <a-table size="small" :columns="insuranceHeaders" :data-source="insuranceForm.insurance_list" :pagination="false"
            :locale="{ emptyText: '暂无数据' }">
            <template slot="action" slot-scope="text, record, index">
              <a-space>
                <v-btn text tile color="success" @click="editinsuranceitem(index)"> 编辑 </v-btn>
                <v-btn text tile color="error" @click="removeinsuranceitem(index)"> 删除 </v-btn>
              </a-space>
            </template>
          </a-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" tile @click="savecontractinsurance">保存</v-btn>
          <v-btn tile @click="() => insuranceModel = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "PostLineList",
  data() {
    return {
      pages: [
        { text: '20/页', value: 20 },
        { text: '50/页', value: 50 },
        { text: '100/页', value: 100 },
        { text: '200/页', value: 200 },
        // { text: '500/页', value: 500 },
        // { text: '1000/页', value: 1000 },
        // { text: '1500/页', value: 1500 },
      ],
      queryForm: {
        name: "",
        postofficeid: undefined,
        postroutelevel: undefined,
        province: undefined,
        destprovince: undefined,
        departure: "",
        destination: "",
        carrierid: undefined,
        transport_kind: undefined,
        corp: undefined,
      },
      postoffices: [],
      provinces: [],
      destprovinces: [],
      carriers: [],
      transport_kinds: [],
      corps: [],
      postroute: "",
      postroutes: [],
      valid: true,
      postroutecolors: ['pink', 'red', 'orange', 'green', 'cyan', 'blue', 'purple'],
      headers: [
        { title: "序号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 100, align: "center" },
        { dataIndex: "A", slots: { title: "customA" }, scopedSlots: { customRender: "A" }, width: 120, align: "center", },
        { dataIndex: "B", slots: { title: "customB" }, scopedSlots: { customRender: "B" }, width: 120, align: "center", },
        { dataIndex: "C", slots: { title: "customC" }, scopedSlots: { customRender: "C" }, width: 120, align: "center", },
        { dataIndex: "D", slots: { title: "customD" }, scopedSlots: { customRender: "D" }, width: 120, align: "center", },
        { dataIndex: "E", slots: { title: "customE" }, scopedSlots: { customRender: "E" }, width: 120, align: "center", },
        { dataIndex: "F", slots: { title: "customF" }, scopedSlots: { customRender: "F" }, width: 150, align: "center", },
        { dataIndex: "G", slots: { title: "customG" }, scopedSlots: { customRender: "G" }, width: 120, align: "center", },
        { dataIndex: "H", slots: { title: "customH" }, scopedSlots: { customRender: "H" }, width: 120, align: "center", },
        { dataIndex: "I", slots: { title: "customI" }, scopedSlots: { customRender: "I" }, width: 200, align: "center", },
        { dataIndex: "J", slots: { title: "customJ" }, scopedSlots: { customRender: "J" }, width: 150, align: "center", },
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 200, align: "center" },
        { title: "创建时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 180, align: "center", fixed: 'right' },
      ],
      cheaders1: [
        { title: "邮路", dataIndex: "postroutes", scopedSlots: { customRender: "postroutes" }, width: 180, align: "center" },
        { title: "合同公里数", dataIndex: "km", scopedSlots: { customRender: "km" }, width: 120, align: "center" },
        { title: "车型", dataIndex: "vehicle_type", scopedSlots: { customRender: "vehicle_type" }, width: 100, align: "center" },
        { title: "合同单价", dataIndex: "price", scopedSlots: { customRender: "price" }, width: 120, align: "center" },
        { title: "油价联动", dataIndex: "oilpricelink", scopedSlots: { customRender: "oilpricelink" }, width: 100, align: "center" },
        { title: "账期", dataIndex: "account_period", scopedSlots: { customRender: "account_period" }, width: 100, align: "center" },
        { title: "合同期限", dataIndex: "contract_duration", scopedSlots: { customRender: "contract_duration" }, width: 200, align: "center" },
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 200, align: "center" },
        { title: "创建时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 200, align: "center" },
      ],
      cheaders2: [
        { title: "邮路", dataIndex: "postroutes", scopedSlots: { customRender: "postroutes" }, width: 180, align: "center" },
        { title: "承运商", dataIndex: "shortname", scopedSlots: { customRender: "shortname" }, width: 120, align: "center" },
        { title: "车型", dataIndex: "vehicle_type", scopedSlots: { customRender: "vehicle_type" }, width: 100, align: "center" },
        { title: "合同单价", dataIndex: "price", scopedSlots: { customRender: "price" }, width: 120, align: "center" },
        { title: "保险", dataIndex: "insurance", scopedSlots: { customRender: "insurance" }, width: 120, align: "center" },
        { title: "账期模式", dataIndex: "account_period_mode", scopedSlots: { customRender: "account_period_mode" }, width: 100, align: "center" },
        { title: "账期", dataIndex: "account_period", scopedSlots: { customRender: "account_period" }, width: 100, align: "center" },
        { title: "合同期限", dataIndex: "contract_duration", scopedSlots: { customRender: "contract_duration" }, width: 200, align: "center" },
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 200, align: "center" },
        { title: "创建时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 200, align: "center" },
      ],
      selectedRowKeys: [],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      height: 600,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      contractsModel: false,
      contractsTitle: '',
      contracts: [],
      postlineid: '',
      contractType: '',
      contractModel: false,
      contractTitle: '',
      contractValid: true,
      contractForm: {},
      vehicle_types: [],
      carrier_account_period_modes: [],
      contract_start_menu: false,
      contract_end_menu: false,
      insuranceForm: {
        _id: "", insurance_list: []
      },
      insuranceItem: {
        vehicle_type: "", insurance: ""
      },
      insuranceIndex: -1,
      insuranceHeaders: [
        { title: "车型", dataIndex: "vehicle_type", scopedSlots: { customRender: "vehicle_type" }, width: 100, align: "center" },
        { title: "保险", dataIndex: "insurance", scopedSlots: { customRender: "insurance" }, width: 100, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 100, align: "center" },
      ],
      insuranceModel: false,
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
    cwidth1() {
      return _.reduce(this.cheaders1, (a, b) => a + (b.width || 300), 0);
    },
    cwidth2() {
      return _.reduce(this.cheaders2, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
  },
  activated() {
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.count = 0;
    this.getdata();
  },
  mounted() {
    this.height = window.innerHeight - 300;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 300;
    };
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/list", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.postoffices = res.data.postoffices;
          this.provinces = res.data.provinces;
          this.destprovinces = res.data.destprovinces;
          this.carriers = res.data.carriers;
          this.transport_kinds = res.data.transport_kinds;
          this.corps = res.data.corps;
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showupcontracts(item) {
      this.postlineid = item._id;
      this.contractType = 'up';
      this.contractsTitle = `${item.name} ${item.transport_kind} ${item.postroutelevel}`
      this.showcontracts();
    },
    showdowncontracts(item) {
      this.postlineid = item._id;
      this.contractType = 'down';
      this.contractsTitle = `${item.name} ${item.transport_kind} ${item.postroutelevel}`
      this.showcontracts();
    },
    showcontracts() {
      this.selectedRowKeys = [];
      this.contracts = [];
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/contracts", { postlineid: this.postlineid, contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.contracts = _.map(res.data.list, n => {
            return {
              ...n,
              running: n.start_t <= Date.now() && n.end_t >= Date.now(),
            }
          });
          this.contractsModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    addcontract() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/contractinfo", { contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.contractForm = res.data.contractForm;
          this.vehicle_types = res.data.vehicle_types;
          this.carrier_account_period_modes = res.data.carrier_account_period_modes;
          this.contractTitle = `新增${this.contractType === 'up' ? '邮局' : '承运商'}合同`;
          this.contractModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    editcontract(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/contractinfo", { _id: item._id, contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.contractForm = res.data.contractForm;
          this.vehicle_types = res.data.vehicle_types;
          this.carrier_account_period_modes = res.data.carrier_account_period_modes;
          this.contractTitle = `编辑${this.contractType === 'up' ? '邮局' : '承运商'}合同`;
          this.contractModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    bulkcopycontract() {
      if (this.selectedRowKeys.length === 0) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/bulkcopycontract", { ids: this.selectedRowKeys, contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.showcontracts();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    copycontract(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/contractinfo", { _id: item._id, contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.contractForm = { ...res.data.contractForm, _id: "" };
          this.vehicle_types = res.data.vehicle_types;
          this.carrier_account_period_modes = res.data.carrier_account_period_modes;
          this.contractTitle = `新增${this.contractType === 'up' ? '邮局' : '承运商'}合同`;
          this.contractModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    removecontract(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/removecontract", { _id, contractType: this.contractType }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.showcontracts();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    savecontract() {
      if (!this.$refs.contractForm.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/savecontract", { postlineid: this.postlineid, contractType: this.contractType, contractForm: this.contractForm }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.contractModel = false;
          this.showcontracts();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelsavecontract() {
      this.$refs.contractForm.resetValidation();
      this.contractModel = false;
    },
    remove(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/remove", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    editinsurance(item) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/contractinsurance", { _id: item._id }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.vehicle_types = res.data.vehicle_types;
          this.insuranceForm = res.data.insuranceForm;
          this.resetInsuranceItem();
          this.insuranceModel = true;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    saveinsuranceitem() {
      if (!this.insuranceItem.vehicle_type) return this.$alert("车型不能为空");
      if (!this.insuranceItem.insurance) return this.$alert("保险不能为空");
      let insurance_item = _.pick(this.insuranceItem, ['vehicle_type', 'insurance']);
      let insurance_index = this.insuranceIndex;
      if (insurance_index === -1) {
        this.insuranceForm.insurance_list.push(insurance_item);
      } else {
        this.insuranceForm.insurance_list[insurance_index] = insurance_item;
      }
      this.resetInsuranceItem();
    },
    editinsuranceitem(index) {
      this.insuranceIndex = index;
      this.insuranceItem = _.pick(this.insuranceForm.insurance_list[index], ['vehicle_type', 'insurance']);
    },
    removeinsuranceitem(index) {
      this.insuranceForm.insurance_list.splice(index, 1);
      this.resetInsuranceItem();
    },
    savecontractinsurance() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/saveinsurance", { insuranceForm: this.insuranceForm }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.insuranceModel = false;
          this.showcontracts();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    resetInsuranceItem() {
      this.insuranceItem = { vehicle_type: "", insurance: "" };
      this.insuranceIndex = -1;
    },
    changeCarrier(e, o) {
      console.log('changeCarrier', e, o);
      if (o) {
        if (e === '43d7d19ca69944a3bef279572f65a9b9') {//自营
          this.contractForm.price = '0';
          this.contractForm.price_fixed = false;
          this.contractForm.account_period_mode = '背靠背';
          this.contractForm.account_period = '0';
          this.contractForm.start_day = this.$utils.dateFormat();
          this.contractForm.end_day = this.$utils.dateFormat();
        } else {
          // this.contractForm.price = '';
          // this.contractForm.price_fixed = false;
          // this.contractForm.account_period_mode = '';
          // this.contractForm.account_period = '';
          // this.contractForm.start_day = '';
          // this.contractForm.end_day = '';
        }
      }
    },
    copyid(item) {
      let that = this;
      this.$copyText(item._id).then(
        function (e) {
          that.$toast.success("复制成功");
          console.log(e);
        },
        function (e) {
          console.log(e);
        }
      );
    },
    copyall() {
      let that = this;
      let list = _.pluck(this.list, "_id");
      this.$copyText(JSON.stringify(list)).then(
        function (e) {
          that.$toast.success("复制成功");
          console.log(e);
        },
        function (e) {
          console.log(e);
        }
      );
    },
    copycarriercontract(item) {
      let that = this;
      let data = {
        carrier_contractid: item._id,
        postlineid: item.postlineid,
        carrierid: item.carrierid,
      };
      this.$copyText(JSON.stringify(data)).then(
        function (e) {
          that.$toast.success("复制成功");
          console.log(e);
        },
        function (e) {
          console.log(e);
        }
      );
    },
    getpostofficename(item) {
      let postoffice = _.findWhere(this.postoffices, { value: item.postofficeid });
      return postoffice?.text;
    },
    getcarriername(item) {
      let carrier = _.findWhere(this.carriers, { value: item.carrierid });
      return carrier?.text;
    },
    showedit(item) {
      // TODO
      this.$router.replace({ path: "/postline/create", query: { id: item._id } });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

p {
  margin: 0;
}
</style>
