import * as types from './types'

export default {
  SetUserid({ commit }, data) {
    commit(types.SET_USERID, data)
  },
  SetUsername({ commit }, data) {
    commit(types.SET_USERNAME, data)
  },
  SetName({ commit }, data) {
    commit(types.SET_NAME, data)
  },
  SetRole({ commit }, data) {
    commit(types.SET_ROLE, data)
  },
  SetNavTitle({ commit }, data) {
    commit(types.SET_NAV_TITLE, data)
  },
  SetNavLogo({ commit }, data) {
    commit(types.SET_NAV_LOGO, data)
  },
  OpenTab({ commit }, data) {
    commit(types.OPEN_TAB, data);
  },
  CloseTab({ commit }, data) {
    commit(types.CLOSE_TAB, data);
  },
  ClearTabs({ commit }, data) {
    commit(types.CLEAR_TABS, data);
  },
  SetUploadTaskModel({ commit }, data) {
    commit(types.SET_UPLOADTASK_MODEL, data);
  },
  SetUploadTaskTitlt({ commit }, data) {
    commit(types.SET_UPLOADTASK_TITLE, data);
  },
  SetUploadTaskType({ commit }, data) {
    commit(types.SET_UPLOADTASK_TYPE, data);
  },
}
