<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="4">
                <v-text-field v-model="queryForm.goodsID" label="货源ID" dense outlined clearable hide-details />
            </v-col>
            <v-col cols="12" md="4">
                <v-menu ref="date_menu" v-model="date_menu" :close-on-content-click="false" :return-value.sync="date"
                    transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" label="日期" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                            dense outlined>
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable locale="zh-cn"
                        @input="() => { $refs.date_menu.save(date); date_menu = false; getdata() }"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="4">
                <a-space>
                    <v-btn color="cyan" tile style="color:#fff;" @click="getdata">
                        <v-icon small>mdi-magnify</v-icon> 查询
                    </v-btn>
                </a-space>
            </v-col>
        </v-row>
        <v-tabs v-model="tab" color="deep-purple-accent-4" align-tabs="end">
            <v-tab :value="1">全部</v-tab>
            <v-tab :value="2">未退还</v-tab>
            <v-tab :value="3">已退还</v-tab>
        </v-tabs>
        <v-card tile class="mt-2">
            <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
                :scroll="{ x: width, y: height }" row-key="_id">
                <template slot="title">
                    <div class="d-flex align-center pl-2">
                        <span>共 {{ length }} 页 / {{ count }} 条数据</span>
                        <v-menu bottom offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                                    {{ limit }}/页
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                                    <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template slot="pay_time" slot-scope="text, record">
                    {{ record.pay_time | dateFormat('YYYY-MM-DD HH:mm:ss') }}
                </template>
                <template slot="reback_time" slot-scope="text, record">
                    {{ record.reback_time | dateFormat('YYYY-MM-DD HH:mm:ss') }}
                </template>
                <template slot="action" slot-scope="text, record">
                    <a-space>
                        <v-btn text tile color="success" @click="reback(record)"> 退还订金 </v-btn>
                    </a-space>
                </template>
            </a-table>
            <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
        </v-card>
    </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
import UploadTask from "@/components/uploadtask.vue";
export default {
    name: "Subscription",
    data() {
        return {
            tab:"1",
            pages: [
                { text: '20/页', value: 20 },
                { text: '50/页', value: 50 },
                { text: '100/页', value: 100 },
                { text: '200/页', value: 200 },
            ],
            queryForm: { goodsID: "",},
            headers: [
                { title: "流水号", dataIndex: "index", scopedSlots: { customRender: "index" }, width: 80, align: "center" },
                { title: "货源ID", dataIndex: "goodsID", scopedSlots: { customRender: "goodsID" }, width: 80, align: "center" },
                { title: "司机", dataIndex: "drivername", scopedSlots: { customRender: "loadingsite" }, width: 150, align: "center" },
                { title: "车牌号", dataIndex: "carno", scopedSlots: { customRender: "typelength" }, width: 100, align: "center" },
                { title: "车型", dataIndex: "cartype", scopedSlots: { customRender: "freight" }, width: 100, align: "center" },
                { title: "手机号", dataIndex: "phone", scopedSlots: { customRender: "freight" }, width: 100, align: "center" },
                { title: "货主", dataIndex: "owner", scopedSlots: { customRender: "owner" }, width: 100, align: "center" },
                { title: "订金", dataIndex: "subscription", scopedSlots: { customRender: "subscription" }, width: 100, align: "center" },
                { title: "状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 100, align: "center" },
                { title: "备注", dataIndex: "mark", scopedSlots: { customRender: "mark" }, width: 100, align: "center" },
                { title: "支付时间", dataIndex: "pay_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
                { title: "退款时间", dataIndex: "reback_time", scopedSlots: { customRender: "create_time" }, width: 180, align: "center" },
                { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 100, align: "center", fixed: 'right' },
            ],
            page: 1,
            limit: 20,
            list: [],
            length: 0,
            count: 0,
            height: 600,
            model: false,
            modeltitle: "",
            vrules: {
                required: (value) => !!value || "不能为空",
            },
            date_menu: false,
        };
    },
    computed: {
        ...mapState(["role", "username"]),
        width() {
            return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
        },
    },
    components: { UploadTask },
    watch: {
        page() {
            this.getdata();
        },
        limit() {
            if (this.page !== 1) this.page = 1;
            else this.getdata();
        },
    },
    activated() {
        this.page = 1;
        this.list = [];
        this.length = 0;
        this.count = 0;
        this.getdata();
    },
    methods: {
        showupload() {
        },
        getdata() {

        },
        reback(_id) {
            this.$toast.loading({ message: "" });

        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .row {
    margin-bottom: 0 !important;
}

/deep/ .col-12 {
    padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    padding: 0 4px;
}

/deep/ p {
    margin: 0;
    padding: 0;
}
</style>
