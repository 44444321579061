<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2">
        <v-text-field v-model="driver_name" outlined dense label="司机姓名" hide-details clearable />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field v-model="contract_no" outlined dense label="合同编号" hide-details clearable />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field v-model="start_place" outlined dense label="出发地" hide-details clearable />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field v-model="end_place" outlined dense label="目的地" hide-details clearable />
      </v-col>
      <v-col cols="12" md="2" sm="2">
        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="sign_day"
          transition="scale-transition" min-width="auto" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="sign_day" label="签署日期" placeholder="签署日期" append-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on" dense outlined clearable hide-details>
              <template v-slot:label> 签署日期 </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="sign_day" no-title scrollable locale="zh-cn"
            @input="() => { $refs.menu1.save(sign_day); menu1 = false }"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2" sm="2">
        <v-btn color="primary" tile @click="getdata" style="width: 100%">查询</v-btn>
      </v-col>
    </v-row>
    <a-space class="mt-2 mb-2">
      <v-btn tile color="primary" :disabled="selected.length === 0" @click="downloadcontracts">
        下载电子合同
      </v-btn>
      <v-btn tile color="primary" outlined @click="getdata">刷新</v-btn>
    </a-space>
    <v-card>
      <v-data-table :headers="headers" :items="list" :items-per-page="limit" class="elevation-1" no-data-text="无数据"
        hide-default-footer disable-sort item-key="_id" show-select v-model="selected">
        <template v-slot:item.contract_duration="{ item }">
          <p>起：{{ item.start_day }}</p>
          <p>止：{{ item.end_day }}</p>
        </template>
        <template v-slot:item.line="{ item }">
          <p>{{ item.start_place }}->{{ item.end_place }}</p>
        </template>
        <template v-slot:item.contract_url="{ item }">
          <v-btn text x-small color="primary" @click="showcontract(item._id)"> 查看 </v-btn>
        </template>
        <template v-slot:item.accountInfo="{ item }">
          <div class="d-flex flex-column">
            <span>{{ item.bankcard_no }}</span>
            <span>{{ item.bankcard_host }}</span>
            <span>{{ item.bank_name }}</span>
          </div>
        </template>
        <template v-slot:item.signstate="{ item }">
          {{ item.signature ? "已签字" : "未签字" }}
        </template>
        <template v-slot:item.state="{ item }">
          {{ item.pdfurl ? "电子合同已生成" : "电子合同未生成" }}
        </template>
        <template v-slot:item.create_time="{ item }">
          {{ item.create_time | dateTime('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon v-if="!item.signature" small color="blue" class="mr-2" @click="editItem(item._id)">
            mdi-pencil
          </v-icon>
          <v-icon small color="orange" @click="deleteItem(item._id)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
  </v-container>
</template>

<script>
import _ from "underscore";
export default {
  name: "ContractList",
  data() {
    return {
      headers: [
        { text: "编号", value: "contract_no", width: 80 },
        { text: "姓名", value: "name", width: 80 },
        { text: "手机号", value: "phone", width: 100 },
        { text: "身份证", value: "certificate", width: 100 },
        { text: "车牌号", value: "carnumber", width: 120 },
        { text: "GPS登记号", value: "gpsnumber", width: 120 },
        { text: "合同有效期", value: "contract_duration", width: 200 },
        { text: "线路", value: "line", width: 120 },
        { text: "结算金额", value: "money", width: 100 },
        { text: "结算方式", value: "settlement_method", width: 120 },
        { text: "结算周期", value: "billing_cycle", width: 100 },
        { text: "合同地址", value: "contract_url", width: 100 },
        { text: "账户信息", value: "accountInfo", width: 100 },
        { text: "签字状态", value: "signstate", width: 80 },
        { text: "状态", value: "state", width: 150 },
        { text: "创建时间", value: "create_time", width: 180 },
        { text: "操作", value: "action", width: 100 },
      ],
      driver_name: "",
      contract_no: "",
      start_place: "",
      end_place: "",
      sign_day: "",
      menu1: false,
      page: 1,
      limit: 20,
      length: 0,
      selected: [],
      list: [],
    };
  },
  watch: {
    page() {
      this.getdata();
    }
  },
  activated() {
    this.page = 1;
    this.limit = 10;
    this.driver_name = "";
    this.contract_no = "";
    this.list = [];
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/contract/list", {
        driver_name: this.driver_name,
        contract_no: this.contract_no,
        start_place: this.start_place,
        end_place: this.end_place,
        sign_day: this.sign_day,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = res.data.list;
          this.length = res.data.length;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showcontract(id) {
      this.$downloadBrowser({ href: `/contracts/${id}` });
    },
    editItem(id) {
      this.$router.push({ path: `/contract/add/${id}` });
    },
    deleteItem(id) {
      this.$dialog.confirm({ title: "提示", message: "删除合同" }).then(() => {
        this.$toast.loading({ message: "" });
        this.$api.doAPI("/contract/remove", { id }).then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.getdata();
          } else {
            this.$alert(res.msg);
          }
        }).catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
      }).catch(() => { });
    },
    downloadcontracts() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/contract/download", { contractids: _.pluck(this.selected, "_id"), }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$utils.downloadZip(res.data.files, res.data.filename);
          this.selected = [];
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.op {
  padding: 10px;
  border-top: 1px solid #eee;
}

.op .v-btn {
  margin-right: 5px;
}

p {
  margin: 0;
}
</style>
