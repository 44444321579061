<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3" sm="6">
        <v-textarea v-model="queryForm.nos" outlined rows="4" label="发票号码"></v-textarea>
      </v-col>
      <v-col cols="12" md="9" sm="6">
        <v-row>
          <v-col cols="12" md="3" sm="6">
            <v-menu ref="month_menu" v-model="month_menu" :close-on-content-click="false"
              :return-value.sync="queryForm.month" transition="scale-transition" min-width="auto" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="queryForm.month" label="选择月份" placeholder="选择月份" append-icon="mdi-calendar"
                  readonly v-bind="attrs" v-on="on" dense outlined clearable>
                  <template v-slot:label>选择月份</template>
                </v-text-field>
              </template>
              <v-date-picker v-model="queryForm.month" type="month" no-title value="YYYY-mm" scrollable locale="zh-cn"
                @input="() => { $refs.month_menu.save(queryForm.month); month_menu = false; }"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-text-field v-model="queryForm.companyname" label="公司名称" dense outlined></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-autocomplete v-model="queryForm.state" :items="states" label="状态"
              :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-text-field v-model="queryForm.scope" label="所属日期关键字" dense outlined></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <a-space>
              <v-btn @click="getdata" color="cyan" dark tile>
                <v-icon small>mdi-magnify</v-icon>查询
              </v-btn>
              <v-btn @click="download" color="indigo" dark tile>
                <v-icon small>mdi-download</v-icon>下载销项票
              </v-btn>
              <v-btn @click="showadd" color="primary" dark tile>
                <v-icon small>mdi-plus</v-icon>新增销项票
              </v-btn>
            </a-space>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card>
      <a-table size="small" :columns="headers" :data-source="list" row-key="_id" :pagination="false" :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: width, y: height }">
        <template slot="title">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center pl-2">
              <span>共 {{ length }} 页 / {{ count }} 条数据</span>
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                    {{ limit }}/页
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                    <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <v-btn text tile color="success" @click="showedit(record)"> 编辑 </v-btn>
            <a-popconfirm title="删除销项票" ok-text="确定" cancel-text="取消" @confirm="remove(record._id)">
              <v-btn text tile color="error"> 删除 </v-btn>
            </a-popconfirm>
          </a-space>
        </template>
        <template slot="matched" slot-scope="text, record">
          <v-checkbox v-model="record.matched" :label="record.match_label" disabled></v-checkbox>
        </template>
        <template slot="fee_month_source" slot-scope="text, record">
          {{ record.fee_month }} {{ record.fee_source }}
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="model" persistent max-width="588px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            {{ modeltitle }}
            <v-icon @click="cancelsave">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu ref="invoice_month_menu" v-model="invoice_month_menu" :close-on-content-click="false"
                    :return-value.sync="ticketForm.month" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="ticketForm.month" :rules="[vrules.required]" label="月份" placeholder="选择月份"
                        append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          月份<span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="ticketForm.month" type="month" no-title value="YYYY-mm" scrollable
                      locale="zh-cn"
                      @input="() => { $refs.invoice_month_menu.save(ticketForm.month); invoice_month_menu = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu ref="invoice_day_menu" v-model="invoice_day_menu" :close-on-content-click="false"
                    :return-value.sync="ticketForm.day" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="ticketForm.day" :rules="[vrules.required]" label="开票日期" placeholder="选择日期"
                        append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          开票日期 <span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="ticketForm.day" :rules="[vrules.required]" type="date" no-title
                      value="YYYY-mm-DD" scrollable locale="zh-cn"
                      @input="() => { $refs.invoice_day_menu.save(ticketForm.day); invoice_day_menu = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="ticketForm.code" :rules="[vrules.required]" dense outlined>
                    <template v-slot:label>
                      发票代码 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="ticketForm.number" :rules="[vrules.required]" dense outlined>
                    <template v-slot:label>
                      发票号码 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete v-model="ticketForm.type" :items="types" :rules="[vrules.required]"
                    :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
                    <template v-slot:label>
                      类型<span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field v-model="ticketForm.companyname" :rules="[vrules.required]" dense outlined>
                    <template v-slot:label>
                      公司名称<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete v-model="ticketForm.state" :items="states" :rules="[vrules.required]"
                    :menu-props="{ bottom: true, offsetY: true }" no-data-text="无" dense outlined>
                    <template v-slot:label>
                      状态<span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="ticketForm.invoice_amount" :rules="[vrules.required]" type="number" dense
                    outlined>
                    <template v-slot:label>
                      开票金额<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="ticketForm.tax" :rules="[vrules.required]" type="number" dense outlined>
                    <template v-slot:label>
                      税额<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="ticketForm.notax_amount" :rules="[vrules.required]" type="number" dense outlined
                    readonly>
                    <template v-slot:label>
                      不含税金额<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu ref="invoice_month_menu2" v-model="invoice_month_menu2" :close-on-content-click="false"
                    :return-value.sync="ticketForm.fee_month" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="ticketForm.fee_month" :rules="[vrules.required]" label="费用月份"
                        placeholder="选择费用月份" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          费用月份<span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="ticketForm.fee_month" type="month" no-title value="YYYY-mm" scrollable
                      locale="zh-cn"
                      @input="() => { $refs.invoice_month_menu2.save(ticketForm.month); invoice_month_menu2 = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="ticketForm.fee_source" label="费用来源" dense outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="ticketForm.scope" label="所属日期" dense outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="ticketForm.remark" label="备注" dense outlined></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" @click="save" :disabled="!valid">提交</v-btn>
            <v-btn tile @click="cancelsave">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";

export default {
  name: "OutputBill",
  data() {
    return {
      pages: [
        { text: '20/页', value: 20 },
        { text: '50/页', value: 50 },
        { text: '100/页', value: 100 },
        { text: '200/页', value: 200 },
        // { text: '500/页', value: 500 },
        // { text: '1000/页', value: 1000 },
        // { text: '1500/页', value: 1500 },
      ],
      queryForm: {
        nos: "", month: "", companyname: "", state: "", scope: "",
      },
      month_menu: false,
      valid: true,
      headers: [
        { title: "月份", dataIndex: "month", scopedSlots: { customRender: "month" }, width: 120, align: "center" },
        { title: "发票代码", dataIndex: "code", scopedSlots: { customRender: "code" }, width: 150, align: "center" },
        { title: "开票日期", dataIndex: "day", scopedSlots: { customRender: "day" }, width: 120, align: "center" },
        { title: "发票号码", dataIndex: "number", scopedSlots: { customRender: "number" }, width: 150, align: "center" },
        { title: "类型", dataIndex: "type", scopedSlots: { customRender: "type" }, width: 100, align: "center" },
        { title: "公司名称", dataIndex: "companyname", scopedSlots: { customRender: "companyname" }, width: 200, align: "center" },
        { title: "发票状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 100, align: "center" },
        { title: "开票金额", dataIndex: "invoice_amount", scopedSlots: { customRender: "invoice_amount" }, width: 120, align: "center" },
        { title: "税额", dataIndex: "tax", scopedSlots: { customRender: "tax" }, width: 120, align: "center" },
        { title: "不含税金额", dataIndex: "notax_amount", scopedSlots: { customRender: "notax_amount" }, width: 120, align: "center" },
        { title: "费用月份+来源", dataIndex: "fee_month_source", scopedSlots: { customRender: "fee_month_source" }, width: 120, align: "center" },
        { title: "所属日期", dataIndex: "scope", scopedSlots: { customRender: "scope" }, width: 150, align: "center" },
        { title: "已匹配", dataIndex: "matched", scopedSlots: { customRender: "matched" }, width: 100, align: "center" },
        { title: "备注", dataIndex: "remark", scopedSlots: { customRender: "remark" }, width: 150, align: "center" },
        { title: "创建时间", dataIndex: "create_time", scopedSlots: { customRender: "create_time" }, width: 120, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 150, align: "center", fixed: 'right' },
      ],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      model: false,
      modeltitle: "",
      height: 600,
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      ticketTemplate: {
        _id: '',
        month: '', code: '', day: '', number: '', type: '', companyname: '', state: '',
        invoice_amount: '', tax: '', notax_amount: '', fee_month: '', fee_source: '', scope: '', remark: ''
      },
      ticketForm: {},
      invoice_month_menu: false,
      invoice_day_menu: false,
      invoice_month_menu2: false,
      states: [],
      types: [],
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
    ticketForm: {
      handler(form) {
        this.ticketForm.notax_amount = this.$d(form.invoice_amount || 0).minus(form.tax || 0).toString();
      },
      deep: true,
    },
  },
  mounted() {
    this.height = window.innerHeight - 380;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 380;
    };
  },
  activated() {
    this.page = 1;
    this.list = []
    this.length = 0;
    this.queryForm = {
      nos: "", month: "", companyname: "", scope: "",
    };
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/sale_ticket/list", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.list = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: this.$dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
          this.states = res.data.states;
          this.types = res.data.types;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showadd() {
      this.ticketForm = _.extend({}, { ...this.ticketTemplate });
      this.modeltitle = "新增进项票";
      this.model = true;
    },
    showedit(item) {
      this.ticketForm = _.pick(item, _.keys(this.ticketTemplate));
      this.modeltitle = "编辑进项票";
      this.model = true;
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/sale_ticket/save", { ticketForm: this.ticketForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.model = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelsave() {
      this.$refs.form.resetValidation();
      this.model = false;
    },
    remove(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/sale_ticket/remove", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    download() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/sale_ticket/list", { ...this.queryForm, action: 'download' }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .row+.row {
  margin-top: 0;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
