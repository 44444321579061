<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-text-field v-model="queryForm.departure" label="始发地" dense outlined clearable />
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-text-field v-model="queryForm.destination" label="目的地" dense outlined clearable />
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="6">
        <v-menu ref="month_menu" v-model="month_menu" :close-on-content-click="false" :return-value.sync="queryForm.month"
          transition="scale-transition" min-width="auto" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="queryForm.month" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense
              outlined clearable>
              <template v-slot:label>月份</template>
            </v-text-field>
          </template>
          <v-date-picker v-model="queryForm.month" type="month" no-title value="YYYY-mm" scrollable locale="zh-cn"
            @input="() => { $refs.month_menu.save(queryForm.month); month_menu = false; }"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="4" md="3" sm="6">
        <a-space>
          <v-btn color="primary" tile style="color:#fff;width:150px;" @click="gettask">
            <v-icon small>mdi-magnify</v-icon> 查询
          </v-btn>
          <v-btn color="error" tile style="color:#fff;width:150px;" @click="showcreate">
            <v-icon small>mdi-plus</v-icon> 新增
          </v-btn>
        </a-space>
      </v-col>
    </v-row>
    <v-card tile class="mt-2">
      <a-table size="small" :columns="headers" :data-source="list" :pagination="false" :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: width, y: height }" row-key="_id">
        <template slot="title">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center pl-2">
              <span>共 {{ length }} 页 / {{ count }} 条数据</span>
              <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small outlined v-bind="attrs" v-on="on" class="ml-2 grey lighten-3">
                    {{ limit }}/页
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in pages" @click="limit = item.value" :key="index">
                    <v-list-item-title>{{ item.value }}/页</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-btn color="primary" tile text @click="download">导出数据</v-btn>
          </div>
        </template>
        <template slot="postroute" slot-scope="text, record">
          {{ record.departure }} - {{ record.destination }}
        </template>
        <template slot="state" slot-scope="text, record">
          <a-tag v-if="record.state === 'error'" color="red">
            失败
          </a-tag>
          <a-tag v-if="record.state === 'fail'" color="#f50">
            失败
          </a-tag>
          <a-tag v-if="record.state === 'process'" color="#2db7f5">
            正在抓取数据
          </a-tag>
          <a-tag v-if="record.state === 'success'" color="#108ee9">
            正在汇总数据
          </a-tag>
          <a-tag v-if="record.state === 'finished'" color="#87d068">
            成功
          </a-tag>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a-popconfirm v-if="record.state === 'fail' || record.state === 'finished' || record.state === 'error'"
              title="重新汇总当月数据?" ok-text="确定" cancel-text="取消" @confirm="reset(record._id)">
              <a href="javascript:;" class="error--text">重新汇总</a>
            </a-popconfirm>
            <!-- <a href="javascript:;" @click="copy(record)">复制数据</a> -->
          </a-space>
        </template>
      </a-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="taskmodel" persistent width="500px">
      <v-form ref="taskform" v-model="taskvalid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            添加数据任务
            <v-icon @click="cancelcreate">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="taskform.departure" :rules="[vrules.required]" dense outlined>
                    <template v-slot:label>
                      始发地 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field v-model="taskform.destination" :rules="[vrules.required]" dense outlined>
                    <template v-slot:label>
                      目的地 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-menu ref="task_start_month" v-model="task_start_month" :close-on-content-click="false"
                    :return-value.sync="taskform.start_month" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="taskform.start_month" :rules="[vrules.required]" append-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          开始月份 <span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="taskform.start_month" type="month" no-title value="YYYY-mm" scrollable
                      locale="zh-cn"
                      @input="() => { $refs.task_start_month.save(taskform.start_month); task_start_month = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-menu ref="task_end_month" v-model="task_end_month" :close-on-content-click="false"
                    :return-value.sync="taskform.end_month" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="taskform.end_month" :rules="[vrules.required]" append-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          结束月份 <span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="taskform.end_month" type="month" no-title value="YYYY-mm" scrollable
                      locale="zh-cn"
                      @input="() => { $refs.task_end_month.save(taskform.end_month); task_end_month = false; }"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" :disabled="!taskvalid" @click="createtask">保存</v-btn>
            <v-btn tile @click="cancelcreate">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
import UploadTask from "@/components/uploadtask.vue";
import { message } from "ant-design-vue";
export default {
  name: "VehicleList",
  data() {
    return {
      month_menu: false,
      queryForm: { departure: "", destination: "", month: "" },
      pages: [
        { text: '20/页', value: 20 },
        { text: '50/页', value: 50 },
        { text: '100/页', value: 100 },
        // { text: '200/页', value: 200 },
      ],
      headers: [
        { title: "邮路名称", dataIndex: "postroute", scopedSlots: { customRender: "postroute" }, width: 180, align: "center" },
        { title: "发车时间", dataIndex: "month", scopedSlots: { customRender: "month" }, width: 120, align: "center" },
        { title: "12吨", dataIndex: "t12", scopedSlots: { customRender: "t12" }, width: 120, align: "center" },
        { title: "30吨", dataIndex: "t30", scopedSlots: { customRender: "t30" }, width: 120, align: "center" },
        { title: "合计", dataIndex: "total", scopedSlots: { customRender: "total" }, width: 120, align: "center" },
        { title: "状态", dataIndex: "state", scopedSlots: { customRender: "state" }, width: 120, align: "center" },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" }, width: 200, align: "center" },
      ],
      page: 1,
      limit: 20,
      list: [],
      length: 0,
      count: 0,
      height: 600,
      vrules: {
        required: (value) => !!value || "不能为空",
      },
      taskform: {},
      taskmodel: false,
      taskvalid: true,
      task_start_month: false,
      task_end_month: false,
    };
  },
  computed: {
    ...mapState(["role", "username"]),
    width() {
      return _.reduce(this.headers, (a, b) => a + (b.width || 300), 0);
    },
  },
  components: { UploadTask },
  watch: {
    page() {
      this.gettask();
    },
    limit() {
      if (this.page !== 1) this.page = 1;
      else this.gettask();
    },
  },
  mounted() {
    this.height = window.innerHeight - 400;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 400;
    };
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.count = 0;
    this.queryForm = {
      departure: "", destination: "", month: "",
    }
    this.gettask();
  },
  methods: {
    showcreate() {
      this.taskform = {
        departure: "", destination: "", start_month: "", end_month: "",
      };
      this.taskmodel = true;
    },
    cancelcreate() {
      this.$refs.taskform.resetValidation();
      this.taskmodel = false;
    },
    createtask() {
      if (!this.$refs.taskform.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/waybill_createtask", { ...this.taskform }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.gettask();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    gettask() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/waybill_tasklist", { page: this.page, limit: this.limit, ...this.queryForm }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.list = _.map(res.data.list, n => {
            let t12 = n.data?.['12'] || 0;
            let t30 = n.data?.['30'] || 0;
            let total = t12 + t30;
            return {
              ...n, t12, t30, total,
              create_time: this.$dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
              update_time: n.update_time ? this.$dateFormat(n.update_time, 'YYYY-MM-DD HH:mm:ss') : "",
            }
          });
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    download() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/waybill_download", { ...this.queryForm }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$download(res.data.url, res.data.name);
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    reset(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/cptmcp/waybill_resettask", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.gettask();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    copyall() {
      let that = this;
      let data = [
        [
          ['邮路名称', '发车时间', '12吨', '30吨', '合计'].join('\t')
        ]
      ];
      for (let i = 0; i < this.list.length; i++) {
        let record = this.list[i];
        let text = [`${record.departure}-${record.destination}`, record.month, record.t12, record.t30, record.total].join('\t');
        data.push(text);
      }
      this.$copyText(data.join('\n')).then(
        function (e) {
          that.$toast.success("复制成功");
          console.log(e);
        },
        function (e) {
          console.log(e);
        }
      );
    },
    copy(record) {
      let that = this;
      let text = [`${record.departure}-${record.destination}`, record.month, record.t12, record.t30, record.total].join('\t');
      this.$copyText(text).then(
        function (e) {
          that.$toast.success("复制成功");
          console.log(e);
        },
        function (e) {
          console.log(e);
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
