<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="2" md="3">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="month"
          transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="month" label="月份" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense
              outlined :disabled="loading"></v-text-field>
          </template>
          <v-date-picker v-model="month" no-title type="month" value="YYYY-mm" scrollable locale="zh-cn"
            @input="() => { $refs.menu.save(month); menu = false; }"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="2" md="3">
        <v-btn tile color="primary" @click="refresh" :loading="loading" style="width:100%;">刷新</v-btn>
      </v-col>
    </v-row>
    <v-textarea v-model="logtxt" label="日志" :rows="rows" readonly outlined></v-textarea>
  </v-container>
</template>
<script>
import _ from "underscore";
export default {
  name: "RefreshReceivePay",
  data() {
    return {
      menu: false,
      month: "",
      loading: false,
      logs: [],
      rows: 30,
    };
  },
  computed: {
    logtxt() {
      return _.map(this.logs, n => `[${this.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss')}] ${n.content}`).join('\n');
    },
  },
  components: {},
  watch: {},
  mounted() {
    let height = window.innerHeight - 150;
    this.rows = Math.ceil(height / 30);
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      let height = window.innerHeight - 150;
      this.rows = Math.ceil(height / 30);
    };
  },
  methods: {
    refresh() {
      if (!this.month) return;
      this.logs = [];
      this.loading = true;
      this.$api.doAPI("/receivepay/refresh", { month: this.month }).then((res) => {
        if (res.code === 0) {
          let { state, time } = res.data;
          if (state === 'processing') {
            this.load(time);
          } else {
            this.loading = false;
          }
        } else {
          this.$alert(res.msg);
          this.loading = false;
        }
      }).catch((err) => {
        this.loading = false;
        console.error(err);
      });
    },
    load(time) {
      this.$api.doAPI("/receivepay/load", { month: this.month, time }).then((res) => {
        if (res.code === 0) {
          let { state, logs, time } = res.data;
          this.logs = [...logs.reverse(), ...this.logs]
          if (state === 'processing') {
            setTimeout(() => {
              this.load(time);
            }, 1500);
          } else {
            this.loading = false;
          }
        } else {
          this.$alert(res.msg);
          this.loading = false;
        }
      }).catch((err) => {
        this.loading = false;
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped></style>