<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3" sm="3">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4">
              账户设置
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="username" label="账号" disabled dense outlined />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="name" label="姓名" placeholder="输入姓名" :rules="[vrules.required]" dense outlined
                    persistent-placeholder />
                </v-col>
                <v-col cols="12">
                  <v-select :items="allroles" v-model="role" label="角色" disabled dense outlined
                    :menu-props="{ bottom: true, offsetY: true }" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" class="ml-4 mb-4" tile :disabled="!valid" @click="saveuserinfo">
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" md="3" sm="3">
        <v-form ref="form2" v-model="valid2" lazy-validation>
          <v-card>
            <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4">
              修改密码
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="newpwd" label="新密码" placeholder="输入新密码"
                    :rules="[vrules.required, vrules.password]" dense outlined persistent-placeholder />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="newpwd2" label="重复密码" placeholder="请再次输入新密码"
                    :rules="[vrules.required, vrules.password]" dense outlined persistent-placeholder />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" class="ml-4 mb-4" tile :disabled="!valid2" @click="changepwd">
                修改
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
      <!-- <v-col cols="12" md="3" sm="3">
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4">
            绑定微信
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="status === 0" cols="12">
                <v-img width="235" :src="img"> </v-img>
              </v-col>
              <v-col v-if="status === 1" cols="12">
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img v-if="headimgurl" :src="headimgurl" />
                      <v-avatar v-else-if="nickname" size="36" color="primary">
                        <span class="white--text headline">
                          {{ nickname.substr(0, 1) }}
                        </span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ nickname }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="status === 0"
              color="primary"
              class="ml-4 mb-4"
              @click="getuserinfo"
            >
              刷新
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "UserInfo",
  data: () => ({
    valid: true,
    valid2: true,
    username: "",
    name: "",
    role: "",
    status: -1,
    uuid: "",
    img: "",
    nickname: "",
    headimgurl: "",
    vrules: {
      required: (value) => !!value || "不能为空",
      password: (value) => (value.length >= 6 && value.length <= 20) || "密码长度6-20位",
    },
    newpwd: "",
    newpwd2: "",
  }),
  components: {},
  computed: {
    ...mapState(["allroles"]),
  },
  watch: {},
  activated() {
    this.getuserinfo();
  },
  methods: {
    getuserinfo() {
      this.$api.doAPI("/userinfo").then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$utils.setStoreAdmin(res.data);
          this.username = res.data.username;
          this.name = res.data.name;
          this.role = res.data.role;
          this.status = res.data.status;
          this.uuid = res.data.uuid;
          this.img = res.data.img;
          this.nickname = res.data.nickname;
          this.headimgurl = res.data.headimgurl;
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    saveuserinfo() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/saveuser", { name: this.name }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getuserinfo();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    changepwd() {
      if (!this.$refs.form2.validate()) return;
      if (this.newpwd !== this.newpwd2) {
        return this.$alert("两次输入的新密码不一致");
      }
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/changepwd", { newpwd: this.newpwd }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.$alert("密码已修改，请重新登录").then(() => {
            localStorage.clear();
            this.$router.replace({ path: "/login" }).catch(console.error);
          });
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
