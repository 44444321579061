<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-7" cols="12" md="3" lg="3" sm="6">
        <v-card>
          <v-card-text class="pa-5">
            <div class="d-flex align-center">
              <v-btn class="elevation-0" color="info" fab rounded dark>
                <v-icon>mdi-wallet</v-icon>
              </v-btn>
              <div class="ml-2 mr-1">
                <h2 class="headline font-weight-light">1,249</h2>
                <h5 class="subtitle-2 font-weight-regular">本月运单</h5>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-7" cols="12" md="3" lg="3" sm="6">
        <v-card>
          <v-card-text class="pa-5">
            <div class="d-flex align-center">
              <v-btn class="elevation-0" color="warning" fab rounded dark>
                <v-icon>mdi-cart-outline</v-icon>
              </v-btn>
              <div class="ml-2 mr-1">
                <h2 class="headline font-weight-light">$3249</h2>
                <h5 class="subtitle-2 font-weight-regular">本月运费</h5>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-7" cols="12" md="3" lg="3" sm="6">
        <v-card>
          <v-card-text class="pa-5">
            <div class="d-flex align-center">
              <v-btn class="elevation-0" color="deep-purple" fab rounded dark>
                <v-icon>mdi-currency-usd</v-icon>
              </v-btn>
              <div class="ml-2 mr-1">
                <h2 class="headline font-weight-light">$3249</h2>
                <h5 class="subtitle-2 font-weight-regular">本月成本</h5>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-7" cols="12" md="3" lg="3" sm="6">
        <v-card>
          <v-card-text class="pa-5">
            <div class="d-flex align-center">
              <v-btn class="elevation-0" color="error" fab rounded dark>
                <v-icon>mdi-cash-multiple</v-icon>
              </v-btn>
              <div class="ml-2 mr-1">
                <h2 class="headline font-weight-light">$3249</h2>
                <h5 class="subtitle-2 font-weight-regular">本月毛利</h5>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-row>
            <v-col cols="12" sm="4" lg="6">
              <div class="pa-5">
                <h3 class="text-truncate font-weight-regular">数据统计分析</h3>
                <h6 class="body-2 mb-0 blue-grey--text text--lighten-2 font-weight-light">数据来源于近6个月</h6>
              </div>
            </v-col>
            <v-col cols="12" sm="4" lg="3">
              <div class="d-flex align-items-center pa-5">
                <div >
                  <h3 class="font-weight-regular">31,568</h3>
                  <h5 class="blue-grey--text text--lighten-2 font-weight-regular subtitle-1">路单总数量</h5>
                </div>
                <div class="spacer"></div>
                <div class="ml-auto">
                  <v-btn class="elevation-0" fab rounded dark color="success">
                    <v-icon>mdi-chart-line</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="4" lg="3">
              <div class="d-flex align-items-center py-5 px-sm-5 pa-5">
                <div>
                  <h3 class="font-weight-regular">$1504788</h3>
                  <h5 class="blue-grey--text text--lighten-2 font-weight-regular subtitle-1">总运费</h5>
                </div>
                <div class="spacer"></div>
                <div class="ml-auto">
                  <v-btn class="elevation-0" fab rounded dark color="blue-grey">
                    <v-icon>mdi-chart-bar</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-card-text>
            <div style="min-height: 315px; width: 100%; position: relative">
              <apexchart v-if="lineSeries.length > 0" type="line" height="315" :options="lineChartOptions"
                :series="lineSeries" style="height: 300px; max-width: 1620px; position: relative" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" lg="8" sm="6">
        <v-card class="mb-7">
          <v-card-text class="pa-5">
            <h3 class="title font-weight-regular">邮局数据统计</h3>
            <div style="min-height: 315; width: 100%; position: relative">
              <apexchart v-if="barSeries.length > 0" type="bar" height="270" :options="barChartOptions"
                :series="barSeries" style="height: 260px; max-width: 1620px; position: relative" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="6">
        <v-card class="mb-7">
          <v-card-text class="pa-5">
            <h3 class="title font-weight-regular">邮局TOP5</h3>
            <div class="d-flex align-center mt-9">
              <div class="mx-2">郑州</div>
              <div class="spacer"></div>
              <div class="ml-auto">
                <span class="ma-2 v-chip v-chip--label theme--light v-size--small light-blue lighten-5 info--text">
                  <span class="v-chip__content">35%</span>
                </span>
              </div>
            </div>
            <div class="d-flex align-center mt-9">
              <div class="mx-2">杭州</div>
              <div class="spacer"></div>
              <div class="ml-auto">
                <span class="ma-2 v-chip v-chip--label theme--light v-size--small light-blue lighten-5 info--text">
                  <span class="v-chip__content">23%</span>
                </span>
              </div>
            </div>
            <div class="d-flex align-center mt-9">
              <div class="mx-2">苏州</div>
              <div class="spacer"></div>
              <div class="ml-auto">
                <span class="ma-2 v-chip v-chip--label theme--light v-size--small light-blue lighten-5 info--text">
                  <span class="v-chip__content">23%</span>
                </span>
              </div>
            </div>
            <div class="d-flex align-center mt-9">
              <div class="mx-2">南阳</div>
              <div class="spacer"></div>
              <div class="ml-auto">
                <span class="ma-2 v-chip v-chip--label theme--light v-size--small light-blue lighten-5 info--text">
                  <span class="v-chip__content">23%</span>
                </span>
              </div>
            </div>
            <div class="d-flex align-center mt-9">
              <div class="mx-2">贵州</div>
              <div class="spacer"></div>
              <div class="ml-auto">
                <span class="ma-2 v-chip v-chip--label theme--light v-size--small light-blue lighten-5 info--text">
                  <span class="v-chip__content">23%</span>
                </span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" lg="8" sm="6">
        <v-card class="mb-7">
          <v-card-text class="pa-5">
            <h3 class="title font-weight-regular">承运商数据统计</h3>
            <div style="min-height: 315; width: 100%; position: relative">
              <apexchart v-if="polarAreaSeries.length > 0" type="polarArea" height="280" :options="polarAreaOptions"
                :series="polarAreaSeries" style="height: 260px; max-width: 1620px; position: relative" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="6">
        <v-card class="mb-7">
          <v-card-text class="pa-5">
            <h3 class="title font-weight-regular">承运商TOP5</h3>
            <div class="d-flex align-center mt-9">
              <div class="mx-2">8T</div>
              <div class="spacer"></div>
              <div class="ml-auto">
                <span class="ma-2 v-chip v-chip--label theme--light v-size--small light-blue lighten-5 info--text">
                  <span class="v-chip__content">35%</span>
                </span>
              </div>
            </div>
            <div class="d-flex align-center mt-9">
              <div class="mx-2">10T</div>
              <div class="spacer"></div>
              <div class="ml-auto">
                <span class="ma-2 v-chip v-chip--label theme--light v-size--small light-blue lighten-5 info--text">
                  <span class="v-chip__content">23%</span>
                </span>
              </div>
            </div>
            <div class="d-flex align-center mt-9">
              <div class="mx-2">12T</div>
              <div class="spacer"></div>
              <div class="ml-auto">
                <span class="ma-2 v-chip v-chip--label theme--light v-size--small light-blue lighten-5 info--text">
                  <span class="v-chip__content">23%</span>
                </span>
              </div>
            </div>
            <div class="d-flex align-center mt-9">
              <div class="mx-2">15T</div>
              <div class="spacer"></div>
              <div class="ml-auto">
                <span class="ma-2 v-chip v-chip--label theme--light v-size--small light-blue lighten-5 info--text">
                  <span class="v-chip__content">23%</span>
                </span>
              </div>
            </div>
            <div class="d-flex align-center mt-9">
              <div class="mx-2">18T</div>
              <div class="spacer"></div>
              <div class="ml-auto">
                <span class="ma-2 v-chip v-chip--label theme--light v-size--small light-blue lighten-5 info--text">
                  <span class="v-chip__content">23%</span>
                </span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { Empty } from "vant";
Vue.use(Empty);
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
  name: "Home",
  data() {
    return {
      lineSeries: [
        { name: "路单", data: [69, 91, 148, 100, 100, 100, 230, 201, 300, 340, 440, 210] },
        { name: "运费", data: [669, 691, 708, 800, 1000, 1500, 1800, 2000, 2300, 3900, 4000, 3600] }
      ],
      lineChartOptions: {
        chart: { height: 300, type: "line", zoom: { enabled: false } },
        dataLabels: { enabled: false },
        stroke: { curve: "straight" },
        title: { text: "主要业务数据按月份统计", align: "left" },
        grid: { row: { colors: ["#f3f3f3", "transparent"], opacity: 0.5 } },
        xaxis: { categories: [] }
      },
      barSeries: [{ name: "", data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31] }],
      barChartOptions: {
        annotations: {
          points: [
            {
              x: "Bananas",
              seriesIndex: 0,
              label: {
                borderColor: "#775DD0",
                offsetY: 0,
                style: { color: "#fff", background: "#775DD0" },
                text: "Bananas are good"
              }
            }
          ]
        },
        chart: { height: 350, type: "bar" },
        plotOptions: {
          bar: { borderRadius: 10, columnWidth: "50%" }
        },
        dataLabels: { enabled: false },
        stroke: { width: 2 },
        grid: { row: { colors: ["#fff", "#f2f2f2"] } },
        xaxis: {
          labels: { rotate: -45 },
          categories: ["郑州", "杭州", "南阳", "宁波", "南京", "无锡", "三门峡", "洛阳", "开封", "周口"],
          tickPlacement: "on"
        },
        yaxis: { title: { text: "覆盖地市" } },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          }
        }
      },
      polarAreaSeries: [14, 23, 21, 17, 15, 10, 12, 17, 21],
      polarAreaOptions: {
        chart: { type: "polarArea" },
        stroke: { colors: ["#fff"] },
        fill: { opacity: 0.8 },
        responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: "bottom" } } }]
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    statistics() {
      this.lineChartOptions.title.text = "主要业务数据按月份统计";
      this.lineChartOptions.xaxis.categories = ["2020-10", "2020-11", "2020-12", "2021-01", "2021-02", "2021-03", "2021-04", "2021-05", "2021-06", "2021-07", "2021-08", "2021-09"];
      this.lineSeries = [
        {
          name: "路单",
          data: [69, 91, 148, 100, 100, 100, 230, 201, 300, 340, 440, 210]
        },
        {
          name: "运费",
          data: [669, 691, 708, 800, 1000, 1500, 1800, 2000, 2300, 3900, 4000, 3600]
        }
      ];
    }
  },
  mounted() {
    console.log("Home-mounted");
  },
  activated() {
    this.statistics();
  }
};
</script>
<style lang="less" scoped>
.v-application .mt-9 {
  margin-top: 18px !important;
}
</style>
