import axios from 'axios'
import { Dialog, Toast } from "vant";
import { message } from "ant-design-vue";
const instance = axios.create({
  baseURL: process.env.BASE_API,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
});
instance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('lidi.token')}`;
  return config;
}, error => {
  return Promise.reject(error);
})
//响应拦截器
instance.interceptors.response.use(response => {
  if (response.data.code === 'nopower') {
    Toast.clear();
    Dialog.alert({ title: "提示", message: response.data.msg }).then(() => {
      if (response.data.data === '/login') localStorage.clear();
      window._doRouterPush(response.data.data);
    });
    return Promise.reject(new Error(response.data.msg));
  } else if (response.data.code === 'refresh') {
    Toast.clear();
    Dialog.alert({ title: "提示", message: response.data.msg }).then(() => {
      window.location.href = window.location.href;
    });
    return Promise.reject(new Error(response.data.msg));
  }
  return response;
}, error => {
  message.error(error.message);
  return Promise.reject(error);
});
// 封装get方法
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params: params }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    })
  })
}
// 封装post方法
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function upload(formData, onUploadProgress = (progressEvent) => {
  console.log(progressEvent.loaded, progressEvent.total);
}) {
  return new Promise((resolve, reject) => {
    instance.post(`/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('lidi.token')}`,
      }, onUploadProgress
    }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function doUpload(url, formData, onUploadProgress = (progressEvent) => {
  console.log(progressEvent.loaded, progressEvent.total);
}) {
  return new Promise((resolve, reject) => {
    instance.post('/api' + url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('lidi.token')}`,
      }, onUploadProgress
    }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function doAPI(url, Data = {}) {
  return post('/api' + url, Data);
}
export function doAPI2(url, Data = {}) {
  return post(url, Data);
}
export default {
  get, post, upload, doUpload, doAPI, doAPI2,
};
