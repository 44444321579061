import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import store from "../store";
import api from "../request";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});

import WorkBench from "@/views/WorkBench.vue";
import Daily from "@/views/daily.vue";
import Login from "@/views/Login.vue";
import MPLogin from "@/views/MPLogin.vue";
import CarrierQualityData from "@/views/carrierqualitydata.vue";
import ProvinceCarrierQualityData from "@/views/provincecarrierqualitydata.vue";
import QualityData from "@/views/qualitydata.vue";
import NotFound from "@/views/404.vue";

import PostOfficeList from "@/views/postoffice/list.vue";
import PostOfficeOilPrice from "@/views/postoffice/oilprice.vue";
import PostOfficeCarratio from "@/views/postoffice/carratio.vue";

import CarrierList from "@/views/carrier/list.vue";

import PostLineList from "@/views/postline/list.vue";
import PostLineCreate from "@/views/postline/create.vue";

import OrdersList from "@/views/orders/list.vue";
import OrdersRoadBill from "@/views/orders/roadbill.vue";

import TransportStatistics from "@/views/statistics/transport.vue";
import OrdersGrabData from "@/views/orders/grabdata.vue";

import VehicleList from "@/views/vehicle/provincevehicle.vue";
import VehicleBlock from "@/views/vehicle/block.vue";
import VehicleMonit from "@/views/vehicle/monit.vue";

import ContractList from "@/views/contract/list.vue";
import ContractAdd from "@/views/contract/add.vue";
import ContractInfo from "@/views/info.vue";

import SourceGoods from "@/views/sourcegoods/list.vue";
import Owner from "@/views/sourcegoods/owner.vue";
import Subscription from "@/views/sourcegoods/subscription.vue";
///财务管理
import FincanceUpBill from "@/views/finance/upbill.vue";
import FincanceDownBill from "@/views/finance/downbill.vue";
import FincanceSelfBill from "@/views/finance/selfbill.vue";
import FincanceSelfBillChart from "@/views/finance/selfbillchart.vue";
import FincanceReport from "@/views/finance/report.vue";
import IncomeBill from "@/views/finance/incomebill.vue";
import OutputBill from "@/views/finance/outputbill.vue";
import RefreshReceivePay from "@/views/finance/refreshreceivepay.vue";
import Receivable from "@/views/finance/receivable.vue";
import Payable from "@/views/finance/payable.vue";
import SelfPayable from "@/views/finance/selfpayable.vue";
import Managedaily from "@/views/finance/managedaily.vue";

///结算管理
import SettleSummary from "@/views/settle/settle_summary.vue";
import SettleItems from "@/views/settle/settle_items.vue";
//!系统管理
//账户管理
import system_account from "@/views/system/account.vue";
//系统日志
import system_log from "@/views/system/log.vue";
//系统设置
import system_setting from "@/views/system/setting.vue";
//!个人中心
//账户设置
import user_setting from "@/views/user/setting.vue";

const routes = [
  { path: "/", redirect: "/daily" },
  // //工作台
  // {
  //   path: "/workbench",
  //   component: WorkBench,
  //   meta: { layout: "basic-layout", title: "利滴物流大数据" },
  // },
  ///每日数据统计
  {
    path: "/daily",
    component: Daily,
    meta: { layout: "basic-layout", title: "每日数据统计" },
  },
  //邮局管理
  //邮局列表
  {
    path: "/postoffice/list",
    component: PostOfficeList,
    meta: { layout: "basic-layout", title: "邮局列表" },
  },
  //油价联动
  {
    path: "/postoffice/oilprice",
    component: PostOfficeOilPrice,
    meta: { layout: "basic-layout", title: "油价联动" },
  },
  //车辆比例系数
  {
    path: "/postoffice/carratio",
    component: PostOfficeCarratio,
    meta: { layout: "basic-layout", title: "车辆比例系数" },
  },
  //线路信息管理
  {
    path: "/postline/list",
    component: PostLineList,
    meta: { layout: "basic-layout", title: "线路管理" },
  },
  //添加线路
  {
    path: "/postline/create",
    component: PostLineCreate,
    meta: { layout: "basic-layout", title: "新增线路" },
  },

  //承运商信息管理
  {
    path: "/carrier/list",
    component: CarrierList,
    meta: { layout: "basic-layout", title: "承运商管理" },
  },
  //添加承运商
  // {
  //   path: "/carrier/add",
  //   component: CarrierAdd,
  //   meta: { layout: "basic-layout", title: "新增承运商" },
  // },

  //运单管理
  //运单列表
  {
    path: "/orders/list",
    component: OrdersList,
    meta: { layout: "basic-layout", title: "路单列表" },
  },
  ///路单汇总
  {
    path: "/orders/grabdata",
    component: OrdersGrabData,
    meta: { layout: "basic-layout", title: "路单汇总" },
  },
  //路单抓取
  {
    path: "/orders/roadbill",
    component: OrdersRoadBill,
    meta: { layout: "basic-layout", title: "路单抓取" },
  },
  //车管平台数据统计
  //运输发班统计
  {
    path: "/statistics/transport",
    component: TransportStatistics,
    meta: { layout: "basic-layout", title: "运输发班统计" },
  },
  //车辆信息管理
  //车辆列表
  {
    path: "/vehicle/list",
    component: VehicleList,
    meta: { layout: "basic-layout", title: "车辆列表" },
  },
  //车辆黑名单
  {
    path: "/vehicle/block",
    component: VehicleBlock,
    meta: { layout: "basic-layout", title: "车辆黑名单" },
  },
  //车辆监控
  {
    path: "/vehicle/monit",
    component: VehicleMonit,
    meta: { layout: "basic-layout", title: "车辆监控" },
  },
  //电子合同管理
  //电子合同列表
  {
    path: "/contract/list",
    component: ContractList,
    meta: { layout: "basic-layout", title: "电子合同列表" },
  },
  //添加电子合同
  {
    path: "/contract/add/:id?",
    component: ContractAdd,
    meta: { layout: "basic-layout", title: "添加电子合同" },
  },
  //电子合同
  {
    path: "/contracts/:id?",
    component: ContractInfo,
    meta: { layout: "empty-layout", requireAuth: false, storeable: false },
  },
  ///货源发布管理
  {
    path: "/sourcegoods/list",
    component: SourceGoods,
    meta: { layout: "basic-layout", title: "发布货源" },
  },
  //货主管理
  {
    path: "/sourcegoods/owner",
    component: Owner,
    meta: { layout: "basic-layout", title: "货主管理" },
  },
  //订金管理
  {
    path: "/sourcegoods/subscription",
    component: Subscription,
    meta: { layout: "basic-layout", title: "订金管理" },
  },

  ///财务管理
  //账单管理
  //上游账单
  {
    path: "/finance/upbill",
    component: FincanceUpBill,
    meta: { layout: "basic-layout", title: "上游账单管理" },
  },
  //下游账单
  {
    path: "/finance/downbill",
    component: FincanceDownBill,
    meta: { layout: "basic-layout", title: "下游账单管理" },
  },
  //自营账单
  {
    path: "/finance/selfbill",
    component: FincanceSelfBill,
    meta: { layout: "basic-layout", title: "自营账单管理" },
  },
  //调车价格
  {
    path: "/finance/selfbillchart",
    component: FincanceSelfBillChart,
    meta: { layout: "basic-layout", title: "调车价格" },
  },
  //报表管理
  {
    path: "/finance/report",
    component: FincanceReport,
    meta: { layout: "basic-layout", title: "财务报表管理" },
  },
  //进项票管理
  {
    path: "/finance/incomebill",
    component: IncomeBill,
    meta: { layout: "basic-layout", title: "进项票管理" },
  },
  //销项票管理
  {
    path: "/finance/outputbill",
    component: OutputBill,
    meta: { layout: "basic-layout", title: "销项票管理" },
  },
  //刷新应收应付
  {
    path: "/finance/refreshreceivepay",
    component: RefreshReceivePay,
    meta: { layout: "basic-layout", title: "应收管理" },
  },
  //应收管理
  {
    path: "/finance/receivable",
    component: Receivable,
    meta: { layout: "basic-layout", title: "应收管理" },
  },
  //应付管理
  {
    path: "/finance/payable",
    component: Payable,
    meta: { layout: "basic-layout", title: "应付管理" },
  },
  //应付(自营)管理
  {
    path: "/finance/selfpayable",
    component: SelfPayable,
    meta: { layout: "basic-layout", title: "应付(自营)管理" },
  },
  //经营日报
  {
    path: "/finance/managedaily",
    component: Managedaily,
    meta: { layout: "basic-layout", title: "经营日报" },
  },
   ///结算管理---------------
   //结算账单汇总
   {
    path: "/settle/summary",
    component: SettleSummary,
    meta: { layout: "basic-layout", title: "结算账单汇总" },
  },
  //结算账单明细
  {
    path: "/settle/items",
    component: SettleItems,
    meta: { layout: "basic-layout", title: "结算账单明细" },
  },
  //!系统设置
  //账户管理
  {
    path: "/system/account",
    component: system_account,
    meta: { layout: "basic-layout", title: "账户管理", storeable: false },
  },
  //系统日志
  {
    path: "/system/log",
    component: system_log,
    meta: { layout: "basic-layout", title: "系统日志", storeable: false },
  },
  //系统设置
  {
    path: "/system/setting",
    component: system_setting,
    meta: { layout: "basic-layout", title: "系统设置", storeable: false },
  },
  //!个人中心
  //账户设置
  {
    path: "/user/setting",
    component: user_setting,
    meta: { layout: "basic-layout", title: "账户设置", storeable: false },
  },
  //登录
  {
    path: "/login",
    component: MPLogin,
    meta: { layout: "empty-layout", requireAuth: false, storeable: false },
  },
  //质量数据
  {
    path: "/qualitydata",
    component: QualityData,
    meta: { layout: "empty-layout", requireAuth: false, storeable: false },
  },
  {
    path: "/carrierqualitydata",
    component: ProvinceCarrierQualityData,
    meta: { layout: "empty-layout", requireAuth: false, storeable: false },
  },
  {
    path: "/provincecarrierqualitydata",
    component: ProvinceCarrierQualityData,
    meta: { layout: "empty-layout", requireAuth: false, storeable: false },
  },
  //404
  {
    path: "/404",
    component: NotFound,
    meta: { layout: "empty-layout", requireAuth: false, storeable: false },
  },
  { path: "*", redirect: "/404" },
];

const router = new Router({
  mode: "history",
  routes,
  base: "/",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) store.dispatch("SetNavTitle", to.meta.title);
  // console.log(to, from);
  store.dispatch("SetNavLogo", "");
  // 每次切换页面时，调用进度条
  NProgress.start();
  let v = localStorage.getItem("lidi.version") || "0";
  api.get(`/api/init?t=${Date.now()}&v=${v}`).then((data) => {
    // console.log(data);
    if (data.code === "newversion") {
      localStorage.setItem("lidi.version", data.version);
      NProgress.done();
      window.location.href = to.fullPath;
      return;
    }
    setTimeout(() => {
      let { userid, username, name, role, needlogin } = data;
      if (userid) {
        localStorage.setItem("lidi.userid", userid);
        store.dispatch("SetUserid", userid);
      }
      if (username) {
        localStorage.setItem("lidi.username", username);
        store.dispatch("SetUsername", username);
      }
      if (name) {
        localStorage.setItem("lidi.name", name);
        store.dispatch("SetName", name);
      }
      if (role) {
        localStorage.setItem("lidi.role", role);
        store.dispatch("SetRole", role);
      }
      if (needlogin) localStorage.clear();
      if (!localStorage.getItem("lidi.token") && to.meta && to.meta.requireAuth !== false) {
        return next({ path: "/login" });
      }
      if (localStorage.getItem("lidi.token") && to.path === "/login") {
        return next({ path: "/daily" });
      }
      if (to.meta.storeable !== false) store.dispatch("OpenTab", { path: to.path, name: to.meta.title });
      next();
    }, 0);
  });
});
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done();
});
window._doRouterPush = (path) => {
  if (router.currentRoute.path === path) return;
  router.push({ path }).catch(console.error);
};
window._doRouterReplace = (path) => {
  if (router.currentRoute.path === path) return;
  router.replace({ path }).catch(console.error);
};

export default router;
