<template>
  <v-container fluid>
    <a-space>
      <v-btn color="primary" tile @click="showadd">
        <v-icon small>mdi-plus</v-icon>新增油价
      </v-btn>
      <v-btn color="primary" tile outlined @click="getdata">
        <v-icon small>mdi-reload</v-icon>刷新
      </v-btn>
    </a-space>
    <v-tabs v-model="postofficeid" @change="getdata">
      <v-tab v-for="item in postoffices" :key="item._id"> {{ item.shortname }} </v-tab>
    </v-tabs>
    <v-card tile class="mt-2">
      <v-data-table :headers="headers" :items="list" :items-per-page="limit" no-data-text="无数据" hide-default-footer
        disable-sort :height="height" fixed-header>
        <template v-slot:item.index="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn text color="success" @click="showedit(item)"> 编辑 </v-btn>
          <a-popconfirm title="删除" ok-text="确定" cancel-text="取消" @confirm="remove(item._id)">
            <v-btn text color="error"> 删除 </v-btn>
          </a-popconfirm>
        </template>
      </v-data-table>
      <v-pagination v-if="length > 1" v-model="page" :page="page" :length="length" total-visible="10" />
    </v-card>
    <v-dialog v-model="model" persistent max-width="400px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between">
            {{ modeltitle }}
            <v-icon @click="cancelsave">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-menu ref="month_menu" v-model="month_menu" :close-on-content-click="false"
                    :return-value.sync="oilprice.month" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="oilprice.month" :rules="[vrules.required]" placeholder="选择月份"
                        append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined>
                        <template v-slot:label>
                          选择月份 <span class="red--text">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="oilprice.month" type="month" no-title value="YYYY-mm" scrollable
                      locale="zh-cn"
                      @input="() => { $refs.month_menu.save(oilprice.month); month_menu = false; }"></v-date-picker>
                  </v-menu>
                  <v-text-field v-model="oilprice.price1" type="number" :rules="[vrules.required]" placeholder="输入基准油价"
                    persistent-placeholder dense outlined>
                    <template v-slot:label>
                      基准油价 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <v-text-field v-model="oilprice.price2" type="number" :rules="[vrules.required]" label="当月油价"
                    placeholder="输入当月油价" persistent-placeholder dense outlined>
                    <template v-slot:label>
                      当月油价 <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" tile :disabled="!valid" @click="save">确定</v-btn>
            <v-btn tile @click="cancelsave">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "PostOfficeOliPrice",
  data() {
    return {
      postofficeid: 0,
      postoffices: [],
      valid: true,
      headers: [
        { text: "序号", value: "index", align: "center", width: 100 },
        { text: "月份", value: "month", align: "center", width: 100 },
        { text: "基准油价", value: "price1", align: "center", width: 100 },
        { text: "当月油价", value: "price2", align: "center", width: 100 },
        { text: "操作", value: "action", width: 180 },
      ],
      page: 1,
      limit: 50,
      list: [],
      length: 0,
      count: 0,
      model: false,
      modeltitle: "",
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      month_menu: false,
      oliprice_template: {
        _id: "", month: this.$moment().format('YYYY-MM'), price1: "", price2: "",
      },
      oilprice: {},
      height: 600,
    };
  },
  computed: {
    ...mapState(["role", "username"]),
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  mounted() {
    this.height = window.innerHeight - 200;
    window.onresize = (e) => {
      console.log("onresize", window.innerHeight);
      this.height = window.innerHeight - 200;
    };
  },
  activated() {
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.count = 0;
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/oilprice/list", { postofficeid: this.postofficeid, page: this.page, limit: this.limit, }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.postoffices = res.data.postoffices;
          this.list = res.data.list;
          this.length = res.data.length;
          this.count = res.data.count;
          if (this.length > 0 && this.page > this.length) this.page = 1;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    showadd() {
      this.oilprice = _.extend({}, { ...this.oliprice_template });
      this.modeltitle = "新增油价";
      this.model = true;
    },
    showedit(item) {
      this.oilprice = _.pick(item, _.keys(this.oliprice_template));
      this.modeltitle = "编辑油价";
      this.model = true;
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/oilprice/save", { postofficeid: this.postoffices[this.postofficeid]._id, oilprice: this.oilprice }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.model = false;
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    cancelsave() {
      this.$refs.form.resetValidation();
      this.model = false;
    },
    remove(_id) {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/oilprice/remove", { _id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.getdata();
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

/deep/ .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}
</style>
